import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { IsNotEmptyObject } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { BookingStatus } from './booking-status.type';

const parseJSONValue = (v: string) => (v && typeof v === 'string' ? JSON.parse(v) : v);

@Exclude()
export class BookingGuideListItemDto {
  @ApiProperty({ description: 'Booking id' })
  @Expose()
  id: number;

  @ApiProperty({ description: 'Location id' })
  @Expose()
  locationId: number;

  @ApiProperty({ description: 'Location name' })
  @IsNotEmptyObject()
  @Expose()
  @Transform(parseJSONValue)
  titleMl: MultiLangString;

  @ApiProperty({ description: 'Booking creation date' })
  @Expose()
  @Type(() => Date)
  createdAt: Date;

  @ApiProperty({ description: 'Booking start date' })
  @Expose()
  @Type(() => Date)
  dateFrom: Date;

  @ApiProperty({ description: 'Booking end date' })
  @Expose()
  @Type(() => Date)
  dateTo: Date;

  @ApiProperty({ description: 'Selected activity target name' })
  @Expose()
  @Transform(parseJSONValue)
  selectedActivityTargetNameMl: MultiLangString;

  @ApiProperty({ description: 'Selected activity type name' })
  @Expose()
  @Transform(parseJSONValue)
  selectedActivityTypeNameMl: MultiLangString;

  @ApiProperty({ description: 'Booking people count' })
  @Expose()
  peopleCount: number;

  @ApiProperty({ description: 'Booking price' })
  @Expose()
  price: number;

  @ApiProperty({ description: 'Booking paid amount' })
  @Expose()
  paid: number;

  @ApiProperty({ description: 'Booking status' })
  @Expose()
  status: BookingStatus;

  @ApiProperty({ description: 'Guide discount for booking' })
  @Expose()
  discount: number;

  @ApiProperty({ description: 'Location preview image url' })
  @Expose()
  imageUrl: string;
}
