import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-nest.decorator';

@Exclude()
export class PaymentInfoDto {
  @ApiProperty({
    description: 'Payment amount',
  })
  @Expose()
  amount: number;

  @ApiProperty({
    description: 'Bonuses used amount',
  })
  @Expose()
  bonuses: number;

  @ApiProperty({
    description: 'Payment status',
  })
  @Expose()
  success: boolean;

  @ApiProperty({
    description: 'Booking id',
  })
  @Expose()
  bookingId: number;

  @ApiProperty({
    description: 'Transaction code',
  })
  @Expose()
  code: string;

  constructor(partial?: Partial<PaymentInfoDto>) {
    Object.assign(this, partial);
  }
}
