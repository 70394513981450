import * as moment from 'moment';

import { TupleOrValue } from '../../utils/types';
import { BookingFacilityDto } from '../booking-facility/booking-facility.dto';
import { BookingFacilityItem } from '../booking-facility/booking-facility-item';
import { UniversalDate } from '../common';
import { FacilityId, FacilityType, getFacilityAvailability, hasFutureDates } from '../facility';
import {
  FACILITY_ACTIVITY_TYPES,
  FACILITY_EXTRA_TYPES,
  FACILITY_MULTIPLE_TARGET_TYPES,
  FACILITY_TARGET_TYPES,
  isFacilityOfType,
} from '../facility/facility-type.type';
import { LocationDto } from '../location';
import { LocationFacilityFilter, LocationFilterDto } from '../location/location-filter.dto';
import { facilityAdapter, LocationFacilityDto, LocationFacilityItem } from '../location-facility';
import { BookingDto } from './booking.dto';
import { BookingStatus } from './booking-status.type';

export function isPeopleCountDependentItem(item: BookingFacilityItem) {
  return !!item?.locationItem?.prices?.some(pr => {
    if (!(pr.value > 0)) {
      return false;
    }

    return pr.type.id?.indexOf('PERSON') >= 0;
  });
}

export function getDefaultOptionQuantity(booking: BookingDto, option: BookingFacilityItem) {
  return isPeopleCountDependentItem(option) ? booking.peopleCount || 1 : 1;
}

export function buildBookingFromLocation(
  location: LocationDto,
  filter?: LocationFilterDto,
  itemQuantityFn: (item: LocationFacilityItem) => number = () => null,
  selectDefault = false,
  selectHuntType = false,
) {
  const facilities = location?.facilities
    ?.map(f => {
      let alreadySelected = false;

      const options: BookingFacilityItem[] = f.options
        .filter(option => hasFutureDates(option))
        .map(locationItem => {
          const filterValue =
            filter?.facilities?.[f.facility.id as keyof LocationFacilityFilter]?.ids;
          const itemId = facilityAdapter.getItemValue(locationItem)?.id;

          const selected = (!alreadySelected && filterValue?.some(i => i === itemId)) || false;

          if (selected) {
            alreadySelected = true;
          }

          return {
            locationItem,
            price: null,
            selected,
            quantity: itemQuantityFn(locationItem),
          };
        });

      return {
        locationFacility: f,
        price: null,
        options,
      } as BookingFacilityDto;
    })
    .map(facility => {
      if (!selectDefault) {
        return facility;
      }

      const facilityType = facility.locationFacility.facility.type as FacilityId;
      const targetFacilityTypes: FacilityId[] = selectHuntType
        ? ['fishing-type', 'tourism-type', 'beast', 'hunt-type']
        : ['fishing-type', 'tourism-type', 'beast'];

      if (!targetFacilityTypes.includes(facilityType)) {
        return facility;
      }

      if (facility.options.some(option => option.selected)) {
        return facility;
      }

      return {
        ...facility,
        options: facility.options.map((option, index) => {
          return index ? option : { ...option, selected: true };
        }),
      };
    });

  const targetActivity =
    getBookingSelectedActivityType({ facilities } as BookingDto) ||
    getBookingSelectedActivityTarget({ facilities } as BookingDto);

  const availability = getFacilityAvailability(targetActivity?.item?.locationItem);
  const needPreselectDates = availability?.mode === 'any';

  const dateFrom =
    needPreselectDates && filter?.dateStart
      ? UniversalDate.fromLocalNativeDate(filter?.dateStart)
      : null;

  const dateTo =
    needPreselectDates && filter?.dateEnd
      ? UniversalDate.fromLocalNativeDate(filter?.dateEnd)
      : null;

  return new BookingDto({
    locationId: location?.id,
    peopleCount: Number(filter?.peopleCount) || 1,
    dateFrom,
    dateTo,
    status: 'NEW',
    facilities,
    discount: location.discount,
  });
}

export function getBookingActivityTargetFacility(booking: BookingDto) {
  for (const f of booking.facilities) {
    if (isFacilityOfType(f, FACILITY_TARGET_TYPES)) {
      return f;
    }
  }

  return null;
}
export function getBookingActivityMultipleTargetFacility(booking: BookingDto) {
  for (const f of booking.facilities) {
    if (isFacilityOfType(f, FACILITY_MULTIPLE_TARGET_TYPES)) {
      return f;
    }
  }

  return null;
}

export type SelectedActivityTarget<
  T extends (typeof FACILITY_TARGET_TYPES)[number] = (typeof FACILITY_TARGET_TYPES)[number],
> = {
  facility: BookingFacilityDto<T>;
  item: BookingFacilityItem<T>;
};

export function getBookingSelectedActivityTarget(booking: BookingDto): SelectedActivityTarget {
  const facility = getBookingActivityTargetFacility(booking);
  const item = facility?.options.find(option => option.selected);

  return { item, facility };
}

export function getBookingActivityTypeFacility(booking: BookingDto) {
  for (const f of booking.facilities) {
    if (isFacilityOfType(f, FACILITY_ACTIVITY_TYPES)) {
      return f;
    }
  }

  return null;
}

export type SelectedActivityType<
  T extends (typeof FACILITY_ACTIVITY_TYPES)[number] = (typeof FACILITY_ACTIVITY_TYPES)[number],
> = {
  facility: BookingFacilityDto<T>;
  item: BookingFacilityItem<T>;
};

export function getBookingSelectedActivityType(booking: BookingDto): SelectedActivityType {
  const facility = getBookingActivityTypeFacility(booking);
  const item = facility?.options.find(option => option.selected);

  return { item, facility };
}

export function isBookingPrepaymentAllowed(
  booking: Pick<BookingDto, 'paid' | 'dateFrom'>,
  minDaysForBookingPrepayment: number,
) {
  if (booking.paid || !booking.dateFrom) {
    return false;
  }

  return moment(booking.dateFrom).diff(moment(), 'days') >= minDaysForBookingPrepayment;
}

export function getBookingFacilityOfType<F extends TupleOrValue<FacilityType>>(
  booking: BookingDto,
  types: F,
) {
  if (!booking?.facilities) {
    return null;
  }

  for (const f of booking.facilities) {
    if (isFacilityOfType(f, types)) {
      return f;
    }
  }

  return null;
}

export function getBookingStatusText(
  booking: { status: BookingStatus; paid: number; price: number },
  options?: {
    ignorePayment?: boolean;
    ignoreStatus?: boolean;
  },
) {
  if (!booking) {
    return null;
  }

  if (options?.ignorePayment) {
    switch (booking.status) {
      case 'CANCELED':
      case 'DECLINED':
        return 'Отменено';
      case 'CONFIRMED':
        return 'Подтверждено';
      case 'NEW':
        return 'Ожидает подтверждения';
    }
  }

  if (options?.ignoreStatus) {
    if (!booking.paid) {
      return 'Не оплачено';
    }

    const paidPercent = (booking.paid * 100) / booking.price;
    return `Оплачено ${Math.round(paidPercent)}%`;
  }

  switch (booking.status) {
    case 'DECLINED':
    case 'CANCELED':
      return 'Отменено';
    case 'CONFIRMED':
    case 'NEW': {
      if (!booking.paid) {
        return 'Не оплачено';
      }

      const paidPercent = (booking.paid * 100) / booking.price;
      return `Оплачено ${Math.round(paidPercent)}%`;
    }
  }
}

export function getLocationFacilityOfType<F extends TupleOrValue<FacilityType>>(
  location: LocationDto,
  types: F,
) {
  for (const f of location.facilities) {
    if (isFacilityOfType(f, types)) {
      return f;
    }
  }

  return null;
}

export function getIncludedLocationFacilityItems(location: { facilities: LocationFacilityDto[] }) {
  return location.facilities
    .filter(facility => isFacilityOfType(facility, FACILITY_EXTRA_TYPES))
    .map(facility =>
      facility.options.map(option => {
        return {
          ...option,
          nameMl: option.nameMl.ru ? option.nameMl : facility.facility.nameMl,
        };
      }),
    )
    .reduce((acc, curr) => [...acc, ...curr], [])
    .filter(item => item.isFree);
}

export function updateBookingFacility(booking: BookingDto, update: BookingFacilityDto): BookingDto {
  return new BookingDto({
    ...booking,
    facilities: booking.facilities.map(facility => {
      return facility.locationFacility.id === update.locationFacility.id ? update : facility;
    }),
  });
}

export function getBookingFacilities(
  booking: BookingDto,
  filters?: {
    includeIds?: FacilityId[];
    includeTypes?: FacilityType[];
    excludeIds?: FacilityId[];
    excludeTypes?: FacilityType[];
  },
) {
  const includeTypes = filters?.includeTypes && new Set(filters.includeTypes);
  const excludeTypes = filters?.excludeTypes && new Set(filters.excludeTypes);
  const includeIds = filters?.includeIds && new Set(filters.includeIds);
  const excludeIds = filters?.excludeIds && new Set(filters.excludeIds);

  return (booking?.facilities || []).filter(
    f =>
      (!includeTypes || includeTypes.has(f.locationFacility.facility.type)) &&
      (!includeIds || includeIds.has(f.locationFacility.facility.id)) &&
      !excludeTypes?.has(f.locationFacility.facility.type) &&
      !excludeIds?.has(f.locationFacility.facility.id),
  );
}
