import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { PromocodeForUIDto } from '../promocode/promocode-for-ui.dto';
import { TransactionStatus } from './transaction-status.type';
import { TransactionType } from './transaction-type.type';

@Exclude()
export class TransactionDto {
  @ApiProperty({ description: 'Внутренний идентификатор транзакции' })
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  type: TransactionType;

  @ApiProperty({ description: 'Внешний идентификатор транзакции' })
  externalId?: string;

  @ApiProperty({
    description:
      'Идентификатор транзакции, ожидаемый в запросе от платёжной системы при отправке ею данных о статусе платежа',
  })
  @Expose()
  code: string;

  @ApiProperty({ description: 'Используемая платёжная система' })
  @Expose()
  merchant: string;

  @ApiProperty({ description: 'Статус транзакции' })
  @Expose()
  status: TransactionStatus;

  @ApiProperty({ description: 'Сумма оплаты' })
  @Expose()
  cost: number;

  @ApiProperty({ description: 'Кол-во использованных бонусов' })
  @Expose()
  bonuses?: number;

  @ApiProperty()
  @Expose()
  createdAt: Date;

  @ApiProperty()
  @Expose()
  promocode?: PromocodeForUIDto;

  constructor(partial?: Partial<TransactionDto>) {
    Object.assign(this, partial);
  }
}
