import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumberString, IsOptional } from 'class-validator';

import { ApiProperty } from '../../utils';
import { CountryDto } from '../country';

@Exclude()
export class SelfEmployedDto {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty()
  @Expose()
  firstName: string;

  @ApiProperty()
  @Expose()
  lastName: string;

  @ApiProperty()
  @IsOptional()
  @Expose()
  patronymic: string;

  @ApiProperty()
  @Expose()
  @Type(() => CountryDto)
  country: CountryDto;

  @ApiProperty()
  @IsNumberString()
  @Expose()
  inn: string;

  @ApiProperty()
  @Expose()
  registration: string;

  constructor(partial?: Partial<SelfEmployedDto>) {
    Object.assign(this, partial);
  }
}
