import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';
import { FileDto } from '../file/file.dto';

@Exclude()
export class LocationImageDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => FileDto)
  file: FileDto;

  deleted?: boolean;
}
