export class Lazy<T> {
  private _value: T;

  private valueSet = false;

  constructor(private evalFunc: () => T) {}

  value() {
    if (!this.valueSet) {
      this._value = this.evalFunc();
      this.valueSet = true;
    }

    return this._value;
  }

  get hasValue() {
    return this.valueSet;
  }
}
