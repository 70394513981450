/* eslint-disable @typescript-eslint/naming-convention */
// TODO: change type in v4.0
export enum TuiMonthNumber {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
