import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { FacilityPriceTypeDto } from '../facility-price-type/facility-price-type.dto';
import { TourTypeDto } from '../tour-type/tour-type.dto';
import { FacilityId, FacilityType } from './facilities';

@Exclude()
export class FacilityDto<T extends FacilityType = FacilityType> {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  @Expose()
  id: FacilityId;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  descriptionMl: MultiLangString;

  @ApiProperty({ type: () => Object })
  @IsNotEmpty()
  @Expose()
  type: T;

  @ApiProperty({
    description: 'If `true` the facility could be added to the location more than once',
  })
  @IsNotEmpty()
  @Expose()
  multiple: boolean;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  required: boolean;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => FacilityPriceTypeDto)
  prices: FacilityPriceTypeDto[];

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => TourTypeDto)
  tourTypes: TourTypeDto[];

  constructor(partial: Partial<FacilityDto>) {
    Object.assign(this, partial);
  }
}
