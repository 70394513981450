import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class PromocodeForUIDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  code: string;

  @ApiProperty()
  @Expose()
  amount: number;

  @ApiProperty()
  @Expose()
  expirationDate: Date;

  constructor(partial?: Partial<PromocodeForUIDto>) {
    Object.assign(this, partial);
  }
}
