import { regionById } from '../region/region.data';

export const COUNTRY_STATE_RAW_DATA = [
  {
    id: 1,
    nameMl: { en: 'Амурская область', ru: 'Амурская область' },
    yandexName: 'Амурская область',
    region: regionById(1),
  },
  {
    id: 2,
    nameMl: {
      en: 'Еврейская автономная область',
      ru: 'Еврейская автономная область',
    },
    yandexName: 'Еврейская автономная область',
    region: regionById(1),
  },
  {
    id: 3,
    nameMl: { en: 'Камчатка', ru: 'Камчатка' },
    yandexName: 'Камчатский край',
    region: regionById(1),
  },
  {
    id: 4,
    nameMl: { en: 'Магадан', ru: 'Магадан' },
    yandexName: 'Магадан',
    region: regionById(1),
  },
  {
    id: 5,
    nameMl: { en: 'Приморский край', ru: 'Приморский край' },
    yandexName: 'Приморский край',
    region: regionById(1),
  },
  {
    id: 6,
    nameMl: { en: 'Сахалин', ru: 'Сахалин' },
    yandexName: 'Сахалинская область',
    region: regionById(1),
  },
  {
    id: 7,
    nameMl: { en: 'Хабаровский край', ru: 'Хабаровский край' },
    yandexName: 'Хабаровский край',
    region: regionById(1),
  },
  {
    id: 8,
    nameMl: { en: 'Чукотка', ru: 'Чукотка' },
    yandexName: 'Чукотский автономный округ',
    region: regionById(1),
  },
  {
    id: 9,
    nameMl: { en: 'Якутия', ru: 'Якутия' },
    yandexName: 'Республика Саха (Якутия)',
    region: regionById(1),
  },
  {
    id: 10,
    nameMl: { en: 'Башкортостан', ru: 'Башкортостан' },
    yandexName: 'Республика Башкортостан',
    region: regionById(2),
  },
  {
    id: 11,
    nameMl: { en: 'Кировская область', ru: 'Кировская область' },
    yandexName: 'Кировская область',
    region: regionById(2),
  },
  {
    id: 12,
    nameMl: { en: 'Марий Эл', ru: 'Марий Эл' },
    yandexName: 'Республика Марий Эл',
    region: regionById(2),
  },
  {
    id: 13,
    nameMl: { en: 'Мордовия', ru: 'Мордовия' },
    yandexName: 'Республика Мордовия',
    region: regionById(2),
  },
  {
    id: 14,
    nameMl: { en: 'Нижегородская область', ru: 'Нижегородская область' },
    yandexName: 'Нижегородская область',
    region: regionById(2),
  },
  {
    id: 15,
    nameMl: { en: 'Оренбургская область', ru: 'Оренбургская область' },
    yandexName: 'Оренбургская область',
    region: regionById(2),
  },
  {
    id: 16,
    nameMl: { en: 'Пензенская область', ru: 'Пензенская область' },
    yandexName: 'Пензенская область',
    region: regionById(2),
  },
  {
    id: 17,
    nameMl: { en: 'Пермский край', ru: 'Пермский край' },
    yandexName: 'Пермский край',
    region: regionById(2),
  },
  {
    id: 18,
    nameMl: { en: 'Самарская область', ru: 'Самарская область' },
    yandexName: 'Самарская область',
    region: regionById(2),
  },
  {
    id: 19,
    nameMl: { en: 'Саратовская область', ru: 'Саратовская область' },
    yandexName: 'Саратовская область',
    region: regionById(2),
  },
  {
    id: 20,
    nameMl: { en: 'Татарстан', ru: 'Татарстан' },
    yandexName: 'Республика Татарстан',
    region: regionById(2),
  },
  {
    id: 21,
    nameMl: { en: 'Удмуртия', ru: 'Удмуртия' },
    yandexName: 'Удмуртская Республика',
    region: regionById(2),
  },
  {
    id: 22,
    nameMl: { en: 'Ульяновская область', ru: 'Ульяновская область' },
    yandexName: 'Ульяновская область',
    region: regionById(2),
  },
  {
    id: 23,
    nameMl: { en: 'Чувашия', ru: 'Чувашия' },
    yandexName: 'Чувашская Республика',
    region: regionById(2),
  },
  {
    id: 24,
    nameMl: { en: 'Архангельская область', ru: 'Архангельская область' },
    yandexName: 'Архангельская область',
    region: regionById(3),
  },
  {
    id: 25,
    nameMl: { en: 'Вологодская область', ru: 'Вологодская область' },
    yandexName: 'Вологодская область',
    region: regionById(3),
  },
  {
    id: 26,
    nameMl: { en: 'Калининградская область', ru: 'Калининградская область' },
    yandexName: 'Калининградская область',
    region: regionById(3),
  },
  {
    id: 27,
    nameMl: { en: 'Карелия', ru: 'Карелия' },
    yandexName: 'Республика Карелия',
    region: regionById(3),
  },
  {
    id: 28,
    nameMl: { en: 'Коми', ru: 'Коми' },
    yandexName: 'Республика Коми',
    region: regionById(3),
  },
  {
    id: 29,
    nameMl: { en: 'Ленинградская область', ru: 'Ленинградская область' },
    yandexName: 'Ленинградская область',
    region: regionById(3),
  },
  {
    id: 30,
    nameMl: { en: 'Мурманская область', ru: 'Мурманская область' },
    yandexName: 'Мурманская область',
    region: regionById(3),
  },
  {
    id: 31,
    nameMl: {
      en: 'Ненецкий автономный округ',
      ru: 'Ненецкий автономный округ',
    },
    yandexName: 'Ненецкий автономный округ',
    region: regionById(3),
  },
  {
    id: 32,
    nameMl: { en: 'Новгородская область', ru: 'Новгородская область' },
    yandexName: 'Новгородская область',
    region: regionById(3),
  },
  {
    id: 33,
    nameMl: { en: 'Псковская область', ru: 'Псковская область' },
    yandexName: 'Псковская область',
    region: regionById(3),
  },
  {
    id: 34,
    nameMl: { en: 'Санкт-Петербург', ru: 'Санкт-Петербург' },
    yandexName: 'Санкт-Петербург',
    region: regionById(3),
  },
  {
    id: 35,
    nameMl: { en: 'Дагестан', ru: 'Дагестан' },
    yandexName: 'Республика Дагестан',
    region: regionById(4),
  },
  {
    id: 36,
    nameMl: { en: 'Ингушетия', ru: 'Ингушетия' },
    yandexName: 'Республика Ингушетия',
    region: regionById(4),
  },
  {
    id: 37,
    nameMl: { en: 'Кабардино-Балкария', ru: 'Кабардино-Балкария' },
    yandexName: 'Кабардино-Балкарская Республика',
    region: regionById(4),
  },
  {
    id: 38,
    nameMl: { en: 'Карачаево-Черкесия', ru: 'Карачаево-Черкесия' },
    yandexName: 'Карачаево-Черкесская Республика',
    region: regionById(4),
  },
  {
    id: 39,
    nameMl: { en: 'Северная Осетия-Алания', ru: 'Северная Осетия-Алания' },
    yandexName: 'Республика Северная Осетия — Алания',
    region: regionById(4),
  },
  {
    id: 40,
    nameMl: { en: 'Ставропольский край', ru: 'Ставропольский край' },
    yandexName: 'Ставропольский край',
    region: regionById(4),
  },
  {
    id: 41,
    nameMl: { en: 'Чечня', ru: 'Чечня' },
    yandexName: 'Чеченская Республика',
    region: regionById(4),
  },
  {
    id: 42,
    nameMl: { en: 'Алтай', ru: 'Алтай' },
    yandexName: 'Республика Алтай',
    region: regionById(5),
  },
  {
    id: 43,
    nameMl: { en: 'Алтайский край', ru: 'Алтайский край' },
    yandexName: 'Алтайский край',
    region: regionById(5),
  },
  {
    id: 44,
    nameMl: { en: 'Бурятия', ru: 'Бурятия' },
    yandexName: 'Республика Бурятия',
    region: regionById(5),
  },
  {
    id: 45,
    nameMl: { en: 'Забайкальский край', ru: 'Забайкальский край' },
    yandexName: 'Забайкальский край',
    region: regionById(5),
  },
  {
    id: 46,
    nameMl: { en: 'Иркутская область', ru: 'Иркутская область' },
    yandexName: 'Иркутская область',
    region: regionById(5),
  },
  {
    id: 47,
    nameMl: { en: 'Кемеровская область', ru: 'Кемеровская область' },
    yandexName: 'Кемеровская область',
    region: regionById(5),
  },
  {
    id: 48,
    nameMl: { en: 'Красноярский край', ru: 'Красноярский край' },
    yandexName: 'Красноярский край',
    region: regionById(5),
  },
  {
    id: 49,
    nameMl: { en: 'Новосибирская область', ru: 'Новосибирская область' },
    yandexName: 'Новосибирская область',
    region: regionById(5),
  },
  {
    id: 50,
    nameMl: { en: 'Омская область', ru: 'Омская область' },
    yandexName: 'Омская область',
    region: regionById(5),
  },
  {
    id: 51,
    nameMl: { en: 'Томская область', ru: 'Томская область' },
    yandexName: 'Томская область',
    region: regionById(5),
  },
  {
    id: 52,
    nameMl: { en: 'Тыва', ru: 'Тыва' },
    yandexName: 'Республика Тыва',
    region: regionById(5),
  },
  {
    id: 53,
    nameMl: { en: 'Хакасия', ru: 'Хакасия' },
    yandexName: 'Республика Хакасия',
    region: regionById(5),
  },
  {
    id: 54,
    nameMl: { en: 'Курганская область', ru: 'Курганская область' },
    yandexName: 'Курганская область',
    region: regionById(6),
  },
  {
    id: 55,
    nameMl: { en: 'Свердловская область', ru: 'Свердловская область' },
    yandexName: 'Свердловская область',
    region: regionById(6),
  },
  {
    id: 56,
    nameMl: { en: 'Тюменская область', ru: 'Тюменская область' },
    yandexName: 'Тюменская область',
    region: regionById(6),
  },
  {
    id: 57,
    nameMl: {
      en: 'Ханты-Мансийский автономный округ - Югра',
      ru: 'Ханты-Мансийский автономный округ - Югра',
    },
    yandexName: 'Ханты-Мансийский автономный округ',
    region: regionById(6),
  },
  {
    id: 58,
    nameMl: { en: 'Челябинская область', ru: 'Челябинская область' },
    yandexName: 'Челябинская область',
    region: regionById(6),
  },
  {
    id: 59,
    nameMl: {
      en: 'Ямало-Ненецкий автономный округ',
      ru: 'Ямало-Ненецкий автономный округ',
    },
    yandexName: 'Ямало-Ненецкий автономный округ',
    region: regionById(6),
  },
  {
    id: 60,
    nameMl: { en: 'Белгородская область', ru: 'Белгородская область' },
    yandexName: 'Белгородская область',
    region: regionById(7),
  },
  {
    id: 61,
    nameMl: { en: 'Брянская область', ru: 'Брянская область' },
    yandexName: 'Брянская область',
    region: regionById(7),
  },
  {
    id: 62,
    nameMl: { en: 'Владимирская область', ru: 'Владимирская область' },
    yandexName: 'Владимирская область',
    region: regionById(7),
  },
  {
    id: 63,
    nameMl: { en: 'Воронежская область', ru: 'Воронежская область' },
    yandexName: 'Воронежская область',
    region: regionById(7),
  },
  {
    id: 64,
    nameMl: { en: 'Ивановская область', ru: 'Ивановская область' },
    yandexName: 'Ивановская область',
    region: regionById(7),
  },
  {
    id: 65,
    nameMl: { en: 'Калужская область', ru: 'Калужская область' },
    yandexName: 'Калужская область',
    region: regionById(7),
  },
  {
    id: 66,
    nameMl: { en: 'Костромская область', ru: 'Костромская область' },
    yandexName: 'Костромская область',
    region: regionById(7),
  },
  {
    id: 67,
    nameMl: { en: 'Курская область', ru: 'Курская область' },
    yandexName: 'Курская область',
    region: regionById(7),
  },
  {
    id: 68,
    nameMl: { en: 'Липецкая область', ru: 'Липецкая область' },
    yandexName: 'Липецкая область',
    region: regionById(7),
  },
  {
    id: 69,
    nameMl: { en: 'Москва', ru: 'Москва' },
    yandexName: 'Москва',
    region: regionById(7),
  },
  {
    id: 70,
    nameMl: { en: 'Московская область', ru: 'Московская область' },
    yandexName: 'Московская область',
    region: regionById(7),
  },
  {
    id: 71,
    nameMl: { en: 'Орловская область', ru: 'Орловская область' },
    yandexName: 'Орловская область',
    region: regionById(7),
  },
  {
    id: 72,
    nameMl: { en: 'Рязанская область', ru: 'Рязанская область' },
    yandexName: 'Рязанская область',
    region: regionById(7),
  },
  {
    id: 73,
    nameMl: { en: 'Смоленская область', ru: 'Смоленская область' },
    yandexName: 'Смоленская область',
    region: regionById(7),
  },
  {
    id: 74,
    nameMl: { en: 'Тамбовская область', ru: 'Тамбовская область' },
    yandexName: 'Тамбовская область',
    region: regionById(7),
  },
  {
    id: 75,
    nameMl: { en: 'Тверская область', ru: 'Тверская область' },
    yandexName: 'Тверская область',
    region: regionById(7),
  },
  {
    id: 76,
    nameMl: { en: 'Тульская область', ru: 'Тульская область' },
    yandexName: 'Тульская область',
    region: regionById(7),
  },
  {
    id: 77,
    nameMl: { en: 'Ярославская область', ru: 'Ярославская область' },
    yandexName: 'Ярославская область',
    region: regionById(7),
  },
  {
    id: 78,
    nameMl: { en: 'Адыгея', ru: 'Адыгея' },
    yandexName: 'Республика Адыгея',
    region: regionById(8),
  },
  {
    id: 79,
    nameMl: { en: 'Астраханская область', ru: 'Астраханская область' },
    yandexName: 'Астраханская область',
    region: regionById(8),
  },
  {
    id: 80,
    nameMl: { en: 'Волгоградская область', ru: 'Волгоградская область' },
    yandexName: 'Волгоградская область',
    region: regionById(8),
  },
  {
    id: 81,
    nameMl: { en: 'Калмыкия', ru: 'Калмыкия' },
    yandexName: 'Республика Калмыкия',
    region: regionById(8),
  },
  {
    id: 82,
    nameMl: { en: 'Краснодарский край', ru: 'Краснодарский край' },
    yandexName: 'Краснодарский край',
    region: regionById(8),
  },
  {
    id: 83,
    nameMl: { en: 'Крым', ru: 'Крым' },
    yandexName: 'Республика Крым',
    region: regionById(8),
  },
  {
    id: 84,
    nameMl: { en: 'Ростовская область', ru: 'Ростовская область' },
    yandexName: 'Ростовская область',
    region: regionById(8),
  },
  {
    id: 85,
    nameMl: { en: 'Севастополь', ru: 'Севастополь' },
    yandexName: 'Севастополь',
    region: regionById(8),
  },
] as const;
