import { Exclude, Expose, Type } from 'class-transformer';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { BusinessFormDto } from '../business-form';
import { UserDto } from '../user';
import { PaymentMethod } from './payment-method';

@Exclude()
export class PartnerDto {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty()
  @Expose()
  @Type(() => PaymentMethod)
  paymentMethod: PaymentMethod;

  @Expose()
  @Type(() => BusinessFormDto)
  businessForm: BusinessFormDto;

  @Expose()
  brandNameMl?: MultiLangString;

  @Expose()
  isTrusted?: boolean;

  @Expose()
  user?: UserDto;

  constructor(partial?: Partial<PartnerDto>) {
    Object.assign(this, partial);
  }
}
