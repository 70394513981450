import { Type } from 'class-transformer';

import { PageResponseDto } from '../../common/page-response.dto';
import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-angular.decorator';
import { PromocodeManageDto } from './promocode-manage.dto';

export class PromocodesPageResponseDto extends PageResponseDto<PromocodeManageDto> {
  @ApiProperty()
  @Type(() => PromocodeManageDto)
  override docs: PromocodeManageDto[];
}
