import { Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';
import { LocationFacilityDto } from '../location-facility/location-facility.dto';
import { TourType } from '../tour-type/tour-type.type';

export class LocationSearchDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  tourType: TourType;

  @ApiProperty()
  @Expose()
  geoLocation?: [number, number];

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  countryRegion: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  hide: boolean;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => LocationFacilityDto)
  facilities: LocationFacilityDto[];
}
