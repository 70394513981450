import {
  TOUR_TYPE_FISH as TOUR_TYPE_FISH_RAW,
  TOUR_TYPE_HUNT as TOUR_TYPE_HUNT_RAW,
  TOUR_TYPE_RAW_DATA,
  TOUR_TYPE_TOUR as TOUR_TYPE_TOUR_RAW,
} from '../../static-data';

export const TOUR_TYPE_HUNT = TOUR_TYPE_HUNT_RAW.id;
export const TOUR_TYPE_FISH = TOUR_TYPE_FISH_RAW.id;
export const TOUR_TYPE_TOURISM = TOUR_TYPE_TOUR_RAW.id;

export type TourType = (typeof TOUR_TYPE_RAW_DATA)[number]['id'];
