import { Exclude, Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

import { ApiProperty } from '../../utils';
import { PromocodeDto } from '../promocode';

@Exclude()
export class InitBookingPaymentDto {
  @ApiProperty()
  @Expose()
  bookingId: number;

  @ApiProperty()
  @Expose()
  paymentAmount: number;

  @ApiProperty()
  @Expose()
  usedBonusesAmount: number;

  @ApiProperty()
  @Expose()
  @IsOptional()
  promocode?: PromocodeDto;

  constructor(partial?: Partial<InitBookingPaymentDto>) {
    Object.assign(this, partial);
  }
}
