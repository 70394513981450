import { Exclude, Expose, Transform } from 'class-transformer';
import { IsNotEmpty, IsOptional } from 'class-validator';

import { ApiProperty } from '../../utils';

@Exclude()
export class UserDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id?: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  name: string;

  @ApiProperty()
  @Expose()
  phone: string;

  @ApiProperty()
  @IsOptional()
  @Expose()
  @Transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any[]) => {
      if (!value) {
        return value;
      }

      return value.map(role => {
        if (typeof role === 'object' && 'name' in role) {
          return role.name;
        }
        return role;
      });
    },
    { toClassOnly: true },
  )
  roles: string[];

  @ApiProperty()
  @IsOptional()
  @Expose()
  activityFlag: boolean;
}
