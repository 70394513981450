import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class BookingDeclineDataDto {
  @ApiProperty()
  @Expose()
  reason: string;

  @ApiProperty()
  @Expose()
  comment: string;
}
