import { PageRequestDto } from '../../common/page-request.dto';
import { LocationAggregationKey } from '../../static-data/aggregations';
import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-nest.decorator';
import { Pattern } from '../../utils/types';
import { Range } from '../common';
import { FacilityId } from '../facility';
import { Bounds } from '../map';
import { TourType } from '../tour-type';
import { GeoLocationDto } from './geo-location.dto';
import { LocationStatusId } from './location-status-id.type';

export type SortOrder = 'asc' | 'desc';

/* eslint-disable @typescript-eslint/naming-convention */
export type LocationFacilityFilter = Partial<
  Pattern<
    Partial<Record<FacilityId, object>>,
    {
      beast: { ids: number[] };
      fish: { ids: number[] };
      'tourism-type': { ids: number[] };
      'hunt-type': { ids: number[] };
      'fishing-type': { ids: number[] };
    }
  >
>;
/* eslint-enable @typescript-eslint/naming-convention */

export type LocationSort = {
  price: SortOrder;
};

export class LocationFilterDto extends PageRequestDto {
  @ApiProperty()
  tourType?: TourType;

  @ApiProperty()
  text?: string;

  @ApiProperty()
  countryState?: number[];

  @ApiProperty()
  dateStart?: Date;

  @ApiProperty()
  dateEnd?: Date;

  @ApiProperty()
  rating?: number;

  @ApiProperty()
  price?: Range<number>;

  @ApiProperty()
  peopleCount?: number;

  @ApiProperty({ description: 'Facility filters' })
  facilities?: LocationFacilityFilter;

  @ApiProperty({
    description: 'Координаты видимой области (Левая нижняя и правая верхняя координаты)',
  })
  geoLocation?: Bounds<GeoLocationDto>;

  @ApiProperty()
  sort?: Partial<LocationSort>;

  @ApiProperty()
  published?: boolean;

  @ApiProperty()
  archived?: boolean;

  @ApiProperty()
  hide?: boolean;

  @ApiProperty()
  status?: LocationStatusId;

  @ApiProperty()
  geoHashGridPrecision?: number;

  @ApiProperty()
  participantId?: number;

  @ApiProperty()
  aggregations?: LocationAggregationKey[];

  constructor(partial?: Partial<LocationFilterDto>) {
    super(partial);
    Object.assign(this, partial);
  }
}
