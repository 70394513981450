import { Pattern } from '../../../utils/types';
import { FacilityType } from '../../facility/facilities';
import { LocationFacilityItem } from '../location-facility-item';
import { BasePriceFacilityItemAdapter } from './base-facility-item-adapter';

type FType = Pattern<FacilityType, 'fish'>;
type ItemType = LocationFacilityItem<FType>;

export class FishFacilityItemAdapter extends BasePriceFacilityItemAdapter<FType> {
  override getValue(item: ItemType) {
    return item?.fish;
  }
}
