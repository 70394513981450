import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ArrayMinSize, IsNotEmpty, IsNotEmptyObject } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty, convertArray } from '../../utils';
import { CountryStateDto } from '../country-state/country-state.dto';
import { LocationFacilityDto } from '../location-facility/location-facility.dto';
import { LocationGeoObjectDto } from '../location-geo-object';
import { LocationImageDto } from '../location-image/location-image.dto';
import { LocationTransportDto } from '../location-transport';
import { TourTypeDto } from '../tour-type/tour-type.dto';
import { GeoLocationDto } from './geo-location.dto';
import { LocationStatusDto } from './location-status.dto';

@Exclude()
export class LocationDto {
  @ApiProperty({ description: 'id' })
  @Expose()
  id: number;

  @ApiProperty({ description: 'Название' })
  @IsNotEmptyObject()
  @Expose()
  titleMl: MultiLangString;

  @ApiProperty({ description: 'Описание' })
  @IsNotEmptyObject()
  @Expose()
  descriptionMl: MultiLangString;

  @ApiProperty({ type: () => TourTypeDto, description: 'Тип тура' })
  @IsNotEmpty()
  @Expose()
  @Type(() => TourTypeDto)
  tourType: TourTypeDto;

  @ApiProperty({ type: () => LocationTransportDto, description: 'Станция ЖД' })
  @Expose()
  trainStation: LocationTransportDto;

  @ApiProperty({ type: () => LocationTransportDto, description: 'Станция ЖД - старая реализация' })
  @Expose()
  trainStationLegacy?: LocationGeoObjectDto;

  @ApiProperty({ type: () => LocationTransportDto, description: 'Аэропорт' })
  @Expose()
  airport: LocationTransportDto;

  @ApiProperty({ type: () => LocationTransportDto, description: 'Аэропорт - старая реализация' })
  @Expose()
  airportLegacy?: LocationGeoObjectDto;

  @ApiProperty()
  @ArrayMinSize(1)
  @Expose()
  @Transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any[]) => {
      const sorted = value?.filter(x => !!x).sort((x, y) => x.order - y.order);
      const dtos = convertArray(sorted, LocationImageDto);
      return dtos;
    },
    {
      toClassOnly: true,
    },
  )
  // @Type(() => LocationImageDto)
  images: LocationImageDto[];

  @ApiProperty()
  @Expose()
  geoLocation: GeoLocationDto;

  @ApiProperty({ description: 'Рейтинг' })
  @Expose()
  rating: number;

  @ApiProperty({ description: 'Кол-во отзывов' })
  @Expose()
  feedbackCount: number;

  @ApiProperty({ description: 'Регион' })
  @Expose()
  @Type(() => CountryStateDto)
  countryState: CountryStateDto;

  @ApiProperty({ description: 'Услуги' })
  @IsNotEmpty()
  @Expose()
  @Type(() => LocationFacilityDto)
  facilities: LocationFacilityDto[];

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  hide: boolean;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  archived: boolean;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  published: boolean;

  @ApiProperty()
  @Expose()
  minDays: number;

  @ApiProperty()
  @Expose()
  maxDays: number;

  @ApiProperty()
  @Expose()
  navigationHelp: MultiLangString;

  @ApiProperty()
  @Expose()
  status: LocationStatusDto;

  @ApiProperty({ description: 'Comments from manager to fix' })
  @Expose()
  statusComment: string;

  @ApiProperty({ description: 'Sum of all discounts in % e.g. 12%' })
  @Expose()
  discount?: number;

  @ApiProperty()
  @Expose()
  yandexMapExternalRef?: string;

  @ApiProperty()
  @Expose()
  smartWidgetExternalRef?: string;

  @ApiProperty()
  @Expose()
  updatedAt: Date;

  constructor(partial?: Partial<LocationDto>) {
    Object.assign(this, partial);
  }
}
