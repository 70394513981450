import { plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { PartialDeep } from 'type-fest';
import { v4 as uuid } from 'uuid';

import { Id, ObjectOrId, PromiseOrValue, WithId } from './types';

export function convert<T, F>(from: F, to: ClassType<T>, after?: (f: F, t: T) => T) {
  const result = plainToClass(to, from);

  if (after) {
    return after(from, result);
  }

  return result;
}

export function convertArray<T, F>(
  from: F[] | readonly F[],
  to: ClassType<T>,
  after?: (f: F, t: T) => T,
) {
  return (from as F[])?.map(f => convert(f, to, after));
}

export function createObject<T>(type: ClassType<T>, plain: PartialDeep<T>) {
  return convert(plain, type) as T;
}

export function newUuid() {
  return uuid();
}

export function objectEntries<T>(obj: T) {
  return Object.entries(obj) as {
    [K in keyof T]: [K, T[K]];
  }[keyof T][];
}

export function objectEntriesMap<T>(obj: T) {
  return Object.entries(obj).map(entry => {
    return {
      key: entry[0],
      value: entry[1],
    };
  }) as {
    [K in keyof T]: {
      key: K;
      value: T[K];
    };
  }[keyof T][];
}

export function extractId<T extends WithId>(objectOrId: ObjectOrId<T>): Id<T> {
  if (objectOrId === null || objectOrId === undefined) {
    return objectOrId as Id<T>;
  }
  return typeof objectOrId === 'object' ? (objectOrId as WithId).id : objectOrId;
}

export function objectFromId<T extends WithId, R extends PromiseOrValue<T>>(
  objectOrId: ObjectOrId<T>,
  getter: (id: Id<T>) => R,
): R {
  if (typeof objectOrId === 'object') {
    return objectOrId as R;
  }

  return getter(objectOrId);
}

export function* iterMap<T, R>(input: Iterable<T>, mapFun: (v: T) => R) {
  for (const i of input) {
    yield mapFun(i);
  }
}

export function subtractPercentAndRound(num: number, percent: number) {
  return Math.round(num - (num / 100) * percent);
}

export function getPercentAndRound(num: number, percent: number) {
  return Math.round((num / 100) * percent);
}
