// from here https://github.com/ppetzold/nestjs-paginate/blob/df6ff31b999d62d3e6ca72895098d2cb51d82a97/src/decorator.ts#L5
export class PageRequestDto {
  page?: number;
  limit?: number;
  // sortBy?: [string, string][];
  // searchBy?: string[];
  // search?: string;
  filter?: { [column: string]: string | string[] };
  // path: string;

  constructor(partial?: Partial<PageRequestDto>) {
    Object.assign(this, partial);
  }
}
