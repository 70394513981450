import { IsNotEmpty, IsString } from 'class-validator';

import { ApiProperty } from '../../utils';

export class CodeVerificationFormDto {
  @ApiProperty()
  @IsNotEmpty()
  @IsString()
  readonly emailOrPhone: string;

  @ApiProperty()
  @IsNotEmpty()
  readonly verificationCode: string;
}
