import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class InitCertificatePaymentDto {
  @ApiProperty()
  @Expose()
  certificateId: number;

  constructor(partial?: Partial<InitCertificatePaymentDto>) {
    Object.assign(this, partial);
  }
}
