import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class TelegramUserDto {
  @Expose()
  @ApiProperty()
  userId: number;

  @Expose()
  @ApiProperty()
  chatId: number;

  @Expose()
  @ApiProperty()
  username: string;

  @Expose()
  @ApiProperty()
  userFirstName?: string;

  @Expose()
  @ApiProperty()
  userLastName?: string;

  @Expose()
  @ApiProperty()
  groupname?: string;

  constructor(partial?: Partial<TelegramUserDto>) {
    Object.assign(this, partial);
  }
}
