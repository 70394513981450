import { priceTypeById } from '../price-type/price-type.data';
import { rawElementByIdFunc } from '../raw-element-by-id';
import { TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR } from '../tour-type/tour-type.data';

export const FACILITY_RAW_DATA = [
  {
    id: 'barbecue',
    nameMl: {
      ru: 'Беседка с мангалом',
    },
    descriptionMl: {
      ru: 'Стоимость аренды в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('ONCE'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'breakfast',
    nameMl: {
      ru: 'Завтрак',
    },
    descriptionMl: {
      ru: 'Включен/не включен в стоимость; стоимость если не включен в день, либо "по меню"',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON_NIGHT',
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'dinner',
    nameMl: {
      ru: 'Ужин',
    },
    descriptionMl: {
      ru: 'Включен/не включен в стоимость; стоимость если не включен в день, либо "по меню"',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON_NIGHT',
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'fish',
    nameMl: {
      en: 'Fish',
      ru: 'Рыбы',
    },
    descriptionMl: {
      ru: 'Доступные виды рыб для рыбалки',
    },
    type: 'fish',
    multiple: false,
    required: true,
    prices: [
      {
        price: priceTypeById('ONCE'),
        descriptionMl: {
          ru: 'Стоимость нормы',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_FISH],
  },
  {
    id: 'fishing-rent',
    nameMl: {
      ru: 'Аренда инвентаря для рыбалки',
    },
    descriptionMl: {
      ru: 'Стоимость в сутки или входит в стоимость',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_PERSON_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH],
  },
  {
    id: 'fishing-type',
    nameMl: {
      en: 'Fishing type',
      ru: 'Вид рыбалки',
    },
    descriptionMl: {
      ru: 'Доступные виды рыбалки',
    },
    type: 'fishing-type',
    multiple: false,
    required: true,
    prices: [
      {
        price: priceTypeById('FIRST_PERSON'),
        descriptionMl: {
          ru: 'Базовая стоимость',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON'),
        descriptionMl: {
          ru: 'Цена за доп. человека',
        },
      },
      {
        price: priceTypeById('FIRST_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за день',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за доп. человека за день',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_FISH],
  },
  {
    id: 'full-board',
    nameMl: {
      ru: 'Полный пансион',
    },
    descriptionMl: {
      ru: 'Включен/не включен в стоимость; стоимость если не включен в день',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON_NIGHT',
          nameMl: {
            ru: 'Цена за ночь на человека',
          },
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'horses',
    nameMl: {
      ru: 'Лошади',
    },
    descriptionMl: {
      ru: 'Стоимость аренды в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_PERSON_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'house-rent',
    nameMl: {
      ru: 'Проживание',
    },
    descriptionMl: {
      ru: 'Стоимость аренды в сутки, указать количество мест',
    },
    type: 'house-rent',
    multiple: false,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_NIGHT'),
        descriptionMl: null,
      },
      {
        price: {
          id: 'PER_PERSON_NIGHT',
          nameMl: {
            ru: 'Цена за ночь на человека',
          },
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'lunch',
    nameMl: {
      ru: 'Обед',
    },
    descriptionMl: {
      ru: 'Включен/не включен в стоимость; стоимость если не включен в день, либо "по меню"',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON_NIGHT',
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'other',
    nameMl: {
      ru: 'Прочее',
    },
    descriptionMl: {
      ru: 'Услуга, включенная в стоимость, либо с оплатой за единицу',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON',
          nameMl: {
            ru: 'Цена за человека',
          },
        },
        descriptionMl: {
          ru: 'Цена за единицу',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'sauna',
    nameMl: {
      ru: 'Баня',
    },
    descriptionMl: {
      ru: 'Стоимость за 2 часа',
    },
    type: 'sauna',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('ONCE'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'tent-rent',
    nameMl: {
      ru: 'Аренда палатки',
    },
    descriptionMl: {
      ru: 'Стоимость в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_NIGHT'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'transfer',
    nameMl: {
      ru: 'Трансфер',
    },
    descriptionMl: {
      ru: 'Трансфер',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('ONCE'),
        descriptionMl: {
          ru: 'Стоимость трансфера',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR],
  },
  {
    id: 'beast',
    nameMl: {
      en: 'Beasts',
      ru: 'Дичь',
    },
    descriptionMl: {
      ru: 'Звери, доступные для охоты',
    },
    type: 'beast',
    multiple: false,
    required: true,
    prices: [
      {
        price: priceTypeById('ONCE'),
        descriptionMl: {
          ru: 'Стоимость лицензии',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'beaters',
    nameMl: {
      ru: 'Загонщики',
    },
    descriptionMl: {
      ru: 'Стоимость в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'dogs',
    nameMl: {
      ru: 'Собаки',
    },
    descriptionMl: {
      ru: 'Стоимость в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'hideout',
    nameMl: {
      ru: 'Скрадок',
    },
    descriptionMl: {
      ru: 'Плата в сутки',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_PERSON_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'hunt-type',
    nameMl: {
      en: 'Hunt type',
      ru: 'Вид охоты',
    },
    descriptionMl: {
      ru: 'Доступные виды охоты',
    },
    type: 'hunt-type',
    multiple: false,
    required: true,
    prices: [
      {
        price: priceTypeById('FIRST_PERSON'),
        descriptionMl: {
          ru: 'Егерьское обслуживание',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON'),
        descriptionMl: {
          ru: 'Цена за доп. человека',
        },
      },
      {
        price: priceTypeById('FIRST_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за день',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за доп. человека за день',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'skinning',
    nameMl: {
      ru: 'Разделка трофея',
    },
    descriptionMl: {
      ru: 'Есть/Нет',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'weapon-cleaning',
    nameMl: {
      ru: 'Чистка оружия',
    },
    descriptionMl: {
      ru: 'Стоимость чистки за единицу',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: {
          id: 'PER_PERSON',
          nameMl: {
            ru: 'Цена за человека',
          },
        },
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_HUNT],
  },
  {
    id: 'tourism-rent',
    nameMl: {
      ru: 'Аренда инвентаря для похода',
    },
    descriptionMl: {
      ru: 'Стоимость в сутки или входит в стоимость',
    },
    type: 'default',
    multiple: true,
    required: false,
    prices: [
      {
        price: priceTypeById('PER_PERSON_DAY'),
        descriptionMl: null,
      },
    ],
    tourTypes: [TOUR_TYPE_TOUR],
  },
  {
    id: 'tourism-type',
    nameMl: {
      en: 'Tourism type',
      ru: 'Вид туризма',
    },
    descriptionMl: {
      ru: 'Доступные виды туризма',
    },
    type: 'tourism-type',
    multiple: false,
    required: true,
    prices: [
      {
        price: priceTypeById('FIRST_PERSON'),
        descriptionMl: {
          ru: 'Базовая стоимость',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON'),
        descriptionMl: {
          ru: 'Цена за доп. человека',
        },
      },
      {
        price: priceTypeById('FIRST_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за день',
        },
      },
      {
        price: priceTypeById('EXTRA_PERSON_DAY'),
        descriptionMl: {
          ru: 'Цена за доп. человека за день',
        },
      },
    ],
    tourTypes: [TOUR_TYPE_TOUR],
  },
] as const;

export const facilityById = rawElementByIdFunc(FACILITY_RAW_DATA);
