import { Type } from 'class-transformer';

import { PageResponseDto } from '../../common';
import { ApiProperty } from '../../utils';
import { TourManagerDto } from './tour-manager.dto';

export class TourManagerPageResponseDto extends PageResponseDto<TourManagerDto> {
  @ApiProperty()
  @Type(() => TourManagerDto)
  override docs: TourManagerDto[];
}
