/* eslint-disable @typescript-eslint/naming-convention */
// TODO: change type in v4.0
export enum TuiDayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
