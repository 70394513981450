import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';
import { PhoneCodeDto } from './phone-code.dto';

export class LoginFormDto {
  @ApiProperty()
  @IsNotEmpty()
  readonly username: string;

  @ApiProperty()
  @IsNotEmpty()
  readonly password: string;

  @ApiProperty()
  readonly twoFactorEnabled: boolean;

  @ApiProperty()
  readonly phoneCodeDto: PhoneCodeDto;
}
