import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { PromocodeCertificateAmountOptionDto } from './promocode-certificate-amount-option.dto';

@Exclude()
export class InitPromocodeCertificateDto {
  @ApiProperty()
  @Expose()
  amount: PromocodeCertificateAmountOptionDto;

  @ApiProperty()
  @Expose()
  userId: number;

  constructor(partial?: Partial<InitPromocodeCertificateDto>) {
    Object.assign(this, partial);
  }
}
