import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { TackleDto } from '../tackle';
import { TourTypeDto } from '../tour-type/tour-type.dto';

@Exclude()
export class ActivityTypeDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => TourTypeDto)
  tourType: TourTypeDto;

  @ApiProperty()
  @Type(() => TackleDto)
  @Expose()
  tackles: TackleDto[];
}
