import { Exclude, Expose, Type } from 'class-transformer';

import {
  LocationAggregationKey,
  LocationAggregationTypeByKey,
} from '../../../static-data/aggregations';
import { ApiProperty } from '../../../utils/swagger-decorators/swagger-decorators-nest.decorator';
import { Pattern } from '../../../utils/types';
import { GeoHashAggregationDto } from './geo-hash-grid-aggregation.dto';
import { RangeAggregationDto } from './range-aggregation.dto';
import { StatsAggregationDto } from './stats-aggregation.dto';
import { TermsAggregationDto } from './terms-aggregation.dto';

@Exclude()
export class LocationAggregationDto {
  @ApiProperty()
  @Type(() => StatsAggregationDto)
  @Expose()
  price?: StatsAggregationDto;

  @ApiProperty()
  @Type(() => RangeAggregationDto)
  @Expose()
  rating?: RangeAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  beast?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  huntType?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  fish?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  fishingType?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  tourismType?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => TermsAggregationDto)
  @Expose()
  countryState?: TermsAggregationDto;

  @ApiProperty()
  @Type(() => GeoHashAggregationDto)
  @Expose()
  geoClustering?: GeoHashAggregationDto;

  constructor(partial: Partial<LocationAggregationDto>) {
    return Object.assign(this, partial);
  }
}

// !!!!DO NOT REMOVE!!! using this type to validate the correct list of properties in class
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Test = Pattern<LocationAggregationDtoPattern, Required<LocationAggregationDto>>;

type LocationAggregationDtoPattern = {
  [K in LocationAggregationKey]: {
    stats: StatsAggregationDto;
    range: RangeAggregationDto;
    terms: TermsAggregationDto;
    geohash_grid: GeoHashAggregationDto;
  }[LocationAggregationTypeByKey<K>];
};
