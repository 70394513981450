import { Exclude, Expose, Type } from 'class-transformer';
import { IsEmail, IsPhoneNumber } from 'class-validator';

import { ApiProperty } from '../../utils';
import { CountryDto } from '../country/country.dto';
import { GenderDto } from '../gender/gender.dto';

@Exclude()
export class PersonDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  firstName: string;

  @ApiProperty()
  @Expose()
  lastName: string;

  @ApiProperty()
  @Expose()
  dateOfBirth: Date;

  @ApiProperty()
  @Expose()
  @Type(() => CountryDto)
  country: CountryDto;

  @ApiProperty()
  @Expose()
  @Type(() => GenderDto)
  gender: GenderDto;

  @ApiProperty()
  @IsEmail()
  @Expose()
  email: string;

  @ApiProperty()
  @IsPhoneNumber(null)
  @Expose()
  phoneNumber: string;

  constructor(partial?: Partial<PersonDto>) {
    Object.assign(this, partial);
  }
}
