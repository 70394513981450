import { ApiProperty } from '../utils';

export abstract class PageResponseDto<T = unknown> {
  @ApiProperty()
  docs: T[];

  @ApiProperty()
  total: number;

  constructor(partial?: Partial<PageResponseDto<T>>) {
    Object.assign(this, partial);
  }
}
