import { formatDate as ngFormatDate } from '@angular/common';
import * as moment from 'moment';

export function getMonthNames(locale: string): string[] {
  const format = new Intl.DateTimeFormat(locale, { month: 'long' });
  const months = [];
  for (let month = 0; month < 12; month++) {
    const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
    months.push(format.format(testDate));
  }

  return months;
}

function formatDate(input: moment.MomentInput, format = 'd MMM') {
  return `${ngFormatDate(moment(input).toDate(), format, 'ru')}`;
}

export function dateToString(from: moment.MomentInput, to?: moment.MomentInput, format?: string) {
  if (!from && !to) {
    return '';
  }

  if (!from || !to) {
    return formatDate(from || to, format);
  }

  if (moment(from).diff(to, 'day') === 0) {
    return formatDate(from, format);
  }

  return `${formatDate(from, format)} - ${formatDate(to, format)}`;
}
