export const formatPriceAmount = (amount: number, options?: Intl.NumberFormatOptions) => {
  const formatter = Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
    ...options,
  });

  return formatter.format(amount);
};
