/* eslint-disable @typescript-eslint/no-explicit-any */

import * as moment from 'moment';

import { UniversalDate, UniversalDateLike } from '../dto/common';
import { IterableOrValue } from './types';

type DateInput = moment.MomentInput | UniversalDate;
type DateRange = { from?: DateInput; to?: DateInput };
type DateOrRange = DateInput | DateRange;
type DateRangeInput = IterableOrValue<DateOrRange>;

export function getDaysCount(dateFrom: DateInput, dateTo: DateInput) {
  return dateFrom && dateTo ? 1 + Math.abs(moment(dateFrom).diff(dateTo, 'day')) : 1;
}

export function hasUnavailableDate(testPeriod: DateRange, availablePeriods: DateRangeInput) {
  return !isRangeInclusion(testPeriod, availablePeriods);
}

export function getDatesOverlap(testPeriods: DateRangeInput, availablePeriods: DateRangeInput) {
  return rangeOverlap(testPeriods, availablePeriods, getOverlap);
}

export function isValidDate(v: unknown): v is DateInput {
  if (v instanceof Date) {
    return true;
  }

  if (typeof v === 'object') {
    if ('day' in v && 'month' in v && 'year' in v) {
      const { day, month, year } = v as UniversalDateLike;
      return UniversalDate.isValidDay(year, month, day);
    }

    return false;
  }

  return moment(v, null, true).isValid();
}

function isIterable<T>(v: unknown): v is Iterable<T> {
  // checks for null and undefined
  if (v == null) {
    return false;
  }
  return typeof v[Symbol.iterator] === 'function';
}

export function isValidDateRange(v: unknown): v is DateRange {
  // date range is object
  if (typeof v !== 'object') {
    return false;
  }

  const { from, to } = v as { from: DateInput; to: DateInput };

  // at least one of two properties have to be truthy
  if (!from && !to) {
    return false;
  }

  // `from` have to be a valid input if defined
  if (from != null && !isValidDate(from)) {
    return false;
  }

  // `to` have to be a valid input if defined
  if (to != null && !isValidDate(to)) {
    return false;
  }

  return true;
}

function hasIntersection(x: DateRange, y: DateRange) {
  if (!moment(x.from).isSameOrBefore(y.to)) {
    return false;
  }

  if (!moment(x.to).isSameOrAfter(y.from)) {
    return false;
  }

  return true;
}

function isInclusion(x: DateRange, y: DateRange) {
  if (!moment(x.to).isSameOrBefore(y.to)) {
    return false;
  }

  if (!moment(x.from).isSameOrAfter(y.from)) {
    return false;
  }

  return true;
}

function getOverlap(x: DateRange, y: DateRange): DateRange | null {
  if (!hasIntersection(x, y)) {
    return null;
  }

  return {
    from: moment.max(moment(x.from), moment(y.from)),
    to: moment.min(moment(x.to), moment(y.to)),
  };
}

export function isRangeIntersection(x: DateRangeInput, y: DateRangeInput) {
  return rangeComparison(x, y, hasIntersection, 'any');
}

export function isRangeInclusion(x: DateRangeInput, y: DateRangeInput) {
  return rangeComparison(x, y, isInclusion, 'one-to-any');
}

// we use generator to support 'on-the-fly' conversions to DateRange
function* normalizedIteration(input: DateRangeInput) {
  const iterableInput = !isIterable(input) ? [input] : input;

  for (const v of iterableInput) {
    if (isValidDateRange(v)) {
      yield v;
      continue;
    }

    if (isValidDate(v)) {
      yield {
        from: moment(v),
        to: moment(v),
      };
      continue;
    }
  }
}

function rangeComparison(
  x: DateRangeInput,
  y: DateRangeInput,
  condition: (i: DateRange, j: DateRange) => boolean,
  mode: 'one-to-any' | 'any',
) {
  if (mode === 'any') {
    for (const left of normalizedIteration(x)) {
      for (const right of normalizedIteration(y)) {
        if (condition(left, right)) {
          return true;
        }
      }
    }

    return false;
  }

  if (mode === 'one-to-any') {
    for (const left of normalizedIteration(x)) {
      let found = false;
      for (const right of normalizedIteration(y)) {
        if (condition(left, right)) {
          found = true;
          break;
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  }

  throw new Error(`Incorrect mode value: ${mode}`);
}

function rangeOverlap(
  x: DateRangeInput,
  y: DateRangeInput,
  condition: (i: DateRange, j: DateRange) => DateRange | null,
) {
  const overlaps: DateRange[] = [];

  for (const left of normalizedIteration(x)) {
    for (const right of normalizedIteration(y)) {
      const overlap = condition(left, right);
      if (overlap) {
        overlaps.push(overlap);
      }
    }
  }
  return overlaps;
}

/**
 * @deprecated
 * replace discount dates with UniversalDate and remove this function then
 */
export function toMskDate(fullDate: Date): moment.Moment {
  return moment({
    year: fullDate.getFullYear(),
    month: fullDate.getMonth(),
    date: fullDate.getDate(),
  }).utcOffset('+0300', true);
}

export function getDatesText(
  dateOrRange: DateOrRange,
  formatter: (date: Date) => string,
  fallback: string = 'даты не заданы',
) {
  const range = isValidDateRange(dateOrRange)
    ? dateOrRange
    : isValidDate(dateOrRange)
    ? { from: dateOrRange, to: dateOrRange }
    : null;

  if (!range || !range.from || !range.to) {
    return fallback;
  }

  return moment(range.from).isSame(range.to)
    ? `${formatter(moment(range.from).toDate())}`
    : `${formatter(moment(range.from).toDate())} - ${formatter(moment(range.to).toDate())}`;
}
