import { Exclude, Expose } from 'class-transformer';

import { GeoLocationDto } from '../geo-location.dto';
import { TourMapDto } from '../tour-map.dto';

@Exclude()
export class GeoHashAggregationDto {
  @Expose()
  buckets: GeoHashAggregationBucketDto[];

  constructor(partial: Partial<GeoHashAggregationDto>) {
    return Object.assign(this, partial);
  }
}

@Exclude()
export class GeoHashAggregationBucketDto<
  T extends GeoHashAggregationBucketType = GeoHashAggregationBucketType,
> {
  @Expose()
  type: T;

  @Expose()
  data: PlaceMarkData<T>;
}

export type GeoHashAggregationBucketType = 'tour' | 'cluster';

type PlaceMarkData<T extends GeoHashAggregationBucketType = GeoHashAggregationBucketType> =
  GeoHashAggregationBucketDataMap[T];

type GeoHashAggregationBucketDataMap = {
  tour: TourMapDto;
  cluster: GeoHashAggregationCluster;
};

export type GeoHashAggregationCluster = {
  docCount: number;
  geoLocation: GeoLocationDto;
};
