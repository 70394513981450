import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as effects from './search-filters.effects';
import { searchFiltersFeature } from './search-filters.feature';

@NgModule({
  imports: [StoreModule.forFeature(searchFiltersFeature), EffectsModule.forFeature(effects)],
})
export class SearchFilterFeatureModule {}
