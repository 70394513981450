export const GENDER_RAW_DATA = [
  {
    id: 'F',
    nameMl: {
      en: 'Female',
      ru: 'Женский',
    },
  },
  {
    id: 'M',
    nameMl: {
      en: 'Male',
      ru: 'Мужской',
    },
  },
] as const;

export const [GENDER_FEMALE, GENDER_MALE] = GENDER_RAW_DATA;
