import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { DocumentTypeType } from './document-type.type';

@Exclude()
export class DocumentTypeDto {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  @Expose()
  id: DocumentTypeType;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  constructor(partial?: Partial<DocumentTypeDto>) {
    Object.assign(this, partial);
  }
}
