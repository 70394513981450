import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OverloadParameters } from '@turgenev/common';
import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { map } from 'rxjs/operators';

import { Config } from '../config';

function convertMap<T>(type: ClassType<T>) {
  return map(i => {
    if (!type) {
      return i as T;
    }

    return plainToClass(type, i);
  });
}

type RequestOptions<T extends ('get' | 'post') & keyof HttpClient> = T extends 'get'
  ? OverloadParameters<HttpClient['get']>[1]
  : T extends 'post'
  ? OverloadParameters<HttpClient['post']>[2]
  : never;

type RequestConfig<T, P extends 'get' | 'post'> = {
  responseType?: ClassType<T>;
  options?: RequestOptions<P>;
} & (P extends 'post'
  ? {
      noPlainBody?: boolean;
    } // eslint-disable-next-line @typescript-eslint/ban-types
  : {});

@Injectable({
  providedIn: 'root',
})
export class Api {
  constructor(private http: HttpClient, private config: Config) {}

  private buildPath(url: string) {
    if (!url || url.match(/^http/)) {
      return url;
    }

    return `${this.config.baseUrl.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;
  }

  get<T = void>(url: string, config?: RequestConfig<T, 'get'>) {
    const { responseType, options } = config || {};
    const builtPath = this.buildPath(url);
    return this.http.get<T>(builtPath, options as unknown).pipe(convertMap(responseType));
  }

  delete<T = void>(url: string, responseType?: ClassType<T>) {
    const builtPath = this.buildPath(url);
    return this.http.delete<T>(builtPath).pipe(convertMap(responseType));
  }

  post<T = void, B = unknown>(url: string, body?: B, config?: RequestConfig<T, 'post'>) {
    const { responseType, options, noPlainBody } = config || {};
    const builtPath = this.buildPath(url);
    const plainBody = noPlainBody ? body : body ? classToPlain(body) : null;
    return this.http
      .post<T>(builtPath, plainBody, options as unknown)
      .pipe(convertMap(responseType));
  }

  put<T = void, B = unknown>(url: string, body?: B, responseType?: ClassType<T>) {
    const builtPath = this.buildPath(url);
    const plainBody = body ? classToPlain(body) : null;
    return this.http.put<T>(builtPath, plainBody).pipe(convertMap(responseType));
  }
}
