import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { TourType } from './tour-type.type';

@Exclude()
export class TourTypeDto {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  @Expose()
  id: TourType;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;
}
