import { Pattern } from '../../../utils/types';
import { FacilityType } from '../../facility/facilities';
import { BasePriceFacilityItemAdapter } from './base-facility-item-adapter';

type FType = Pattern<FacilityType, 'house-rent'>;

export class HouseRentFacilityItemAdapter extends BasePriceFacilityItemAdapter<FType> {
  override getValue(): null {
    return null;
  }
}
