import { IsNotEmpty, MinLength } from 'class-validator';

import { ApiProperty } from '../../utils';

export class RegisterFormStepTwoDto {
  @ApiProperty()
  @IsNotEmpty()
  @MinLength(5)
  readonly password: string;

  @ApiProperty()
  @IsNotEmpty()
  readonly emailOrPhone: string;

  constructor(partial: Partial<RegisterFormStepTwoDto>) {
    Object.assign(this, partial);
  }
}
