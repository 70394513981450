import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';
import { GeoLocationDto } from './geo-location.dto';
import { TransportType } from './station-type.type';

export class GeoLocationSearchDto extends GeoLocationDto {
  @ApiProperty()
  @IsNotEmpty()
  type: TransportType;
}
