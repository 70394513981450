import { Exclude, Expose } from 'class-transformer';

import { LocationGeoObjectDto } from '../location-geo-object';

@Exclude()
export class LocationTransportDto {
  @Expose()
  id: number;

  @Expose()
  geoObject: LocationGeoObjectDto;

  @Expose()
  distanceKm: number;

  constructor(partial?: Partial<LocationTransportDto>) {
    Object.assign(this, partial);
  }
}
