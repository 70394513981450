import * as moment from 'moment';

import { multiLangCoalesce, MultiLangString } from '../../common/multi-lang-string';
import { BookingFacilityItem } from '../booking-facility';
import { LocationFacilityItem } from '../location-facility';
import { isFacilityOfType } from './facility-type.type';

export function getFacilityName(
  facility: BookingFacilityItem,
  fallbackMultiLangString?: MultiLangString,
  priority: 'default' | 'custom' = 'default',
): MultiLangString {
  let multiLangString: MultiLangString = {};

  switch (facility.locationItem.type) {
    case 'beast':
      multiLangString = facility.locationItem.beast.nameMl;
      break;
    case 'hunt-type':
      multiLangString = facility.locationItem.huntType.nameMl;
      break;
    case 'fishing-type':
      multiLangString = facility.locationItem.fishingType.nameMl;
      break;
    case 'fish':
      multiLangString = facility.locationItem.fish.nameMl;
      break;
    case 'tourism-type':
      multiLangString = facility.locationItem.tourismType.nameMl;
      break;
  }

  if (priority === 'custom') {
    return multiLangCoalesce(
      facility.locationItem.nameMl,
      multiLangString,
      fallbackMultiLangString,
    );
  }

  return multiLangCoalesce(multiLangString, facility.locationItem.nameMl, fallbackMultiLangString);
}

export function hasFutureDates(item: LocationFacilityItem) {
  if (!isFacilityOfType(item, ['beast', 'hunt-type', 'tourism-type', 'fishing-type'])) {
    return true;
  }
  if (item.availability.mode === 'any') {
    return true;
  }

  if (item.availability.mode === 'periods') {
    const availablePeriods = item.availability.periods.filter(period =>
      moment().isSameOrBefore(moment(period.start), 'day'),
    );

    if (!availablePeriods.length) {
      return false;
    }
  }

  if (item.availability.mode === 'seasons') {
    const availableSeasons = item.availability.seasons.filter(period =>
      moment().isSameOrBefore(moment(period.end), 'day'),
    );

    if (!availableSeasons.length) {
      return false;
    }
  }

  return true;
}

export function getFacilityAvailability(item: LocationFacilityItem) {
  if (!item) {
    return null;
  }

  return 'availability' in item ? item.availability : null;
}
