import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { CountryStateDto, LocationFilterDto, TourDto } from '@turgenev/dto';

import { SearchActions, SearchFiltersActions, SearchPageActions } from './search-filters.actions';

export type ViewMode = 'MAP' | 'LIST';

const initialState = {
  filters: {
    tourType: null,
    geoHashGridPrecision: 6,
    page: 1,
    limit: 10,
  } as LocationFilterDto,
  listItems: [] as TourDto[],
  lastPageLoaded: false,
  listLoading: false,
  selectedTourId: 0,
  regions: [] as CountryStateDto[],
  regionsLoading: false,
};

type State = typeof initialState;

export const searchFiltersFeature = createFeature({
  name: 'searchFilters',
  reducer: createReducer(
    initialState,
    on(
      SearchActions.listItemsPageCreated,
      (state): State => ({
        ...state,
        listLoading: true,
      }),
    ),
    on(
      SearchFiltersActions.filtersPageUpdated,
      (state, { filter }): State => ({
        ...state,
        filters: {
          ...state.filters,
          ...filter,
        },
      }),
    ),
    on(
      SearchFiltersActions.datesUpdated,
      (state, { dateStart, dateEnd }): State => ({
        ...state,
        filters: { ...state.filters, dateStart, dateEnd },
      }),
    ),
    on(
      SearchFiltersActions.priceSortUpdated,
      (state, { sort }): State => ({
        ...state,
        filters: { ...state.filters, sort: { price: sort } },
      }),
    ),
    on(
      SearchFiltersActions.textUpdated,
      (state, { text }): State => ({
        ...state,
        filters: { ...state.filters, text },
      }),
    ),
    on(
      SearchFiltersActions.tourTypeUpdated,
      (state, { tourType }): State => ({
        ...state,
        regionsLoading: true,
        filters: { ...state.filters, countryState: [], tourType },
      }),
    ),
    on(
      SearchFiltersActions.reset,
      (state): State => ({
        ...state,
        listItems: [],
        listLoading: true,
        filters: { tourType: state.filters.tourType, page: 1, limit: 10 },
      }),
    ),
    on(
      SearchFiltersActions.priceUpdated,
      (state, { price }): State => ({
        ...state,
        filters: { ...state.filters, price },
      }),
    ),
    on(
      SearchFiltersActions.changed,
      (state): State => ({
        ...state,
        listItems: [],
        listLoading: true,
        lastPageLoaded: false,
        filters: { ...state.filters, page: 1 },
      }),
    ),

    on(
      SearchPageActions.pageIncremented,
      (state): State => ({
        ...state,
        listLoading: true,
        filters: { ...state.filters, page: state.filters.page + 1 },
      }),
    ),

    on(
      SearchActions.listItemsSet,
      (state, { listItems }): State => ({
        ...state,
        listItems,
        listLoading: false,
      }),
    ),
    on(
      SearchActions.listItemsAppended,
      (state, { listItems }): State => ({
        ...state,
        listItems: [...state.listItems, ...listItems],
        listLoading: false,
        lastPageLoaded: !listItems.length,
      }),
    ),
    on(
      SearchActions.regionsSet,
      (state, { regions }): State => ({
        ...state,
        regions,
        regionsLoading: false,
      }),
    ),
  ),
  extraSelectors: ({ selectFilters }) => ({
    selectSearchFiltersType: createSelector(selectFilters, f => f?.tourType),
    selectSearchFiltersDateStart: createSelector(selectFilters, f => f?.dateStart),
    selectSearchFiltersDateEnd: createSelector(selectFilters, f => f?.dateEnd),
    selectSearchFiltersPriceSort: createSelector(selectFilters, f => f?.sort?.price),
    selectFilterForListItems: createSelector(
      selectFilters,
      ({ geoLocation, geoHashGridPrecision, ...other }) => ({
        ...other,
      }),
    ),
    selectPage: createSelector(selectFilters, f => f?.page),
    selectPeopleCount: createSelector(selectFilters, f => f?.peopleCount || 1),
    selectActiveFiltersQuantity: createSelector(selectFilters, f => {
      if (!f) {
        return 0;
      }

      let quantity = 0;

      if (f.countryState) {
        quantity++;
      }
      if (f.dateStart || f.dateEnd) {
        quantity++;
      }
      if (f.rating) {
        quantity++;
      }
      if (f.price?.min || f.price?.max) {
        quantity++;
      }
      if (f.facilities?.beast?.ids.length || f.facilities?.fish?.ids.length) {
        quantity++;
      }
      if (f.peopleCount) {
        quantity++;
      }
      if (
        f.facilities?.['tourism-type']?.ids.length ||
        f.facilities?.['hunt-type']?.ids.length ||
        f.facilities?.['fishing-type']?.ids.length
      ) {
        quantity++;
      }

      return quantity;
    }),
  }),
});
