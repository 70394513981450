export const TOUR_TYPE_RAW_DATA = [
  {
    id: 'FISH',
    nameMl: {
      en: 'Fishing',
      ru: 'Рыбалка',
    },
  },
  {
    id: 'HUNT',
    nameMl: {
      en: 'Hunting',
      ru: 'Охота',
    },
  },
  {
    id: 'TOUR',
    nameMl: {
      en: 'Tourism',
      ru: 'Туризм',
    },
  },
] as const;

export const [TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR] = TOUR_TYPE_RAW_DATA;
