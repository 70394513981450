import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-nest.decorator';

@Exclude()
export class AdvertisementDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  markup: string;

  constructor(partial?: Partial<AdvertisementDto>) {
    Object.assign(this, partial);
  }
}
