import { MultiLangString } from '../../common';
import { BusinessFormType } from '../../dto/business-form/business-form.type';
import { DocumentTypeType } from '../../dto/document-type/document-type.type';
import { INDIVIDUAL_ENTREPRENEUR, ORGANIZATION, SELF_EMPLOYED } from '../business-form';

export const DOCUMENT_TYPE_RAW_DATA = [
  {
    id: 'PASSPORT',
    nameMl: {
      en: 'PASSPORT',
      ru: 'Паспорт',
    },
  },
  {
    id: 'INN',
    nameMl: {
      en: 'INN',
      ru: 'ИНН',
    },
  },
  {
    id: 'OGRN',
    nameMl: {
      en: 'OGRN',
      ru: 'ОГРН',
    },
  },
  {
    id: 'EGRIP',
    nameMl: {
      en: 'EGRIP',
      ru: 'ЕГРИП',
    },
  },
  {
    id: 'REGISTRATION',
    nameMl: {
      en: 'REGISTRATION',
      ru: 'Адрес прописки',
    },
  },
] as const;

export const [PASSPORT, INN, OGRN, EGRIP, REGISTRATION] = DOCUMENT_TYPE_RAW_DATA;

export const documentsConfig: {
  label: MultiLangString;
  documentType: DocumentTypeType;
  businessForms: BusinessFormType[] | 'any';
}[] = [
  {
    label: INN.nameMl,
    documentType: INN.id,
    businessForms: 'any',
  },
  {
    label: PASSPORT.nameMl,
    documentType: PASSPORT.id,
    businessForms: 'any',
  },
  {
    label: REGISTRATION.nameMl,
    documentType: REGISTRATION.id,
    businessForms: [INDIVIDUAL_ENTREPRENEUR.id, SELF_EMPLOYED.id],
  },
  {
    label: OGRN.nameMl,
    documentType: OGRN.id,
    businessForms: [ORGANIZATION.id],
  },
  {
    label: EGRIP.nameMl,
    documentType: EGRIP.id,
    businessForms: [INDIVIDUAL_ENTREPRENEUR.id],
  },
];
