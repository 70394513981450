import { Lazy } from '../../utils/lazy';
import { BookingFacilityDto } from '../booking-facility/booking-facility.dto';
import { BookingFacilityItem } from '../booking-facility/booking-facility-item';
import { FacilityType } from '../facility/facilities';
import { FacilityDto } from '../facility/facility.dto';
import { BeastFacilityItemAdapter } from './adapters/beast-facility-item-adapter';
import { FishFacilityItemAdapter } from './adapters/fish-facility-item-adapter';
import { FishingTypeFacilityItemAdapter } from './adapters/fishing-type-facility-item-adapter';
import { HouseRentFacilityItemAdapter } from './adapters/house-rent-facility-item-adapter';
import { HuntTypeFacilityItemAdapter } from './adapters/hunt-type-facility-item-adapter';
import { ILocationFacilityItemAdapter } from './adapters/location-facility-item-adapter.interface';
import { SaunaFacilityItemAdapter } from './adapters/sauna-facility-item-adapter';
import { SimplePriceFacilityItemAdapter } from './adapters/simple-facility-item-adapter';
import { TourismTypeFacilityItemAdapter } from './adapters/tourism-type-facility-item-adapter';
import { LocationFacilityDto } from './location-facility.dto';
import { LocationFacilityItem } from './location-facility-item';

const adaptersMapping = new Lazy(
  () =>
    /* eslint-disable prettier/prettier */
    ({
      'beast': new BeastFacilityItemAdapter(),
      'fish': new FishFacilityItemAdapter(),
      'hunt-type': new HuntTypeFacilityItemAdapter(),
      'fishing-type': new FishingTypeFacilityItemAdapter(),
      'tourism-type': new TourismTypeFacilityItemAdapter(),
      'house-rent': new HouseRentFacilityItemAdapter(),
      'sauna': new SaunaFacilityItemAdapter(),
      'default': new SimplePriceFacilityItemAdapter(),
    } as const),
  /* eslint-enable prettier/prettier */
);
type AdaptersMapping = ReturnType<(typeof adaptersMapping)['value']>;

type AdapterType<T extends FacilityType = FacilityType> = AdaptersMapping[T];

export type LocationFacilityItemValue<T extends FacilityType = FacilityType> = ReturnType<
  AdapterType<T>['getValue']
>;

function resolveAdapter<T extends FacilityType>(type: T) {
  return adaptersMapping.value()[type] as ILocationFacilityItemAdapter<T>;
}

// type FacilityOrItsWrapper<F extends FacilityType = FacilityType> =
//   | string
//   | FacilityDto<F>
//   | LocationFacilityDto<F>
//   | LocationFacilityItem<F>
//   | BookingFacilityDto<F>;

class LocationFacilityAdapter {
  itemsHaveSameType<X extends FacilityType>(
    left: LocationFacilityItem<X>,
    right: LocationFacilityItem,
  ): right is LocationFacilityItem<X> {
    if (!left || !right) {
      return false;
    }

    return left.type === right.type;
  }

  itemsHaveSameValue<X extends FacilityType>(
    left: LocationFacilityItem<X>,
    right: LocationFacilityItem,
  ) {
    if (!this.itemsHaveSameType(left, right)) {
      return false;
    }

    const adapter = resolveAdapter(left.type);
    return adapter.valuesAreEqual(left, right);
  }

  getItemValue<T extends FacilityType>(
    item: LocationFacilityItem<T>,
  ): LocationFacilityItemValue<T> {
    const adapter = resolveAdapter(item?.type);
    return adapter?.getValue(item) as LocationFacilityItemValue<T>;
  }

  isFacilityOfType<F extends FacilityType>(f: string, type: F): f is F;
  isFacilityOfType<F extends FacilityType>(f: FacilityDto, type: F): f is FacilityDto<F>;
  isFacilityOfType<F extends FacilityType>(
    f: LocationFacilityDto,
    type: F,
  ): f is LocationFacilityDto<F>;
  isFacilityOfType<F extends FacilityType>(
    f: LocationFacilityItem,
    type: F,
  ): f is LocationFacilityItem<F>;
  isFacilityOfType<F extends FacilityType>(
    f: BookingFacilityDto,
    type: F,
  ): f is BookingFacilityDto<F>;
  isFacilityOfType<F extends FacilityType>(
    f: BookingFacilityItem,
    type: F,
  ): f is BookingFacilityItem<F>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isFacilityOfType<F extends FacilityType>(f: any, type: F): boolean {
    if (typeof f === 'string') {
      return type === f;
    }

    if ('locationFacility' in f) {
      return this.isFacilityOfType((f as BookingFacilityDto).locationFacility, type);
    }

    if ('locationItem' in f) {
      return this.isFacilityOfType((f as BookingFacilityItem).locationItem, type);
    }

    if ('facility' in f) {
      return this.isFacilityOfType((f as LocationFacilityDto).facility, type);
    }

    if ('type' in f) {
      return this.isFacilityOfType((f as FacilityDto | LocationFacilityItem).type, type);
    }
  }
}

export const facilityAdapter = new LocationFacilityAdapter();
