import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { LocationStatusId } from './location-status-id.type';

export class LocationStatusDto {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  id: LocationStatusId;

  @ApiProperty()
  @IsNotEmpty()
  nameMl: MultiLangString;
}
