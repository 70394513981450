import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';

export class GeoLocationDto {
  @ApiProperty()
  @IsNotEmpty()
  lat: number;

  @ApiProperty()
  @IsNotEmpty()
  lng: number;

  constructor(partial: Partial<GeoLocationDto>) {
    return Object.assign(this, partial);
  }
}
