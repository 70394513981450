import { Exclude, Expose, Transform } from 'class-transformer';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { TourType } from '../tour-type';
import { GeoLocationDto } from './geo-location.dto';

const parseJSONValue = (v: string) => (v && typeof v === 'string' ? JSON.parse(v) : v);

@Exclude()
export class TourListItemDto {
  @ApiProperty({ description: 'Location id' })
  @Expose()
  locationId: number;

  @ApiProperty({ description: 'Location name' })
  @Expose()
  @Transform(parseJSONValue)
  titleMl: MultiLangString;

  @ApiProperty({ description: 'Location description' })
  @Expose()
  @Transform(parseJSONValue)
  descriptionMl: MultiLangString;

  @ApiProperty({ description: 'Location tour type' })
  @Expose()
  tourType: TourType;

  @ApiProperty({ description: 'Location owner brand name' })
  @Expose()
  @Transform(parseJSONValue)
  brandNameMl: MultiLangString;

  @ApiProperty({ description: 'Location region name' })
  @Expose()
  @Transform(parseJSONValue)
  countryStateNameMl: MultiLangString;

  @ApiProperty({ description: 'Location geolocation' })
  @Expose()
  @Transform(parseJSONValue)
  geoLocation: GeoLocationDto;

  @ApiProperty({ description: 'Tour min price' })
  @Expose()
  minPrice: number;

  @ApiProperty({ description: 'Location rating' })
  @Expose()
  rating: number;

  @ApiProperty({ description: 'Location feedback count' })
  @Expose()
  feedbackCount: number;

  @ApiProperty({ description: 'Location preview image id' })
  @Expose()
  imageId: number;

  constructor(partial?: Partial<TourListItemDto>) {
    Object.assign(this, partial);
  }
}
