import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class PromocodeCertificateAmountOptionDto {
  @ApiProperty()
  @Expose()
  amount: number;

  @ApiProperty()
  @Expose()
  price: number;
}
