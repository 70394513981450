import { Exclude, Expose, Type } from 'class-transformer';

import { DocumentTypeDto } from '../document-type';
import { FileDto } from '../file';

@Exclude()
export class ParticipantDocumentDto {
  @Expose()
  id: number;

  @Expose()
  @Type(() => DocumentTypeDto)
  documentType: DocumentTypeDto;

  @Expose()
  @Type(() => FileDto)
  file: FileDto;
}
