import { Exclude, Expose, Transform } from 'class-transformer';
import { IsNotEmpty, IsNotEmptyObject } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { TourType } from '../tour-type';
import { LocationStatusId } from './location-status-id.type';

const parseJSONValue = (v: string) => (v && typeof v === 'string' ? JSON.parse(v) : v);

@Exclude()
export class LocationListItemDto {
  @ApiProperty({ description: 'Location id' })
  @Expose()
  id: number;

  @ApiProperty({ description: 'Location name' })
  @IsNotEmptyObject()
  @Expose()
  @Transform(parseJSONValue)
  titleMl: MultiLangString;

  @ApiProperty({ description: 'Location description' })
  @IsNotEmptyObject()
  @Expose()
  @Transform(parseJSONValue)
  descriptionMl: MultiLangString;

  @ApiProperty({ description: 'Location tour type' })
  @IsNotEmpty()
  @Expose()
  tourType: TourType;

  @ApiProperty({ description: 'Location status id' })
  @Expose()
  statusId: LocationStatusId;

  @ApiProperty({ description: 'Location status name' })
  @Expose()
  @Transform(parseJSONValue)
  statusNameMl: MultiLangString;

  @ApiProperty({ description: 'Flag is location published' })
  @Expose()
  published: boolean;

  @ApiProperty({ description: 'Location preview image id' })
  @Expose()
  imageId: number;

  @ApiProperty({ description: 'Location region' })
  @Expose()
  @Transform(parseJSONValue)
  countryStateNameMl: MultiLangString;

  @ApiProperty({ description: 'Location rating' })
  @Expose()
  rating: number;

  @ApiProperty({ description: 'Location feedback count' })
  @Expose()
  feedbackCount: number;

  @ApiProperty({ description: 'Location discount count of relevant dates' })
  @Expose()
  discountsCount: number;

  @ApiProperty({ description: 'Is location has available dates for booking' })
  @Expose()
  hasAvailableDates: boolean;

  constructor(partial: Partial<LocationListItemDto>) {
    Object.assign(this, partial);
  }
}
