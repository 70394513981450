import { Exclude, Expose, Transform } from 'class-transformer';
import { IsNotEmpty, IsNotEmptyObject } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { BookingStatus } from './booking-status.type';

const parseJSONValue = (v: string) => (v && typeof v === 'string' ? JSON.parse(v) : v);

@Exclude()
export class BookingListItemDto {
  @ApiProperty({ description: 'Booking id' })
  @Expose()
  id: number;

  @ApiProperty({ description: 'Location id' })
  @Expose()
  locationId: number;

  @ApiProperty({ description: 'Location name' })
  @IsNotEmptyObject()
  @Expose()
  @Transform(parseJSONValue)
  nameMl: MultiLangString;

  @ApiProperty({ description: 'Location description' })
  @IsNotEmptyObject()
  @Expose()
  @Transform(parseJSONValue)
  descriptionMl: MultiLangString;

  @ApiProperty({ description: 'Location rating' })
  @Expose()
  locationRating: number;

  @ApiProperty({ description: 'Location feedback count' })
  @Expose()
  feedbackCount: number;

  @ApiProperty({ description: 'Location tour type' })
  @IsNotEmpty()
  @Expose()
  tourType: string;

  @ApiProperty({ description: 'Booking state' })
  @Expose()
  status: BookingStatus;

  @ApiProperty({ description: 'Booking price after discount' })
  @Expose()
  price: number;

  @ApiProperty({ description: 'Price before discount' })
  @Expose()
  originalPrice: number;

  @ApiProperty({ description: 'Booking paid amount' })
  @Expose()
  paid: number;

  @ApiProperty({ description: 'Location preview image id' })
  @Expose()
  imageId: number;

  @ApiProperty({ description: 'Location region' })
  @Expose()
  @Transform(parseJSONValue)
  countryStateNameMl: MultiLangString;

  @ApiProperty({ description: 'Partner brand name' })
  @Expose()
  @Transform(parseJSONValue)
  brandNameMl?: MultiLangString;
}
