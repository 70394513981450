import { rawElementByIdFunc } from '../raw-element-by-id';
import { TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOUR } from '../tour-type/tour-type.data';

export const ACTIVITY_TARGET_TYPE_RAW_DATA = [
  {
    id: 1,
    nameMl: {
      ru: 'Копытные',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 2,
    nameMl: {
      ru: 'Медведи',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 3,
    nameMl: {
      ru: 'Пушные',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 4,
    nameMl: {
      ru: 'Птицы',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 7,
    nameMl: {
      ru: 'Хищники',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 8,
    nameMl: {
      ru: 'мирная рыба',
    },
    tourType: TOUR_TYPE_FISH,
  },
  {
    id: 9,
    nameMl: {
      ru: 'хищная рыба',
    },
    tourType: TOUR_TYPE_FISH,
  },
  {
    id: 10,
    nameMl: {
      ru: 'пеший',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 11,
    nameMl: {
      ru: 'сплав',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 12,
    nameMl: {
      ru: 'яхтинг',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 13,
    nameMl: {
      ru: 'воздушный',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 14,
    nameMl: {
      ru: 'погружения',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 15,
    nameMl: {
      ru: 'базы отдыха',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 16,
    nameMl: {
      ru: 'зимний',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 17,
    nameMl: {
      ru: 'прочие',
    },
    tourType: TOUR_TYPE_TOUR,
  },
  {
    id: 18,
    nameMl: {
      ru: 'детский лагерь',
    },
    tourType: TOUR_TYPE_TOUR,
  },
] as const;

export const activityTargetTypeById = rawElementByIdFunc(ACTIVITY_TARGET_TYPE_RAW_DATA);
