import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CountryStateDto, LocationFilterDto, LocationSort, TourDto } from '@turgenev/dto';

export const SearchFiltersActions = createActionGroup({
  source: 'Search Filters',
  events: {
    'Filters Page Updated': props<{ filter: LocationFilterDto }>(),
    'Text Updated': props<{ text: LocationFilterDto['text'] }>(),
    'Tour Type Updated': props<{ tourType?: LocationFilterDto['tourType'] }>(),
    'Price Sort Updated': props<{ sort: LocationSort['price'] }>(),
    'Dates Updated': props<{
      dateStart: LocationFilterDto['dateStart'];
      dateEnd: LocationFilterDto['dateEnd'];
    }>(),
    'Price Updated': props<{ price: LocationFilterDto['price'] }>(),
    Reset: emptyProps(),
    Changed: props<{ filters: LocationFilterDto }>(),
  },
});

export const SearchPageActions = createActionGroup({
  source: 'Search Page',
  events: {
    'Page Incremented': emptyProps(),
  },
});

export const SearchActions = createActionGroup({
  source: 'Search',
  events: {
    'List Items Page Created': emptyProps(),
    'List Items Appended': props<{ listItems: TourDto[] }>(),
    'List Items Set': props<{ listItems: TourDto[] }>(),
    'Regions Set': props<{ regions: CountryStateDto[] }>(),
  },
});
