import { parseUrl } from './file-utils';

const CLOUD_FLARE_FORMAT_VALUES = ['auto', 'webp', 'jpeg'] as const;
export type CloudFlareFormat = (typeof CLOUD_FLARE_FORMAT_VALUES)[number];

export type CloudFlareConfig = {
  width?: number;
  height?: number;
  format?: CloudFlareFormat;
};

export type CloudFlareConfigInput = CloudFlareConfig | string | number;

function isCloudFlareFormat(input: unknown): input is CloudFlareFormat {
  if (typeof input !== 'string') {
    return false;
  }

  return CLOUD_FLARE_FORMAT_VALUES.includes(input as CloudFlareFormat);
}

function configToArgs(config: CloudFlareConfig): string {
  return [
    config?.width ? `w=${config.width}` : null,
    config?.height ? `h=${config.height}` : null,
    config?.format ? `f=${config.format}` : null,
  ]
    .filter(i => !!i)
    .join(',');
}

function parseCloudFlareConfigFromString(input: string): CloudFlareConfig {
  let {
    width: widthStr,
    height: heightStr,
    format,
  } = /^(?<width>\d+)([x×](?<height>\d+))?(:(?<format>\w+))?$/i.exec(input)?.groups || {};

  const formatConfig: CloudFlareConfig = isCloudFlareFormat(format) ? { format } : undefined;

  const widthNum = Number(widthStr);
  const heightNum = Number(heightStr);

  const width = widthNum || heightNum || undefined;
  const height = heightNum || widthNum || undefined;

  return {
    width,
    height,
    ...formatConfig,
  };
}

export function parseCloudFlareConfig(config: CloudFlareConfigInput): CloudFlareConfig {
  if (!config) {
    return null;
  }

  if (typeof config === 'number') {
    return {
      width: config,
      height: config,
    };
  }

  if (typeof config === 'string') {
    return parseCloudFlareConfigFromString(config);
  }

  if (typeof config === 'object') {
    return config;
  }

  return null;
}

export function insertCloudFlareParams(inputUrl: string, config?: CloudFlareConfigInput) {
  config = parseCloudFlareConfig(config);

  if (!config) {
    config = {};
  }

  if (!config.format) {
    config.format = 'auto';
  }

  const cfArgs = configToArgs(config);

  const { origin, path } = parseUrl(inputUrl);

  const url = `${origin || ''}/cdn-cgi/image/${cfArgs}${path}`;

  return url;
}
