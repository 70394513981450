import { Type } from 'class-transformer';

import { DocumentTypeDto } from '../document-type';
import { FileDto } from '../file';

export class ParticipantDocumentSaveDto {
  @Type(() => DocumentTypeDto)
  documentType: DocumentTypeDto;

  @Type(() => FileDto)
  files: FileDto[];
}
