export const ROLE_RAW_DATA = [
  {
    id: 0,
    name: 'ADMIN',
  },
  {
    id: 1,
    name: 'USER',
  },
  {
    id: 2,
    name: 'MANAGER',
  },
  {
    id: 3,
    name: 'GUIDE',
  },
  {
    id: 4,
    name: 'GUIDE_PENDING',
  },
] as const;

export const [ROLE_ADMIN, ROLE_USER, ROLE_MANAGER, ROLE_GUIDE, GUIDE_PENDING] = ROLE_RAW_DATA;
