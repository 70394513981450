import { Type } from 'class-transformer';

import { PageResponseDto } from '../../common/page-response.dto';
import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-angular.decorator';
import { LocationListItemDto } from './location-list-item.dto';

export class GuideLocationsResponseDto extends PageResponseDto<LocationListItemDto> {
  @ApiProperty()
  @Type(() => LocationListItemDto)
  override docs: LocationListItemDto[];
}
