import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-nest.decorator';

@Exclude()
export class ChangeLocationReviewsSettingsDto {
  @ApiProperty()
  @Expose()
  smartWidgetExternalRef: string;

  @ApiProperty()
  @Expose()
  yandexReviewCount: number;

  @ApiProperty()
  @Expose()
  yandexRating: number;
}
