import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { BusinessFormType } from './business-form.type';

@Exclude()
export class BusinessFormDto {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  @Expose()
  id: BusinessFormType;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  constructor(partial?: Partial<BusinessFormDto>) {
    Object.assign(this, partial);
  }
}
