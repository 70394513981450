import { Exclude, Expose, Transform } from 'class-transformer';
import { TransformationType } from 'class-transformer/TransformOperationExecutor';

import { ApiProperty } from '../../utils';
import { TelegramUserDto } from '../telegram-user';
import { PromocodeCertificateAmountOptionDto } from './promocode-certificate-amount-option.dto';

@Exclude()
export class PromocodeCertificateDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  user: TelegramUserDto;

  @ApiProperty()
  @Expose()
  amount: PromocodeCertificateAmountOptionDto;

  @ApiProperty()
  @Expose()
  @Transform((v, cert, type) => {
    if (type === TransformationType.PLAIN_TO_CLASS) {
      return cert.promocode?.expirationDate;
    }

    return v;
  })
  expirationDate?: Date;

  @ApiProperty()
  @Expose()
  paid: number;

  @ApiProperty()
  @Expose()
  @Transform((v, cert, type) => {
    if (type === TransformationType.PLAIN_TO_CLASS) {
      return cert.promocode?.code;
    }

    return v;
  })
  code?: string;

  constructor(partial?: Partial<PromocodeCertificateDto>) {
    Object.assign(this, partial);
  }
}
