import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { isObject } from 'lodash';

import { multiLangCoalesce, MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';

@Exclude()
export class PartnerManageListItemDto {
  @ApiProperty()
  @Expose()
  id: number;

  @Expose()
  @Transform(val => (val && !isObject(val) ? JSON.parse(val) : val))
  businessFormNameMl: MultiLangString;

  @Expose()
  @Transform(val => (val && !isObject(val) ? JSON.parse(val) : val))
  brandNameMl: MultiLangString;

  @Expose()
  @Transform(
    (_, obj) =>
      multiLangCoalesce(
        JSON.parse(obj.organizationLegalNameMl),
        JSON.parse(obj.individualEntrepreneurLegalNameMl),
        `${obj.selfEmployedFirstName} ${obj.selfEmployedLastName} ${obj.selfEmployedPatronymic}`,
      ),
    { toClassOnly: true },
  )
  partnerNameMl: MultiLangString;

  @Expose()
  personPhone: string;

  @Expose()
  personEmail: string;

  @Expose()
  userEmail: string;

  @Expose()
  userPhone: string;

  @Expose()
  userId: number;

  @Expose()
  unpublishedLocationsCount: number;

  @Expose()
  publishedLocationsCount: number;

  @Expose()
  isTrusted: boolean;

  @Expose()
  @Type(() => Date)
  createdAt: Date;

  constructor(partial?: Partial<PartnerManageListItemDto>) {
    Object.assign(this, partial);
  }
}
