import { rawElementByIdFunc } from '../raw-element-by-id';

export const DISCOUNT_TYPE_RAW_DATA = [
  {
    id: 'GUIDE',
    nameMl: {
      ru: 'Скидка от гида',
    },
    descriptionMl: {
      ru: '',
    },
  },
  {
    id: 'TURGENEV',
    nameMl: {
      ru: 'Скидка от Тургенев',
    },
    descriptionMl: {
      ru: '',
    },
  },
] as const;

export const discountTypeById = rawElementByIdFunc(DISCOUNT_TYPE_RAW_DATA);
