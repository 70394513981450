import { Injectable } from '@angular/core';
import { BookingDto, BookingPriceDetails } from '@turgenev/dto';

import { Api } from '../api';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(private api: Api) {}

  calc(input: BookingDto) {
    const dto = !(input instanceof BookingDto) ? new BookingDto(input) : input;
    return this.api.put('price/calc', dto, BookingDto);
  }

  details(input: BookingDto) {
    const dto = !(input instanceof BookingDto) ? new BookingDto(input) : input;
    return this.api.put<BookingPriceDetails>('price/details', dto);
  }
}
