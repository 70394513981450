import { inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs';

import { CountryStateService } from '../../../providers/services/country-state.service';
import { LocationService } from '../../../providers/services/location.service';
import { SearchActions, SearchFiltersActions, SearchPageActions } from './search-filters.actions';
import { searchFiltersFeature } from './search-filters.feature';

export const emitSearchFilterChangedEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(
        SearchFiltersActions.filtersPageUpdated,
        SearchFiltersActions.reset,
        SearchFiltersActions.datesUpdated,
        SearchFiltersActions.priceSortUpdated,
        SearchFiltersActions.textUpdated,
        SearchFiltersActions.tourTypeUpdated,
        SearchFiltersActions.priceUpdated,
      ),
      concatLatestFrom(() => store.select(searchFiltersFeature.selectFilters)),
      map(([_, filters]) => SearchFiltersActions.changed({ filters })),
    );
  },
  { functional: true },
);

export const listItemsAppendStart = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    locationService = inject(LocationService),
  ) => {
    return actions$.pipe(
      ofType(SearchPageActions.pageIncremented),
      concatLatestFrom(() => store.select(searchFiltersFeature.selectFilterForListItems)),
      switchMap(([_, filters]) => locationService.searchLocationsByFilter({ ...filters })),
      map(({ docs: listItems }) => SearchActions.listItemsAppended({ listItems })),
    );
  },
  { functional: true },
);
export const listItemsSetStart = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    locationService = inject(LocationService),
  ) => {
    return actions$.pipe(
      ofType(SearchFiltersActions.changed, SearchActions.listItemsPageCreated),
      concatLatestFrom(() => store.select(searchFiltersFeature.selectFilterForListItems)),
      switchMap(([_, filters]) => locationService.searchLocationsByFilter({ ...filters })),
      map(({ docs: listItems }) => SearchActions.listItemsSet({ listItems })),
    );
  },
  { functional: true },
);

export const availableRegionsSetStart = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    countryStateService = inject(CountryStateService),
  ) => {
    return action$.pipe(
      ofType(SearchActions.listItemsPageCreated, SearchFiltersActions.tourTypeUpdated),
      concatLatestFrom(() => store.select(searchFiltersFeature.selectFilters)),
      switchMap(([_, { tourType }]) => countryStateService.getAvailableRegionsList({ tourType })),
      map(regions => SearchActions.regionsSet({ regions })),
    );
  },
  {
    functional: true,
  },
);
