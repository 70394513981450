import { IsNotEmpty, IsNumberString, IsString, Length } from 'class-validator';

export class PaymentMethod {
  @IsNumberString()
  @IsNotEmpty()
  @Length(9)
  bic: string;

  @IsNumberString()
  @IsNotEmpty()
  @Length(9)
  kpp: string;

  @IsNumberString()
  @IsNotEmpty()
  @Length(10)
  inn: string;

  @IsString()
  @IsNotEmpty()
  bankName: string;

  @IsNumberString()
  @IsNotEmpty()
  @Length(20)
  korrAccount: string;

  @IsString()
  @IsNotEmpty()
  recipient: string;

  @IsNumberString()
  @IsNotEmpty()
  @Length(20)
  account: string;

  constructor(paymentMethod: Partial<PaymentMethod>) {
    Object.assign(this, paymentMethod);
  }
}
