import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as effects from './active-tour.effects';
import { activeTourFeature } from './active-tour.feature';

@NgModule({
  imports: [StoreModule.forFeature(activeTourFeature), EffectsModule.forFeature(effects)],
})
export class ActiveTourFeatureModule {}
