import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';
import { ParameterId } from './parameter-id.type';

@Exclude()
export class ParameterDto {
  @ApiProperty({ type: () => String })
  @Expose()
  id: ParameterId;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  value: string;

  constructor(partial?: Partial<ParameterDto>) {
    Object.assign(this, partial);
  }
}
