import { createFeature, on } from '@ngrx/store';
import { createRehydrateReducer } from '@turgenev/angular-tools';
import { BookingPersonInfoPartner } from '@turgenev/dto';

import { PartnerActions } from './partner.actions';

const initialState = {
  partner: null as BookingPersonInfoPartner,
};

type State = typeof initialState;

export const partnerFeature = createFeature({
  name: 'partner',
  reducer: createRehydrateReducer(
    'partner-feature-state',
    initialState,
    on(
      PartnerActions.detected,
      (state, { partner }): State => ({
        ...state,
        partner,
      }),
    ),
  ),
});
