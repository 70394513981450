import { Exclude, Expose, Type } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class FeedbackDto {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty({ description: 'Общее впечатление' })
  @Expose()
  generalRating: number;

  @ApiProperty({ description: 'Оценка местоположения' })
  @Expose()
  locationRating: number;

  @ApiProperty({ description: 'Оценка питания' })
  @Expose()
  foodRating: number;

  @ApiProperty({ description: 'Оценка организации' })
  @Expose()
  organizationRating: number;

  @ApiProperty({ description: 'Оценка проживания' })
  @Expose()
  accommodationRating?: number;

  @ApiProperty({ description: 'Средняя оценка' })
  @Expose()
  totalRating?: number;

  @ApiProperty()
  @Expose()
  message?: string;

  @ApiProperty()
  @Expose()
  @Type(() => Date)
  createdAt?: Date;

  constructor(partial?: Partial<FeedbackDto>) {
    Object.assign(this, partial);
  }
}
