import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  BookingDto,
  BookingFacilityDto,
  BookingFacilityItem,
  LocationDto,
  LocationFacilityItem,
} from '@turgenev/dto';

import { AdditionalServicesPageMetadata } from './additional-services-page.type';

export const TourPageActions = createActionGroup({
  source: 'Tour Page',
  events: {
    Opened: props<{ locationId: number }>(),
    'Location Load Start': props<{ locationId: number }>(),
    'Location Load Success': props<{ location: LocationDto }>(),
    'Location Load Error': emptyProps(),
    'Location Reset': emptyProps(),
    'Booking Update Success': props<{ booking: BookingDto }>(),
    'Booking Price Update Success': props<{ booking: BookingDto }>(),
    'Booking Facility Preselected': props<{
      facility: LocationFacilityItem;
    }>(),
  },
});

export const TourBookingPageActions = createActionGroup({
  source: 'Tour Booking Page',
  events: {
    'Booking Dates Updated': props<{
      dates: {
        dateFrom: BookingDto['dateFrom'];
        dateTo: BookingDto['dateTo'];
      };
    }>(),
    'Booking People Count Updated': props<{
      peopleCount: number;
    }>(),
    'Booking Facilities Updated': props<{
      facilities: BookingDto['facilities'];
    }>(),
    'Booking Activities Updated': props<{
      activityTypes: BookingFacilityItem[];
      activityTargets: BookingFacilityItem[];
      selectedActivityType?: LocationFacilityItem;
      selectedActivityTarget?: LocationFacilityItem;
    }>(),
    'Additional Services Pages Updated': props<{
      pages: AdditionalServicesPageMetadata[];
      index: number;
    }>(),
    'Additional Services Page Index Updated': props<{
      index: number;
    }>(),
    'Additional Services Facility Quantity Changed': props<{
      facility: BookingFacilityDto;
    }>(),
  },
});
