/* eslint-disable guard-for-in */
import * as _ from 'lodash';

import { ArrayItem } from '../utils/types';

export const SUPPORTED_LANGS_ARRAY = ['ru'] as const;

export type SupportedLangs = ArrayItem<typeof SUPPORTED_LANGS_ARRAY>;

export const DEFAULT_LANG = SUPPORTED_LANGS_ARRAY[0];

export type MultiLangString = Partial<Record<SupportedLangs, string>>;

export type MultiLangInput = MultiLangString | string;

/**
 * Alias for MultiLangInput
 */
type MI = MultiLangInput;

/* eslint-disable prettier/prettier */
export function multiLangCombine(s1: MI, projector: (s1: string, lang: SupportedLangs) => string): MultiLangString;
export function multiLangCombine(s1: MI, s2: MI, projector: (s1: string, s2: string, lang: SupportedLangs) => string): MultiLangString;
export function multiLangCombine(s1: MI, s2: MI, s3: MI, projector: (s1: string, s2: string, s3: string, lang: SupportedLangs) => string): MultiLangString;
export function multiLangCombine(s1: MI, s2: MI, s3: MI, s4: MI, projector: (s1: string, s2: string, s3: string, s4: string, lang: SupportedLangs) => string): MultiLangString;
/* eslint-enable prettier/prettier */
export function multiLangCombine(...args: unknown[]) {
  const [projector] = args.splice(args.length - 1, 1) as [(...args: string[]) => string];
  const inputs = args as MultiLangInput[];

  return SUPPORTED_LANGS_ARRAY.reduce((r, k) => {
    const inputValues = inputs.map(i => {
      if (i === null || i === undefined) {
        return i as string;
      }

      return typeof i === 'string' ? i : i[k];
    });

    const value = projector(...inputValues, k);

    if (value === undefined) {
      return r;
    }

    return {
      ...r,
      [k]: value,
    };
  }, {} as MultiLangString);
}

export function multiLangMergeWith(
  merger: (value: string, srcValue: string, key: SupportedLangs) => string,
  ...args: MultiLangInput[]
): MultiLangString {
  return args
    .map(i => {
      if (typeof i === 'string') {
        const val = i;
        return SUPPORTED_LANGS_ARRAY.reduce((r, k) => {
          return {
            ...r,
            [k]: val,
          };
        }, {} as MultiLangString);
      }

      return i;
    })
    .reduce((res, cur) => _.mergeWith({ ...res }, cur, merger));
}

export function multiLangConcat(...args: MultiLangInput[]): MultiLangString {
  return multiLangMergeWith((value, srcValue) => `${value ?? ''}${srcValue ?? ''}`, ...args);
}

export function multiLangCoalesce(...args: MultiLangInput[]): MultiLangString {
  return multiLangMergeWith((value, srcValue) => value || srcValue, ...args);
}

export function multiLangApply(
  input: MultiLangInput,
  stringFunc: (s: string) => string,
): MultiLangString {
  return multiLangMergeWith(value => stringFunc(value), input, input);
}

export function multiLangCapitalize(text: MultiLangString): MultiLangString {
  return multiLangApply(text, s => {
    const firstChar = s?.charAt(0);
    return firstChar ? `${firstChar.toUpperCase()}${s.slice(1)}` : s;
  });
}

export function multiLangEquals(a: MultiLangString, b: MultiLangString): boolean {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return !SUPPORTED_LANGS_ARRAY.map(lang => a[lang] !== b[lang]).find(Boolean);
}
