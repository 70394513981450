import { FACILITY_RAW_DATA } from '../../static-data';

type RawFacility = (typeof FACILITY_RAW_DATA)[number];

export type FacilityId = RawFacility['id'];

export type FacilityType = RawFacility['type'];

export type FacilityTypeById<T extends FacilityId> = Extract<RawFacility, { id: T }>['type'];
export type FacilityIdByType<T extends FacilityType> = Extract<RawFacility, { type: T }>['id'];

const facilityIdSet = new Set<string>(FACILITY_RAW_DATA.map(i => i.id));
const facilityTypeSet = new Set<string>(FACILITY_RAW_DATA.map(i => i.type));

export function isFacilityId(input: unknown): input is FacilityId {
  if (typeof input !== 'string') {
    return false;
  }

  return facilityIdSet.has(input);
}

export function isFacilityType(input: unknown): input is FacilityType {
  if (typeof input !== 'string') {
    return false;
  }

  return facilityTypeSet.has(input);
}
