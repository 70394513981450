import { LocationFacilityDto } from '../dto/location-facility';

export const getWordFormByQuantity = (
  quantity: number,
  firstForm: string,
  secondForm: string,
  thirdForm: string,
) => {
  if (quantity > 10 && quantity < 20) {
    return thirdForm;
  }

  const q = quantity % 10;

  if (q > 1 && q < 5) {
    return secondForm;
  }

  if (q === 1) {
    return firstForm;
  }

  return thirdForm;
};

export const getDaysFormByQuantity = (quantity: number) =>
  getWordFormByQuantity(quantity, 'день', 'дня', 'дней');

export const getDaysGenitiveFormByQuantity = (quantity: number) =>
  getWordFormByQuantity(quantity, 'дня', 'дней', 'дней');

export const getPeopleFormByQuantity = (quantity: number) =>
  getWordFormByQuantity(quantity, 'человек', 'человека', 'человек');

export const getDaysCountLabel = (
  activity: LocationFacilityDto<'hunt-type' | 'tourism-type' | 'fishing-type'>,
) => {
  if (!activity) {
    return null;
  }

  const minDaysOptions = activity.options.map(option => option.daysCount).filter(Boolean);

  if (!minDaysOptions.length) {
    if (activity.options.every(option => option.accommodationRequired)) {
      return 'от 2 дней';
    }

    return null;
  }

  if (minDaysOptions.length === 1) {
    const quantity = minDaysOptions[0];

    return `${quantity} ${getDaysFormByQuantity(quantity)}`;
  }

  const quantity = Math.min(...minDaysOptions);

  return `от ${quantity} ${getDaysGenitiveFormByQuantity(quantity)}`;
};
