import { rawElementByIdFunc } from '../raw-element-by-id';

export const WEAPON_RAW_DATA = [
  {
    id: 'airgun',
    nameMl: {
      ru: 'пневматика',
    },
  },
  {
    id: 'bow',
    nameMl: {
      ru: 'лук',
    },
  },
  {
    id: 'crossbow',
    nameMl: {
      ru: 'арбалет',
    },
  },
  {
    id: 'rifle',
    nameMl: {
      ru: 'нарезное',
    },
  },
  {
    id: 'smoothbore',
    nameMl: {
      ru: 'гладкоствольное',
    },
  },
  {
    id: 'trap',
    nameMl: {
      ru: 'капкан',
    },
  },
] as const;

export const weaponById = rawElementByIdFunc(WEAPON_RAW_DATA);
