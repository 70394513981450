import { FacilityType } from '../../facility/facilities';
import { LocationFacilityItem } from '../location-facility-item';
import { ILocationFacilityItemAdapter } from './location-facility-item-adapter.interface';

export abstract class BasePriceFacilityItemAdapter<T extends FacilityType>
  implements ILocationFacilityItemAdapter<T>
{
  valuesAreEqual(left: LocationFacilityItem<T>, right: LocationFacilityItem<T>) {
    if (!left || !right) {
      return false;
    }
    return left.id === right.id;
  }

  getValue(_item: LocationFacilityItem<T>): unknown {
    return null;
  }
}
