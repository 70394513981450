import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';

@Exclude()
export class CountryDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: string;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  constructor(partial?: Partial<CountryDto>) {
    Object.assign(this, partial);
  }
}
