import { rawElementByIdFunc } from '../raw-element-by-id';

export const PRICE_TYPE_RAW_DATA = [
  {
    id: 'EXTRA_PERSON',
    nameMl: {
      ru: 'Цена за доп. человека',
    },
  },
  {
    id: 'EXTRA_PERSON_DAY',
    nameMl: {
      ru: 'Цена за доп. человека за день',
    },
  },
  {
    id: 'EXTRA_PERSON_NIGHT',
    nameMl: {
      ru: 'Цена за доп. человека за сутки',
    },
  },
  {
    id: 'FIRST_PERSON',
    nameMl: {
      ru: 'Цена за первого человека',
    },
  },
  {
    id: 'FIRST_PERSON_DAY',
    nameMl: {
      ru: 'Цена за первого человека за день',
    },
  },
  {
    id: 'FIRST_PERSON_NIGHT',
    nameMl: {
      ru: 'Цена за первого человека за сутки',
    },
  },
  {
    id: 'ONCE',
    nameMl: {
      ru: 'Разовая/общая цена',
    },
  },
  {
    id: 'PER_DAY',
    nameMl: {
      ru: 'Цена за день',
    },
  },
  {
    id: 'PER_NIGHT',
    nameMl: {
      ru: 'Цена за сутки',
    },
  },
  {
    id: 'PER_PERSON',
    nameMl: {
      ru: 'Цена за каждого человека',
    },
  },
  {
    id: 'PER_PERSON_DAY',
    nameMl: {
      ru: 'Цена за день на человека',
    },
  },
  {
    id: 'PER_PERSON_NIGHT',
    nameMl: {
      ru: 'Цена за сутки на человека',
    },
  },
] as const;

export const priceTypeById = rawElementByIdFunc(PRICE_TYPE_RAW_DATA);
