/* eslint-disable @typescript-eslint/ban-types */
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { TransformationType } from 'class-transformer/TransformOperationExecutor';
import * as _ from 'lodash';

import { ApiProperty } from '../../utils';
import { UniversalDate } from '../common';
import { FacilityType } from '../facility/facilities';
import { FacilityDto } from '../facility/facility.dto';
import { Availability, BaseLocationFacilityItem } from './items';
import { LocationFacilityItem } from './location-facility-item';

// build object with keys to protect us if fields set changes
const commonFieldsObj: Record<keyof BaseLocationFacilityItem, unknown> = {
  id: true,
  type: true,
  nameMl: true,
  descriptionMl: true,
  isFree: true,
  prices: true,
  action: true,
  images: true,
};

const COMMON_FIELDS = Object.keys(commonFieldsObj) as readonly (keyof BaseLocationFacilityItem)[];

@Exclude()
export class LocationFacilityDto<T extends FacilityType = FacilityType> {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty({ description: 'Вид услуги' })
  @Expose()
  @Type(() => FacilityDto)
  facility: FacilityDto<T>;

  @ApiProperty({ description: 'Параметры услуги' })
  @Expose()
  @Transform((value, _obj, transformType) => {
    if (!value) {
      return value;
    }

    const availability = value.map(item => {
      if (!('availability' in item) || transformType !== TransformationType.PLAIN_TO_CLASS) {
        return item;
      }

      const availability = item.availability as Availability;

      if (availability.mode === 'any') {
        return item;
      }

      const convertDate = (date: UniversalDate) => {
        return typeof date === 'string' ? date : UniversalDate.clone(date).toJSON();
      };

      const periods = (
        availability.mode === 'periods' ? availability.periods : availability.seasons
      ).map(({ start, end }) => {
        return {
          start: convertDate(start),
          end: convertDate(end),
        };
      });

      return {
        ...item,
        availability:
          availability.mode === 'periods'
            ? { mode: availability.mode, periods }
            : { mode: availability.mode, seasons: periods },
      };
    });

    const customFields = availability.map(item => {
      if (
        transformType === TransformationType.CLASS_TO_PLAIN ||
        (transformType === TransformationType.PLAIN_TO_CLASS && item.customFields)
      ) {
        const { customFields, ...commonFields } = item;

        return {
          ...customFields,
          ...commonFields,
        };
      }

      if (transformType === TransformationType.PLAIN_TO_CLASS) {
        const commonFields = _.pick(item, COMMON_FIELDS);
        const customFields = _.omit(item, COMMON_FIELDS);

        return {
          customFields,
          ...commonFields,
        };
      }
    });

    return customFields;
  })
  options: LocationFacilityItem<T>[];

  constructor(partial?: Partial<LocationFacilityDto<T>>) {
    Object.assign(this, partial);
  }
}
