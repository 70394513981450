import { isArray } from 'lodash';

import { FileDto } from '../dto/file';
import { ArrayOrValue, WithIdAs } from './types';

function isAbsoluteUrl(url: string) {
  return /^https?:\/\//i.test(url);
}

function joinUrl(...urls: string[]) {
  return urls
    .filter(i => !!i)
    .reduce((acc, cur) => {
      if (isAbsoluteUrl(cur)) {
        return cur;
      }

      // trim slash at the end
      acc = acc.replace(/[\/]+$/, '');
      // trim slash at the beginning
      cur = cur.replace(/^[\/]+/, '');

      return `${acc}/${cur}`;
    });
}

export function parseUrl(...urls: string[]) {
  const dummyOrigin = 'http://dummy.dum';

  const urlStr = joinUrl(...urls);
  let urlObj = new URL(urlStr, dummyOrigin);

  const origin = urlObj.origin.startsWith(dummyOrigin) ? null : urlObj.origin;
  const absolute = !!origin;
  const path = urlObj.pathname;

  return {
    origin,
    absolute,
    path,
  };
}

function toAbsoluteUrl(url: string, baseUrl: string) {
  const { origin, path } = parseUrl(baseUrl, url);
  return `${origin || ''}${path}`;
}

function fileRelativeUrl(id: number) {
  return `files/downloadFile/${id}`;
}

export type FileUrlInput = ArrayOrValue<WithIdAs<FileDto> | string | number>;

export function getFileUrl(file: FileUrlInput, baseUrl?: string): string {
  if (!file) {
    return null;
  }

  if (typeof file === 'string') {
    return toAbsoluteUrl(file, baseUrl);
  }

  if (typeof file === 'number') {
    return toAbsoluteUrl(fileRelativeUrl(file), baseUrl);
  }

  if (isArray(file)) {
    return getFileUrl(file[0], baseUrl);
  }

  return toAbsoluteUrl(fileRelativeUrl(file.id), baseUrl);
}
