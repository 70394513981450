import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty, Max, Min } from 'class-validator';

import { ApiProperty } from '../../utils';
import { DiscountTypeDto } from '../discount-type';

@Exclude()
export class DiscountDto {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  locationId?: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  startDate: Date;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  endDate: Date;

  @ApiProperty()
  @IsNotEmpty()
  @Max(100)
  @Min(1)
  @Expose()
  rate: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  discountType: DiscountTypeDto;

  constructor(partial?: Partial<DiscountDto>) {
    Object.assign(this, partial);
  }
}
