import { Injectable } from '@angular/core';
import { toRecord } from '@turgenev/angular-tools';
import { CountryStateDto, LocationFilterDto } from '@turgenev/dto';
import { firstValueFrom, map, Observable } from 'rxjs';

import { Api } from '../api';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class CountryStateService {
  constructor(private api: Api, private locationService: LocationService) {}

  getAll() {
    return this.api.get<CountryStateDto[]>('country-state');
  }

  private aggregateAvailableTargets(locationFilter: LocationFilterDto) {
    return this.locationService
      .aggregateLocations({
        ...locationFilter,
        aggregations: ['countryState'],
      })
      .pipe(map(res => res['countryState']));
  }

  async getAvailableRegionsList(filter: LocationFilterDto) {
    const [all, availableRegions] = await Promise.all([
      firstValueFrom(this.getAll()),
      firstValueFrom(this.aggregateAvailableTargets(filter)),
    ]);

    const availableRegionsMap = toRecord(
      availableRegions?.buckets,
      i => Number(i.key),
      i => i.count,
    );

    return all.filter(item => !!availableRegionsMap[item.id]);
  }

  getRegionsByIds(ids: number[]): Observable<CountryStateDto[]> {
    return this.api.post<CountryStateDto[]>('country-state', { ids });
  }
}
