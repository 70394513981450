import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';

@Exclude()
export class PromocodeDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  code: string;

  @ApiProperty()
  @Expose()
  amount: number;

  @ApiProperty()
  @Expose()
  usageLimit: number;

  @ApiProperty()
  @Expose()
  startDate: Date;

  @ApiProperty()
  @Expose()
  expirationDate: Date;

  @ApiProperty()
  @Expose()
  activeDate: Date;

  constructor(partial?: Partial<PromocodeDto>) {
    Object.assign(this, partial);
  }
}
