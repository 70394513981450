import { ConditionalExcept, ConditionalKeys } from 'type-fest';

const LOCATION_AGGREGATIONS_CONFIG = {
  price: {
    key: 'price',
    type: 'stats',
    responseType: 'stats',
    path: 'price',
  },
  rating: {
    key: 'rating',
    type: 'range',
    responseType: 'range',
    path: 'rating',
  },
  beast: {
    key: 'beast',
    type: 'terms',
    responseType: 'lterms',
    field: 'beastId',
    path: 'facilities.beast',
  },
  huntType: {
    key: 'huntType',
    type: 'terms',
    responseType: 'lterms',
    field: 'huntTypeId',
    path: 'facilities.huntType',
  },
  fish: {
    key: 'fish',
    type: 'terms',
    responseType: 'lterms',
    field: 'fishId',
    path: 'facilities.fish',
  },
  fishingType: {
    key: 'fishingType',
    type: 'terms',
    responseType: 'lterms',
    field: 'fishingTypeId',
    path: 'facilities.fishingType',
  },
  tourismType: {
    key: 'tourismType',
    type: 'terms',
    responseType: 'lterms',
    field: 'tourismTypeId',
    path: 'facilities.tourismType',
  },
  countryState: {
    key: 'countryState',
    type: 'terms',
    responseType: 'lterms',
    field: 'countryStateId',
    path: 'countryState',
  },
  geoClustering: {
    key: 'geoClustering',
    type: 'geohash_grid',
    responseType: 'geohash_grid',
    field: 'geoPoint',
    path: 'geoClustering',
  },
} as const;

type AggregationsMap = typeof LOCATION_AGGREGATIONS_CONFIG;

export type LocationRootAggregationKey = keyof ConditionalExcept<
  AggregationsMap,
  { path: `${string}${'.'}${string}` }
>;

export type LocationFacilityAggregationKey = ConditionalKeys<
  AggregationsMap,
  { path: `${'facilities.'}${string}` }
>;

export type LocationAggregationKey = LocationRootAggregationKey | LocationFacilityAggregationKey;

export const LOCATION_AGGREGATION_ROOT_KEYS = Object.entries(LOCATION_AGGREGATIONS_CONFIG)
  .filter(([_, value]) => !(value.path.indexOf('.') > 0))
  .map(([key, _]) => key as LocationRootAggregationKey);

export const LOCATION_AGGREGATION_FACILITY_KEYS = Object.entries(LOCATION_AGGREGATIONS_CONFIG)
  .filter(([_, value]) => value.path.startsWith('facilities.'))
  .map(([key, _]) => key as LocationFacilityAggregationKey);

export type LocationAggregationTypeByKey<
  N extends LocationAggregationKey = LocationAggregationKey,
> = AggregationsMap[N]['type'];

export function getAggregationConfig<T extends LocationAggregationKey>(key: T) {
  return {
    ...LOCATION_AGGREGATIONS_CONFIG[key],
    key: key,
  };
}
