import { rawElementByIdFunc } from '../raw-element-by-id';

export const TACKLE_RAW_DATA = [
  {
    id: 'spinning',
    nameMl: {
      ru: 'спиннинг',
      en: 'spinning',
    },
  },
  {
    id: 'flywheel',
    nameMl: {
      ru: 'маховая',
      en: 'flywheel',
    },
  },
  {
    id: 'bolognese-tackle',
    nameMl: {
      ru: 'болонка',
      en: 'bolognese-tackle',
    },
  },
  {
    id: 'harpoon',
    nameMl: {
      ru: 'гарпун',
      en: 'harpoon',
    },
  },
  {
    id: 'jig',
    nameMl: {
      ru: 'джиг',
      en: 'jig',
    },
  },
  {
    id: 'leger-rig',
    nameMl: {
      ru: 'донка',
      en: 'leger-rig',
    },
  },
  {
    id: 'zherlitsa',
    nameMl: {
      ru: 'жерлица',
      en: 'zherlitsa',
    },
  },
  {
    id: 'winter-rod',
    nameMl: {
      ru: 'зимняя удочка',
      en: 'winter-rod',
    },
  },
  {
    id: 'micro-jig',
    nameMl: {
      ru: 'микроджиг',
      en: 'micro-jig',
    },
  },
  {
    id: 'fly-fishing',
    nameMl: {
      ru: 'нахлыст',
      en: 'fly-fishing',
    },
  },
  {
    id: 'noodling',
    nameMl: {
      ru: 'нудлинг',
      en: 'noodling',
    },
  },
  {
    id: 'speargun',
    nameMl: {
      ru: 'подводное ружье',
      en: 'speargun',
    },
  },
  {
    id: 'bobber',
    nameMl: {
      ru: 'поплавок',
      en: 'bobber',
    },
  },
  {
    id: 'feeder',
    nameMl: {
      ru: 'фидер',
      en: 'feeder',
    },
  },
  {
    id: 'trolling',
    nameMl: {
      ru: 'троллинг',
      en: 'trolling',
    },
  },
  {
    id: 'rod',
    nameMl: {
      ru: 'удочка',
      en: 'rod',
    },
  },
] as const;

export const tackleById = rawElementByIdFunc(TACKLE_RAW_DATA);
