import { IsEmail, IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';

export class ResetPasswordFormDto {
  @ApiProperty()
  @IsEmail()
  @IsNotEmpty()
  email: string;

  @ApiProperty()
  lang: string;
}
