import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty, IsPositive } from 'class-validator';

import { ApiProperty, subtractPercentAndRound } from '../../utils';
import { BookingFacilityDto } from '../booking-facility/booking-facility.dto';
import { UniversalDateType } from '../common/decorators/universal-date-type.decorator';
import { UniversalDate } from '../common/universal-date.type';
import { BookingStatus } from './booking-status.type';

@Exclude()
export class BookingDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty({ description: 'Comment to booking' })
  @Expose()
  comment: string;

  @ApiProperty({ description: 'Код бронирования' })
  @Expose()
  code: string;

  @ApiProperty()
  @Expose()
  participantId: number;

  @ApiProperty()
  @Expose()
  @UniversalDateType()
  dateFrom: UniversalDate;

  @ApiProperty()
  @Expose()
  @UniversalDateType()
  dateTo: UniversalDate;

  @ApiProperty()
  @IsPositive()
  @Expose()
  peopleCount: number;

  @ApiProperty({ description: 'Price after discount' })
  @Expose()
  price: number;

  @ApiProperty({ description: 'Price before discount' })
  @Expose()
  originalPrice: number;

  @ApiProperty()
  @Expose()
  get discountPrice() {
    return subtractPercentAndRound(this.originalPrice, this.discount);
  }

  @ApiProperty({ description: 'Min price calculated for the cheapest combination of facilities' })
  @Expose()
  minPrice: number;

  @ApiProperty()
  @Expose()
  paid: number;

  @ApiProperty()
  @Expose()
  status: BookingStatus;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => BookingFacilityDto)
  facilities: BookingFacilityDto[];

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  locationId: number;

  @ApiProperty()
  @Expose()
  statusReason: string;

  @ApiProperty()
  @Expose()
  statusComment: string;

  @ApiProperty({ description: 'Sum of all discounts available at booking in % e.g. 12%' })
  @Expose()
  discount: number;

  @ApiProperty()
  @Expose()
  contactFirstName: string;

  @ApiProperty()
  @Expose()
  contactLastName: string;

  @ApiProperty()
  @Expose()
  contactEmail: string;

  @ApiProperty()
  @Expose()
  contactPhone: string;

  @ApiProperty()
  @Expose()
  s7PriorityNumber?: string;

  @ApiProperty()
  @Expose()
  partnerKey?: string;

  @ApiProperty()
  @Expose()
  @Type(() => Date)
  createdAt: Date;

  constructor(partial?: Partial<BookingDto>) {
    Object.assign(this, partial);
  }
}
