import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-nest.decorator';
import { StoryButtonAction } from './story-button-action.type';

export class StoryPageDto {
  @ApiProperty()
  id: number;

  @ApiProperty()
  storyId: number;

  @ApiProperty()
  duration: number;

  @ApiProperty()
  fillerColor?: string;

  @ApiProperty()
  content?: string;

  @ApiProperty()
  buttonTextMl?: MultiLangString;

  @ApiProperty()
  buttonTextColor?: string;

  @ApiProperty()
  buttonBackground?: string;

  @ApiProperty()
  buttonAction?: StoryButtonAction;
}
