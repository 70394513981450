import { Exclude, Expose, Type } from 'class-transformer';
import { IsNumberString } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { CountryDto } from '../country/country.dto';

@Exclude()
export class IndividualEntrepreneurDto {
  @ApiProperty()
  @Expose()
  id?: number;

  @ApiProperty()
  @Expose()
  legalNameMl: MultiLangString;

  @ApiProperty()
  @Expose()
  @Type(() => CountryDto)
  country: CountryDto;

  @ApiProperty()
  @IsNumberString()
  @Expose()
  inn: string;

  @ApiProperty()
  @IsNumberString()
  @Expose()
  egrip: string;

  constructor(partial?: Partial<IndividualEntrepreneurDto>) {
    Object.assign(this, partial);
  }
}
