import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty, IsNumber } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';

@Exclude()
export class LoyaltyStatusDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @IsNumber()
  cashbackPercent: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  descriptionMl: MultiLangString;

  constructor(partial?: Partial<LoyaltyStatusDto>) {
    Object.assign(this, partial);
  }
}
