import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { RegionDto } from '../region/region.dto';

@Exclude()
export class CountryStateDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty({ type: () => RegionDto })
  @IsNotEmpty()
  @Expose()
  @Type(() => RegionDto)
  region: RegionDto;
}
