import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/search-results/search-results.module').then(m => m.SearchResultsPageModule),
  },
  {
    path: 'experience',
    loadChildren: () =>
      import('./pages/search-results/search-results.module').then(m => m.SearchResultsPageModule),
  },
  {
    path: 'alfabank',
    loadChildren: () =>
      import('./pages/search-results/search-results.module').then(m => m.SearchResultsPageModule),
  },
  {
    path: 'tour/:locationId',
    loadChildren: () => import('./pages/tour/tour.module').then(m => m.TourModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'user-agreement',
    loadChildren: () =>
      import('./pages/user-agreement/user-agreement.module').then(m => m.UserAgreementModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
