import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class RangeAggregationDto {
  @Expose()
  @Type(() => RangeAggregationBucketDto)
  buckets: RangeAggregationBucketDto[];

  constructor(partial: Partial<RangeAggregationDto>) {
    return Object.assign(this, partial);
  }
}

@Exclude()
export class RangeAggregationBucketDto {
  @Expose()
  key: string;

  @Expose()
  to?: number;

  @Expose()
  from?: number;

  @Expose()
  doc_count: number;

  constructor(partial: Partial<RangeAggregationBucketDto>) {
    return Object.assign(this, partial);
  }
}
