import { FacilityId } from '@turgenev/dto';

export type AdditionalServicesPageMetadata = {
  id: number;
  title: string;
  facilityTypes: FacilityId[];
};

export const ADDITIONAL_SERVICES_PAGES: AdditionalServicesPageMetadata[] = [
  {
    id: 1,
    title: 'Проживание',
    facilityTypes: ['house-rent'],
  },
  {
    id: 2,
    title: 'Питание',
    facilityTypes: ['lunch', 'dinner', 'breakfast', 'full-board'],
  },
  {
    id: 3,
    title: 'Инвентарь',
    facilityTypes: [
      'tent-rent',
      'fishing-rent',
      'tourism-rent',
      'hideout',
      'horses',
      'dogs',
      'beaters',
    ],
  },
  {
    id: 4,
    title: 'Трансфер',
    facilityTypes: ['transfer'],
  },
  {
    id: 5,
    title: 'Прочие услуги',
    facilityTypes: ['other'],
  },
];
