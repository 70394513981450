import { ApiProperty } from '../../utils';

export class FileDto {
  @ApiProperty()
  id: number;

  @ApiProperty()
  fileName: string;

  @ApiProperty()
  mimeType: string;

  @ApiProperty()
  fileSize: number;

  /** @deprecated */
  @ApiProperty()
  url: string;

  @ApiProperty()
  alt: string;

  constructor(partial?: Partial<FileDto>) {
    Object.assign(this, partial);
  }
}
