export const RATING_AGGREGATION_CONFIG_DATA = [
  {
    title: {
      ru: 'Хорошо',
    },
    key: 'good',
    doc_count: 0,
    from: 4,
  },
  {
    title: {
      ru: 'Очень хорошо',
    },
    key: 'excellent',
    doc_count: 0,
    from: 4.5,
  },
  {
    title: {
      ru: 'Превосходно',
    },
    key: 'outstanding',
    doc_count: 0,
    from: 4.8,
  },
] as const;
