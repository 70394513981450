import { Exclude, Expose } from 'class-transformer';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils/swagger-decorators/swagger-decorators-angular.decorator';

@Exclude()
export class PartnerPublicDto {
  @ApiProperty()
  @Expose()
  brandNameMl?: MultiLangString;
}
