import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class TermsAggregationDto {
  @Expose()
  @Type(() => TermsAggregationBucketDto)
  buckets: TermsAggregationBucketDto[];

  constructor(partial: Partial<TermsAggregationDto>) {
    return Object.assign(this, partial);
  }
}

@Exclude()
export class TermsAggregationBucketDto {
  @Expose()
  key: string;

  @Expose()
  count: number;

  constructor(partial: Partial<TermsAggregationBucketDto>) {
    return Object.assign(this, partial);
  }
}
