import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ArrayMinSize, IsNotEmpty, IsNotEmptyObject } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty, convertArray } from '../../utils';
import { CountryStateDto } from '../country-state';
import { LocationFacilityDto } from '../location-facility';
import { LocationImageDto } from '../location-image/location-image.dto';
import { ParticipantDto } from '../participant';
import { TourTypeDto } from '../tour-type/tour-type.dto';
import { LocationStatusDto } from './location-status.dto';

@Exclude()
export class LocationManageDto {
  @ApiProperty({ description: 'id' })
  @Expose()
  id: number;

  @ApiProperty({ description: 'Название' })
  @IsNotEmptyObject()
  @Expose()
  titleMl: MultiLangString;

  @ApiProperty({ description: 'Описание' })
  @IsNotEmptyObject()
  @Expose()
  descriptionMl: MultiLangString;

  @ApiProperty({ type: () => TourTypeDto, description: 'Тип тура' })
  @IsNotEmpty()
  @Expose()
  @Type(() => TourTypeDto)
  tourType: TourTypeDto;

  @ApiProperty()
  @ArrayMinSize(1)
  @Expose()
  @Transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any[]) => {
      const sorted = value?.filter(x => !!x).sort((x, y) => x.order - y.order);
      const dtos = convertArray(sorted, LocationImageDto);
      return dtos;
    },
    {
      toClassOnly: true,
    },
  )
  // @Type(() => LocationImageDto)
  images: LocationImageDto[];

  @ApiProperty({ description: 'Услуги' })
  @IsNotEmpty()
  @Expose()
  @Type(() => LocationFacilityDto)
  facilities: LocationFacilityDto[];

  @IsNotEmpty()
  @Expose()
  @Type(() => Date)
  createdAt: Date;

  @IsNotEmpty()
  @Expose()
  @Type(() => Date)
  updatedAt: Date;

  @IsNotEmpty()
  @Expose()
  @Type(() => ParticipantDto)
  ownerParticipant: ParticipantDto;

  @Expose()
  smartWidgetExternalRef?: string;

  @Expose()
  yandexMapExternalRef?: string;

  @Expose()
  yandexReviewCount?: number;

  @Expose()
  yandexRating?: number;

  @Expose()
  status: LocationStatusDto;

  @Expose()
  discount: number;

  @Expose()
  published: boolean;

  @ApiProperty({ description: 'Регион' })
  @Expose()
  @Type(() => CountryStateDto)
  countryState: CountryStateDto;

  constructor(partial?: Partial<LocationManageDto>) {
    Object.assign(this, partial);
  }
}
