import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class StatsAggregationDto {
  @Expose()
  min: number;

  @Expose()
  max: number;

  constructor(partial: Partial<StatsAggregationDto>) {
    return Object.assign(this, partial);
  }
}
