import { Transform, TransformationType } from 'class-transformer';

import { UniversalDate } from '../universal-date.type';

export const UniversalDateType = () =>
  Transform((value, _obj, transformType) => {
    if (!value) {
      return value;
    }

    if (transformType === TransformationType.CLASS_TO_PLAIN) {
      // Convert Universal date to json date. UniversalDate -> 2024-01-01
      if (value instanceof UniversalDate) {
        return value.toJSON();
      }

      if (typeof value === 'string') {
        // Convert ISO string to json date to. 2024-01-01T00:00:00.000Z -> 2024-01-01
        return UniversalDate.fromISOString(value).toJSON();
      }

      // Convert raw UniversalDate to json date. { year: 2024, month: 0, day: 1 } -> 2024-01-01
      if ('day' in value && 'month' in value && 'year' in value) {
        return UniversalDate.clone(value).toJSON();
      }

      return value;
    }

    if (transformType === TransformationType.PLAIN_TO_CLASS) {
      if (typeof value === 'string') {
        return UniversalDate.fromISOString(value);
      }

      if (value instanceof Date) {
        return UniversalDate.fromLocalNativeDate(value);
      }

      return value;
    }
  });
