/* eslint-disable @typescript-eslint/no-explicit-any */

import { TupleOrValue, TypeFromTuple } from '../../utils/types';
import { BookingFacilityDto } from '../booking-facility/booking-facility.dto';
import { BookingFacilityItem } from '../booking-facility/booking-facility-item';
import { LocationFacilityDto } from '../location-facility/location-facility.dto';
import { LocationFacilityItem } from '../location-facility/location-facility-item';
import { FacilityId, FacilityType } from './facilities';
import { FacilityDto } from './facility.dto';

export const FACILITY_TARGET_TYPES = ['beast', 'fish'] as const;
export const FACILITY_MULTIPLE_TARGET_TYPES = ['beast', 'fish', 'tourism-type'] as const;
export const FACILITY_ACTIVITY_TYPES = ['hunt-type', 'fishing-type', 'tourism-type'] as const;
export const FACILITY_EXTRA_TYPES = ['house-rent', 'default', 'sauna'] as const;
export const FACILITY_MEAL_TYPES: readonly FacilityId[] = [
  'lunch',
  'dinner',
  'breakfast',
  'full-board',
] as const;
export const FACILITY_INVENTORY_TYPES: readonly FacilityId[] = [
  'tent-rent',
  'fishing-rent',
  'tourism-rent',
  'hideout',
  'horses',
  'dogs',
  'beaters',
] as const;

export const FACILITY_TYPES = [
  ...FACILITY_TARGET_TYPES,
  ...FACILITY_ACTIVITY_TYPES,
  ...FACILITY_EXTRA_TYPES,
] as const;

type FacilityOrItsWrapper<F extends FacilityType = FacilityType> =
  | string
  | FacilityDto<F>
  | LocationFacilityDto<F>
  | LocationFacilityItem<F>
  | BookingFacilityDto<F>
  | BookingFacilityItem<F>;

/**
 * It is an internal utility function. If you want to achieve type narrowing use `isFacilityOfType`
 * @param f An object to test
 * @param typeOrTuple A facility type string, or tuple of them
 */
export function isFacilityInTypesUtil(
  f: FacilityOrItsWrapper,
  typeOrTuple: TupleOrValue<FacilityType>,
): boolean {
  if (!f) {
    return false;
  }

  if (typeof typeOrTuple === 'string') {
    const justType = typeOrTuple as FacilityType;
    return isFacilityInTypesUtil(f, [justType]);
  }

  const types = typeOrTuple as readonly FacilityType[];

  if (typeof f === 'string') {
    return types.some(i => f === i);
  }

  if ('locationFacility' in f) {
    return isFacilityInTypesUtil((f as BookingFacilityDto).locationFacility, types);
  }

  if ('locationItem' in f) {
    return isFacilityInTypesUtil((f as BookingFacilityItem).locationItem, types);
  }

  if ('facility' in f) {
    return isFacilityInTypesUtil((f as LocationFacilityDto).facility, types);
  }

  if ('type' in f) {
    return isFacilityInTypesUtil((f as FacilityDto | LocationFacilityItem).type, types);
  }

  return false;
}

export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: BookingFacilityDto,
  typeOrTuple: F,
): f is BookingFacilityDto<TypeFromTuple<F>>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: BookingFacilityItem,
  typeOrTuple: F,
): f is BookingFacilityItem<TypeFromTuple<F>>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: LocationFacilityDto,
  typeOrTuple: F,
): f is LocationFacilityDto<TypeFromTuple<F>>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: LocationFacilityItem,
  typeOrTuple: F,
): f is LocationFacilityItem<TypeFromTuple<F>>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: FacilityDto,
  typeOrTuple: F,
): f is FacilityDto<TypeFromTuple<F>>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(
  f: string,
  typeOrTuple: F,
): f is TypeFromTuple<F>;
export function isFacilityOfType<F extends TupleOrValue<FacilityType>>(f: any, typeOrTuple: F) {
  return isFacilityInTypesUtil(f, typeOrTuple);
}
