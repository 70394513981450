import { IsNotEmpty } from 'class-validator';

import { ApiProperty } from '../../utils';

export class UserPasswordDto {
  @ApiProperty()
  @IsNotEmpty()
  name: string;

  @ApiProperty()
  @IsNotEmpty()
  password: string;
}
