import { activityTargetTypeById } from '../activity-target-type';
import { weaponById } from '../weapon/weapon.data';

export const ACTIVITY_TARGET_RAW_DATA = [
  {
    id: 93,
    nameMl: {
      ru: 'белоглазка',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 94,
    nameMl: {
      ru: 'белорыбица',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 95,
    nameMl: {
      ru: 'белуга',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 96,
    nameMl: {
      ru: 'белый амур',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 97,
    nameMl: {
      ru: 'берш',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 98,
    nameMl: {
      ru: 'быстрянка',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 99,
    nameMl: {
      ru: 'бычок',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 100,
    nameMl: {
      ru: 'вобла',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 101,
    nameMl: {
      ru: 'вьюн',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 102,
    nameMl: {
      ru: 'голавль',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 103,
    nameMl: {
      ru: 'голец',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 104,
    nameMl: {
      ru: 'горбуша',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 105,
    nameMl: {
      ru: 'густера',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 106,
    nameMl: {
      ru: 'елец',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 107,
    nameMl: {
      ru: 'ерш обыкн.',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 108,
    nameMl: {
      ru: 'жерех обыкн.',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 109,
    nameMl: {
      ru: 'змееголов ',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 110,
    nameMl: {
      ru: 'зубатка',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 111,
    nameMl: {
      ru: 'камбала',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 112,
    nameMl: {
      ru: 'карась',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 113,
    nameMl: {
      ru: 'карп',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 114,
    nameMl: {
      ru: 'кета',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 115,
    nameMl: {
      ru: 'кижуч',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 116,
    nameMl: {
      ru: 'корюшка азиатская',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 117,
    nameMl: {
      ru: 'корюшка европейская (снеток)',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 118,
    nameMl: {
      ru: 'корюшка морская малоротая',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 119,
    nameMl: {
      ru: 'красноперка',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 120,
    nameMl: {
      ru: 'кумжа',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 121,
    nameMl: {
      ru: 'ленок',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 122,
    nameMl: {
      ru: 'лещ, подлещик',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 123,
    nameMl: {
      ru: 'линь',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 124,
    nameMl: {
      ru: 'минога',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 125,
    nameMl: {
      ru: 'муксун',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 126,
    nameMl: {
      ru: 'налим',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 127,
    nameMl: {
      ru: 'нерка',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 128,
    nameMl: {
      ru: 'окунь речной',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 129,
    nameMl: {
      ru: 'омуль',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 130,
    nameMl: {
      ru: 'осетр',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 131,
    nameMl: {
      ru: 'пелядь',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 132,
    nameMl: {
      ru: 'пескарь',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 133,
    nameMl: {
      ru: 'плотва',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 134,
    nameMl: {
      ru: 'подлещик',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 135,
    nameMl: {
      ru: 'подуст',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 136,
    nameMl: {
      ru: 'ротаны',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 137,
    nameMl: {
      ru: 'рыбец',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 138,
    nameMl: {
      ru: 'ряпушка',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 139,
    nameMl: {
      ru: 'сазан',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 140,
    nameMl: {
      ru: 'сарган',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 141,
    nameMl: {
      ru: 'севрюга',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 142,
    nameMl: {
      ru: 'сельдь',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 143,
    nameMl: {
      ru: 'семга',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 144,
    nameMl: {
      ru: 'сиг',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 145,
    nameMl: {
      ru: 'синец',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 146,
    nameMl: {
      ru: 'сом',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 147,
    nameMl: {
      ru: 'стерлядь',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 148,
    nameMl: {
      ru: 'судак',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 149,
    nameMl: {
      ru: 'таймень',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 150,
    nameMl: {
      ru: 'тарань',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 151,
    nameMl: {
      ru: 'толстолобики',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 152,
    nameMl: {
      ru: 'тюлька черноморско-каспийская',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 153,
    nameMl: {
      ru: 'угорь',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 154,
    nameMl: {
      ru: 'уклейка',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 155,
    nameMl: {
      ru: 'усач',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 156,
    nameMl: {
      ru: 'форель ручьевая',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 157,
    nameMl: {
      ru: 'хариус',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 158,
    nameMl: {
      ru: 'чавыча',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 159,
    nameMl: {
      ru: 'чехонь',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 160,
    nameMl: {
      ru: 'шип',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 161,
    nameMl: {
      ru: 'щука',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 162,
    nameMl: {
      ru: 'язь',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 235,
    nameMl: {
      ru: 'микижа',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 236,
    nameMl: {
      ru: 'сима',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 237,
    nameMl: {
      ru: 'кунджа',
    },
    types: [activityTargetTypeById(8)],
    weapons: [],
  },
  {
    id: 1,
    nameMl: {
      en: 'кабан',
      ru: 'кабан',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 2,
    nameMl: {
      en: 'кабарга',
      ru: 'кабарга',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 3,
    nameMl: {
      en: 'дикий северный олень',
      ru: 'дикий северный олень',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 4,
    nameMl: {
      en: 'косуля',
      ru: 'косуля',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 5,
    nameMl: {
      en: 'лось',
      ru: 'лось',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 6,
    nameMl: {
      en: 'благородный олень',
      ru: 'благородный олень',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 7,
    nameMl: {
      en: 'пятнистый олень',
      ru: 'пятнистый олень',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 8,
    nameMl: {
      en: 'лань',
      ru: 'лань',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 9,
    nameMl: {
      en: 'овцебык',
      ru: 'овцебык',
    },
    types: [activityTargetTypeById(1)],
    weapons: [weaponById('crossbow'), weaponById('rifle'), weaponById('smoothbore')],
  },
  {
    id: 10,
    nameMl: {
      en: 'муфлон',
      ru: 'муфлон',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 11,
    nameMl: {
      en: 'сайгак',
      ru: 'сайгак',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 12,
    nameMl: {
      en: 'серна',
      ru: 'серна',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 13,
    nameMl: {
      en: 'сибирский горный козел',
      ru: 'сибирский горный козел',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 14,
    nameMl: {
      en: 'тур',
      ru: 'тур',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 15,
    nameMl: {
      en: 'снежный баран',
      ru: 'снежный баран',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 16,
    nameMl: {
      en: 'изюбрь',
      ru: 'изюбрь',
    },
    types: [activityTargetTypeById(1)],
    weapons: [weaponById('crossbow'), weaponById('rifle'), weaponById('smoothbore')],
  },
  {
    id: 17,
    nameMl: {
      en: 'марал',
      ru: 'марал',
    },
    types: [activityTargetTypeById(1)],
    weapons: [
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 18,
    nameMl: {
      en: 'бизон',
      ru: 'бизон',
    },
    types: [activityTargetTypeById(1)],
    weapons: [weaponById('crossbow'), weaponById('rifle'), weaponById('smoothbore')],
  },
  {
    id: 19,
    nameMl: {
      en: 'зубр',
      ru: 'зубр',
    },
    types: [activityTargetTypeById(1)],
    weapons: [weaponById('crossbow'), weaponById('rifle'), weaponById('smoothbore')],
  },
  {
    id: 20,
    nameMl: {
      en: 'бурый медведь',
      ru: 'бурый медведь',
    },
    types: [activityTargetTypeById(2), activityTargetTypeById(7)],
    weapons: [
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('bow'),
    ],
  },
  {
    id: 21,
    nameMl: {
      en: 'волк',
      ru: 'волк',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 22,
    nameMl: {
      en: 'шакал',
      ru: 'шакал',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 23,
    nameMl: {
      en: 'лисица',
      ru: 'лисица',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 24,
    nameMl: {
      en: 'корсак',
      ru: 'корсак',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 25,
    nameMl: {
      en: 'песец',
      ru: 'песец',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 26,
    nameMl: {
      en: 'енотовидная собака',
      ru: 'енотовидная собака',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 27,
    nameMl: {
      en: 'енот-полоскун',
      ru: 'енот-полоскун',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 28,
    nameMl: {
      en: 'рысь',
      ru: 'рысь',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 29,
    nameMl: {
      en: 'росомаха',
      ru: 'росомаха',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 30,
    nameMl: {
      en: 'барсук',
      ru: 'барсук',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 31,
    nameMl: {
      en: 'куница',
      ru: 'куница',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 32,
    nameMl: {
      en: 'соболь',
      ru: 'соболь',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 33,
    nameMl: {
      en: 'харза',
      ru: 'харза',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 34,
    nameMl: {
      en: 'дикая кошка',
      ru: 'дикая кошка',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 35,
    nameMl: {
      en: 'ласка',
      ru: 'ласка',
    },
    types: [activityTargetTypeById(3), activityTargetTypeById(7)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 36,
    nameMl: {
      en: 'горностай',
      ru: 'горностай',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 37,
    nameMl: {
      en: 'солонгой',
      ru: 'солонгой',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 38,
    nameMl: {
      en: 'колонок',
      ru: 'колонок',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 39,
    nameMl: {
      en: 'хори',
      ru: 'хори',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 40,
    nameMl: {
      en: 'норка',
      ru: 'норка',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 41,
    nameMl: {
      en: 'выдра',
      ru: 'выдра',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 42,
    nameMl: {
      en: 'заяц',
      ru: 'заяц',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 43,
    nameMl: {
      en: 'дикий кролик',
      ru: 'дикий кролик',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 44,
    nameMl: {
      en: 'бобер',
      ru: 'бобер',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 45,
    nameMl: {
      en: 'сурок',
      ru: 'сурок',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 46,
    nameMl: {
      en: 'суслик',
      ru: 'суслик',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 47,
    nameMl: {
      en: 'крот',
      ru: 'крот',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 48,
    nameMl: {
      en: 'бурундук',
      ru: 'бурундук',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 49,
    nameMl: {
      en: 'летяга',
      ru: 'летяга',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 50,
    nameMl: {
      en: 'белки',
      ru: 'белки',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 51,
    nameMl: {
      en: 'хомяк',
      ru: 'хомяк',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 52,
    nameMl: {
      en: 'ондатра',
      ru: 'ондатра',
    },
    types: [activityTargetTypeById(3)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('rifle'),
      weaponById('smoothbore'),
      weaponById('trap'),
    ],
  },
  {
    id: 53,
    nameMl: {
      en: 'водяная полевка;',
      ru: 'водяная полевка;',
    },
    types: [activityTargetTypeById(3)],
    weapons: [],
  },
  {
    id: 54,
    nameMl: {
      en: 'гусь',
      ru: 'гусь',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 55,
    nameMl: {
      en: 'казарка',
      ru: 'казарка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 56,
    nameMl: {
      en: 'утка',
      ru: 'утка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 57,
    nameMl: {
      en: 'глухарь',
      ru: 'глухарь',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 58,
    nameMl: {
      en: 'тетерев',
      ru: 'тетерев',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 59,
    nameMl: {
      en: 'рябчик',
      ru: 'рябчик',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 60,
    nameMl: {
      en: 'куропатка',
      ru: 'куропатка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 61,
    nameMl: {
      en: 'перепел',
      ru: 'перепел',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 62,
    nameMl: {
      en: 'кеклик',
      ru: 'кеклик',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 63,
    nameMl: {
      en: 'фазан',
      ru: 'фазан',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 64,
    nameMl: {
      en: 'улар',
      ru: 'улар',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 65,
    nameMl: {
      en: 'пастушок',
      ru: 'пастушок',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 66,
    nameMl: {
      en: 'обыкновенный погоныш',
      ru: 'обыкновенный погоныш',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 67,
    nameMl: {
      en: 'коростель',
      ru: 'коростель',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 68,
    nameMl: {
      en: 'камышница',
      ru: 'камышница',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 69,
    nameMl: {
      en: 'лысуха',
      ru: 'лысуха',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 70,
    nameMl: {
      en: 'чибис',
      ru: 'чибис',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 71,
    nameMl: {
      en: 'тулес',
      ru: 'тулес',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 72,
    nameMl: {
      en: 'хрустан',
      ru: 'хрустан',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 73,
    nameMl: {
      en: 'камнешарка',
      ru: 'камнешарка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 74,
    nameMl: {
      en: 'турухтан',
      ru: 'турухтан',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 75,
    nameMl: {
      en: 'травник',
      ru: 'травник',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 76,
    nameMl: {
      en: 'улиты',
      ru: 'улиты',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 77,
    nameMl: {
      en: 'мородунка',
      ru: 'мородунка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 78,
    nameMl: {
      en: 'веретенник',
      ru: 'веретенник',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 79,
    nameMl: {
      en: 'кроншнеп',
      ru: 'кроншнеп',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 80,
    nameMl: {
      en: 'бекас',
      ru: 'бекас',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 81,
    nameMl: {
      en: 'дупель',
      ru: 'дупель',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 82,
    nameMl: {
      en: 'гаршнеп',
      ru: 'гаршнеп',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 83,
    nameMl: {
      en: 'вальдшнеп',
      ru: 'вальдшнеп',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 84,
    nameMl: {
      en: 'саджа',
      ru: 'саджа',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 85,
    nameMl: {
      en: 'голубь',
      ru: 'голубь',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 86,
    nameMl: {
      en: 'горлица',
      ru: 'горлица',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 87,
    nameMl: {
      en: 'гагара',
      ru: 'гагара',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 88,
    nameMl: {
      en: 'баклан',
      ru: 'баклан',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 89,
    nameMl: {
      en: 'поморник',
      ru: 'поморник',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 90,
    nameMl: {
      en: 'чайка',
      ru: 'чайка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 91,
    nameMl: {
      en: 'крачка',
      ru: 'крачка',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 92,
    nameMl: {
      en: 'чистиковые',
      ru: 'чистиковые',
    },
    types: [activityTargetTypeById(4)],
    weapons: [
      weaponById('airgun'),
      weaponById('bow'),
      weaponById('crossbow'),
      weaponById('smoothbore'),
    ],
  },
  {
    id: 163,
    nameMl: {
      ru: 'поход налегке',
    },
    types: [activityTargetTypeById(10)],
    weapons: [],
  },
  {
    id: 164,
    nameMl: {
      ru: 'горный поход',
    },
    types: [activityTargetTypeById(10)],
    weapons: [],
  },
  {
    id: 165,
    nameMl: {
      ru: 'скалолазание',
    },
    types: [activityTargetTypeById(10)],
    weapons: [],
  },
  {
    id: 166,
    nameMl: {
      ru: 'восхождение',
    },
    types: [activityTargetTypeById(10)],
    weapons: [],
  },
  {
    id: 167,
    nameMl: {
      ru: 'байдарка',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 168,
    nameMl: {
      ru: 'каяк',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 169,
    nameMl: {
      ru: 'морской каяк',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 170,
    nameMl: {
      ru: 'sup-серф',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 171,
    nameMl: {
      ru: 'катамаран',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 172,
    nameMl: {
      ru: 'пакрафт',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 173,
    nameMl: {
      ru: 'рафт',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 174,
    nameMl: {
      ru: 'плот',
    },
    types: [activityTargetTypeById(11)],
    weapons: [],
  },
  {
    id: 175,
    nameMl: {
      ru: 'круиз на яхте',
    },
    types: [activityTargetTypeById(12)],
    weapons: [],
  },
  {
    id: 176,
    nameMl: {
      ru: 'гонка на яхте',
    },
    types: [activityTargetTypeById(12)],
    weapons: [],
  },
  {
    id: 177,
    nameMl: {
      ru: 'яхта с капитаном',
    },
    types: [activityTargetTypeById(12)],
    weapons: [],
  },
  {
    id: 178,
    nameMl: {
      ru: 'самолет',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 179,
    nameMl: {
      ru: 'вертолет',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 180,
    nameMl: {
      ru: 'воздушный шар',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 181,
    nameMl: {
      ru: 'параплан',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 182,
    nameMl: {
      ru: 'дельтаплан',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 183,
    nameMl: {
      ru: 'прыжки с парашютом',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 184,
    nameMl: {
      ru: 'аэротруба',
    },
    types: [activityTargetTypeById(13)],
    weapons: [],
  },
  {
    id: 185,
    nameMl: {
      ru: 'дайвинг',
    },
    types: [activityTargetTypeById(14)],
    weapons: [],
  },
  {
    id: 186,
    nameMl: {
      ru: 'фридайвинг',
    },
    types: [activityTargetTypeById(14)],
    weapons: [],
  },
  {
    id: 187,
    nameMl: {
      ru: 'сноркелинг',
    },
    types: [activityTargetTypeById(14)],
    weapons: [],
  },
  {
    id: 188,
    nameMl: {
      ru: 'каньонинг',
    },
    types: [activityTargetTypeById(14)],
    weapons: [],
  },
  {
    id: 189,
    nameMl: {
      ru: 'кэмпинг',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 190,
    nameMl: {
      ru: 'глэмпинг',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 191,
    nameMl: {
      ru: 'домики',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 192,
    nameMl: {
      ru: 'гостиница',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 193,
    nameMl: {
      ru: 'экоферма',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 194,
    nameMl: {
      ru: 'спецбаза',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 195,
    nameMl: {
      ru: 'горный лагерь',
    },
    types: [activityTargetTypeById(15)],
    weapons: [],
  },
  {
    id: 196,
    nameMl: {
      ru: 'новогодний',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 197,
    nameMl: {
      ru: 'лыжный',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 198,
    nameMl: {
      ru: 'снегоступы',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 238,
    nameMl: {
      ru: 'снегоходы',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 199,
    nameMl: {
      ru: 'собачья упряжка',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 200,
    nameMl: {
      ru: 'на оленях',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 201,
    nameMl: {
      ru: 'горные лыжи/сноуборд',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 202,
    nameMl: {
      ru: 'ночевки в теплом шатре с печкой',
    },
    types: [activityTargetTypeById(16)],
    weapons: [],
  },
  {
    id: 203,
    nameMl: {
      ru: 'автопутешествие',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 204,
    nameMl: {
      ru: 'фототур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 205,
    nameMl: {
      ru: 'йога тур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 206,
    nameMl: {
      ru: 'гастротур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 207,
    nameMl: {
      ru: 'винный тур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 208,
    nameMl: {
      ru: 'космический',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 209,
    nameMl: {
      ru: 'ролевые игры',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 210,
    nameMl: {
      ru: 'велотур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 211,
    nameMl: {
      ru: 'конный',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 212,
    nameMl: {
      ru: 'спелео',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 213,
    nameMl: {
      ru: 'круиз',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 214,
    nameMl: {
      ru: 'мультитур',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 215,
    nameMl: {
      ru: 'кругосветка',
    },
    types: [activityTargetTypeById(17)],
    weapons: [],
  },
  {
    id: 216,
    nameMl: {
      ru: 'языковой',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 217,
    nameMl: {
      ru: 'семейный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 218,
    nameMl: {
      ru: 'на море',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 219,
    nameMl: {
      ru: 'пионерский',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 220,
    nameMl: {
      ru: 'спортивный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 221,
    nameMl: {
      ru: 'палаточный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 222,
    nameMl: {
      ru: 'городской',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 223,
    nameMl: {
      ru: 'горнолыжный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 224,
    nameMl: {
      ru: 'музыкальный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 225,
    nameMl: {
      ru: 'научный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 226,
    nameMl: {
      ru: 'образовательный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 227,
    nameMl: {
      ru: 'компьютерный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 228,
    nameMl: {
      ru: 'военно-патриотический',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 229,
    nameMl: {
      ru: 'конный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 230,
    nameMl: {
      ru: 'оздоровительный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 231,
    nameMl: {
      ru: 'робототехнический',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 232,
    nameMl: {
      ru: 'художественный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 233,
    nameMl: {
      ru: 'шахматный',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 234,
    nameMl: {
      ru: 'туристический',
    },
    types: [activityTargetTypeById(18)],
    weapons: [],
  },
  {
    id: 239,
    nameMl: {
      ru: 'як',
    },
    types: [activityTargetTypeById(1)],
    weapons: [weaponById('crossbow'), weaponById('rifle'), weaponById('smoothbore')],
  },
  {
    id: 240,
    nameMl: {
      ru: 'треска',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 241,
    nameMl: {
      ru: 'форель',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 242,
    nameMl: {
      ru: 'форель радужная',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
  {
    id: 243,
    nameMl: {
      ru: 'окунь морской',
    },
    types: [activityTargetTypeById(9)],
    weapons: [],
  },
] as const;
