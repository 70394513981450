import { rawElementByIdFunc } from '../raw-element-by-id';

export const REGION_RAW_DATA = [
  {
    id: 1,
    nameMl: {
      ru: 'Дальневосточный',
    },
  },
  {
    id: 2,
    nameMl: {
      ru: 'Приволжский',
    },
  },
  {
    id: 3,
    nameMl: {
      ru: 'Северо-Западный',
    },
  },
  {
    id: 4,
    nameMl: {
      ru: 'Северо-Кавказский',
    },
  },
  {
    id: 5,
    nameMl: {
      ru: 'Сибирский',
    },
  },
  {
    id: 6,
    nameMl: {
      ru: 'Уральский',
    },
  },
  {
    id: 7,
    nameMl: {
      ru: 'Центральный',
    },
  },
  {
    id: 8,
    nameMl: {
      ru: 'Южный',
    },
  },
] as const;

export const regionById = rawElementByIdFunc(REGION_RAW_DATA);
