import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  BookingDto,
  BookingFacilityItem,
  FACILITY_INVENTORY_TYPES,
  FACILITY_MEAL_TYPES,
  FacilityId,
  getBookingActivityTargetFacility,
  getBookingActivityTypeFacility,
  getBookingSelectedActivityTarget,
  getBookingSelectedActivityType,
  LocationDto,
  LocationFacilityItem,
  SelectedActivityTarget,
  UniversalDate,
  updateBookingFacility,
} from '@turgenev/dto';
import { getDaysCount } from '@turgenev/utils';
import * as _ from 'lodash';

import { TourBookingPageActions, TourPageActions } from './active-tour.actions';
import { AdditionalServicesPageMetadata } from './additional-services-page.type';

const initialState = {
  locationId: undefined as number,
  location: undefined as LocationDto,
  booking: undefined as BookingDto,

  activityTypes: [] as BookingFacilityItem[],
  activityTargets: [] as BookingFacilityItem[],

  selectedActivityType: undefined as LocationFacilityItem,
  selectedActivityTarget: undefined as LocationFacilityItem,

  additionalServices: {
    pages: [] as AdditionalServicesPageMetadata[],
    index: 0,
  },

  isLoading: false,
  isCalcPrice: false,
};

type State = typeof initialState;

export const activeTourFeature = createFeature({
  name: 'active-tour',
  reducer: createReducer(
    initialState,
    on(
      TourPageActions.opened,
      (state, { locationId }): State => ({
        ...state,
        booking: undefined,
        locationId,
      }),
    ),
    on(
      TourPageActions.locationLoadStart,
      (state): State => ({
        ...state,
        location: undefined,
        isLoading: true,
      }),
    ),
    on(
      TourPageActions.locationLoadSuccess,
      (state, { location }): State => ({
        ...state,
        location,
        isLoading: false,
      }),
    ),
    on(
      TourPageActions.locationLoadError,
      (state): State => ({
        ...state,
        isLoading: false,
      }),
    ),
    on(
      TourPageActions.locationReset,
      (state): State => ({
        ...state,
        location: undefined,
        locationId: undefined,
      }),
    ),
    on(
      TourBookingPageActions.bookingDatesUpdated,
      (state, { dates }): State => ({
        ...state,
        booking: new BookingDto({
          ...state.booking,
          ...dates,
        }),
      }),
    ),
    on(
      TourBookingPageActions.bookingPeopleCountUpdated,
      (state, { peopleCount }): State => ({
        ...state,
        booking: new BookingDto({
          ...state.booking,
          peopleCount,
        }),
      }),
    ),
    on(
      TourBookingPageActions.bookingFacilitiesUpdated,
      (state, { facilities }): State => ({
        ...state,
        booking: new BookingDto({
          ...state.booking,
          facilities,
        }),
      }),
    ),
    on(
      TourPageActions.bookingUpdateSuccess,
      (state, { booking }): State => ({
        ...state,
        booking,
        isCalcPrice: true,
      }),
    ),
    on(
      TourPageActions.bookingPriceUpdateSuccess,
      (state, { booking }): State => ({
        ...state,
        booking,
        isCalcPrice: false,
      }),
    ),
    on(
      TourBookingPageActions.bookingActivitiesUpdated,
      (state, activities): State => ({
        ...state,
        ...activities,
      }),
    ),
    on(
      TourBookingPageActions.additionalServicesPagesUpdated,
      (state, payload): State => ({
        ...state,
        additionalServices: payload,
      }),
    ),
    on(
      TourBookingPageActions.additionalServicesPageIndexUpdated,
      (state, { index }): State => ({
        ...state,
        additionalServices: {
          ...state.additionalServices,
          index,
        },
      }),
    ),
    on(TourPageActions.bookingFacilityPreselected, (state, { facility }): State => {
      const targetFacility = state.booking.facilities.find(
        f => f.locationFacility.facility.type === facility.type,
      );

      const updated = updateBookingFacility(state.booking, {
        ...targetFacility,
        options: targetFacility.options.map(option => ({
          ...option,
          selected: option.locationItem.id === facility.id,
        })),
      });

      return {
        ...state,
        booking: updated,
      };
    }),
  ),
  extraSelectors: ({
    selectActivityTypes,
    selectActivityTargets,
    selectSelectedActivityType,
    selectSelectedActivityTarget,
    selectLocation,
    selectBooking,
    selectAdditionalServices,
  }) => ({
    selectLocationId: createSelector(selectLocation, l => l?.id),
    selectLocationType: createSelector(selectLocation, l => l?.tourType.id),
    selectBookingActivityTypes: createSelector(selectBooking, b =>
      b ? getBookingActivityTypeFacility(b)?.options : [],
    ),
    selectBookingActivityTargets: createSelector(selectBooking, b =>
      b ? getBookingActivityTargetFacility(b)?.options : [],
    ),
    selectBookingSelectedTarget: createSelector(
      selectBooking,
      b => (b ? getBookingSelectedActivityTarget(b) : undefined) as SelectedActivityTarget<'beast'>,
    ),
    selectBookingSelectedType: createSelector(selectBooking, b =>
      b ? getBookingSelectedActivityType(b) : undefined,
    ),
    selectDaysBooked: createSelector(selectBooking, ({ dateFrom, dateTo }) =>
      getDaysCount(dateFrom, dateTo),
    ),
    selectPeopleCount: createSelector(selectBooking, b => b?.peopleCount),
    selectDiscount: createSelector(selectBooking, b => b?.discount),
    selectAccommodationRequired: createSelector(
      selectBooking,
      b => getBookingSelectedActivityType(b)?.item.locationItem.accommodationRequired,
    ),
    selectMinPrice: createSelector(selectBooking, b => b?.minPrice),
    selectDates: createSelector(selectBooking, ({ dateFrom, dateTo }) => ({ dateFrom, dateTo })),
    selectActivities: createSelector(
      selectActivityTypes,
      selectActivityTargets,
      selectSelectedActivityType,
      selectSelectedActivityTarget,
      (types, targets, selectedType, selectedTarget) => {
        return {
          types,
          targets,
          selectedType,
          selectedTarget,
        };
      },
    ),
    selectAdditionalServicesButtonLabel: createSelector(selectBooking, b => {
      const base = 'Выбрать';
      const facilitiesItemIds = _.flatMap(b.facilities.map(f => f.locationFacility.facility.id));

      const haveAccommodation = facilitiesItemIds.includes('house-rent');
      const showAccommodation =
        haveAccommodation &&
        b.dateFrom &&
        b.dateTo &&
        UniversalDate.clone(b.dateFrom).dayBefore(b.dateTo);

      if (showAccommodation) {
        return base + ' проживание';
      }

      const haveFood = facilitiesItemIds.some((id: FacilityId) => FACILITY_MEAL_TYPES.includes(id));

      if (haveFood) {
        return base + ' питание';
      }

      const haveInventory = facilitiesItemIds.some((id: FacilityId) =>
        FACILITY_INVENTORY_TYPES.includes(id),
      );

      if (haveInventory) {
        return base + ' инвентарь';
      }

      const haveTransfer = facilitiesItemIds.includes('transfer');

      if (haveTransfer) {
        return base + ' трансфер';
      }

      return base + ' прочие услуги';
    }),
    selectIsLastAdditionalServicesPage: createSelector(
      selectAdditionalServices,
      services => services.index >= services.pages.length - 1,
    ),
    selectIsHunt: createSelector(selectLocation, location => location?.tourType.id === 'HUNT'),
    selectHuntType: createSelector(
      selectSelectedActivityType,
      type => type as LocationFacilityItem<'hunt-type'>,
    ),
    selectHuntTypes: createSelector(
      selectActivityTypes,
      selectSelectedActivityTarget,
      (types, selectedTarget) => {
        const selectedAnimal = selectedTarget as LocationFacilityItem<'beast'>;
        const huntTypes = types as BookingFacilityItem<'hunt-type'>[];

        return huntTypes
          .filter(
            type =>
              !type.locationItem.beasts.length ||
              !!type.locationItem.beasts.find(beast => beast.id === selectedAnimal.beast.id),
          )
          .map(type => type.locationItem);
      },
    ),
  }),
});
