import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';

@Pipe({
  name: 'formArray',
})
export class FormArrayPipe<T> implements PipeTransform {
  transform(value: AbstractControl<T[]>) {
    return value as FormArray<FormControl<T>>;
  }
}
