import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { TransactionStatus } from './transaction-status.type';

@Exclude()
export class TransactionStatusDto {
  @ApiProperty({ description: 'Статус транзакции' })
  @Expose()
  status: TransactionStatus;
}
