import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { isObject } from 'lodash';

import { multiLangCoalesce, MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { BookingStatus } from './booking-status.type';

@Exclude()
export class BookingManageListItemDto {
  @ApiProperty()
  @Expose()
  bookingId: number;

  @ApiProperty()
  @Expose()
  bookingCode: string;

  @ApiProperty()
  @Expose()
  ownerParticipantId: number;

  @ApiProperty()
  @Expose()
  ownerParticipantUserId: number;

  @ApiProperty()
  @Expose()
  ownerParticipantUserPhone: string;

  @ApiProperty()
  @Expose()
  ownerParticipantPersonPhone: string;

  @ApiProperty()
  @Expose()
  @Transform(val => (val && !isObject(val) ? JSON.parse(val) : val))
  ownerParticipantPartnerBrandNameMl: MultiLangString;

  @ApiProperty()
  @Expose()
  customerPersonFirstName: string;

  @ApiProperty()
  @Expose()
  customerPersonLastName: string;

  @ApiProperty()
  @Expose()
  customerUserPhone: string;

  @ApiProperty()
  @Expose()
  customerPersonPhone: string;

  @ApiProperty()
  @Expose()
  customerUserEmail: string;

  @ApiProperty()
  @Expose()
  customerPersonEmail: string;

  @ApiProperty()
  @Expose()
  @Transform(val => (val && !isObject(val) ? JSON.parse(val) : val))
  locationTitleMl: MultiLangString;

  @ApiProperty()
  @Expose()
  selfEmployedPatronymic: string;

  @ApiProperty()
  @Expose()
  @Transform(val => (val && !isObject(val) ? JSON.parse(val) : val))
  tourNameMl: MultiLangString;

  @ApiProperty()
  @Expose()
  @Transform(
    (_, obj) =>
      multiLangCoalesce(
        obj.organizationLegalNameMl,
        obj.individualEntrepreneurLegalNameMl,
        `${obj.selfEmployedFirstName} ${obj.selfEmployedLastName} ${obj.selfEmployedPatronymic}`,
      ),
    { toClassOnly: true },
  )
  legalEntityNameMl: MultiLangString;

  @ApiProperty()
  @Expose()
  @Type(() => Date)
  dateTo: Date;

  @ApiProperty()
  @Expose()
  @Type(() => Date)
  dateFrom: Date;

  @ApiProperty()
  @Expose()
  price: number;

  @ApiProperty()
  @Expose()
  paid: number;

  @ApiProperty()
  @Expose()
  status: BookingStatus;

  @ApiProperty()
  @Expose()
  fromWeb: boolean;

  @ApiProperty()
  @Expose()
  personInfoFirstName: boolean;

  @ApiProperty()
  @Expose()
  personInfoSecondName: boolean;

  @ApiProperty()
  @Expose()
  personInfoEmail: boolean;

  @ApiProperty()
  @Expose()
  personInfoPhone: boolean;

  @ApiProperty()
  @Expose()
  @Type(() => Date)
  createdAt: Date;

  constructor(partial?: Partial<BookingManageListItemDto>) {
    Object.assign(this, partial);
  }
}
