import { Type } from 'class-transformer';

import { PageResponseDto } from '../../common';
import { ApiProperty } from '../../utils';
import { TourDto } from './tour.dto';

export class TourPageResponseDto extends PageResponseDto<TourDto> {
  @ApiProperty()
  @Type(() => TourDto)
  override docs: TourDto[];
}
