import { Expose, Transform, TransformationType } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { FacilityType } from '../facility';
import { LocationFacilityDto } from '../location-facility';
import { BookingFacilityItem } from './booking-facility-item';

export class BookingFacilityDto<T extends FacilityType = FacilityType> {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  locationFacility: LocationFacilityDto<T>;

  @ApiProperty()
  @Expose()
  price: number;

  @ApiProperty()
  @Expose()
  @Transform((value, _obj, transformType) => {
    if (!value) {
      return value;
    }

    for (const option of value) {
      if (transformType === TransformationType.PLAIN_TO_CLASS) {
        if (option?.locationItem?.customFields) {
          const { customFields, ...commonPart } = option.locationItem;
          option.locationItem = {
            ...customFields,
            ...commonPart,
          };
        }
      }
    }

    return value;
  })
  options: BookingFacilityItem<T>[];

  constructor(partial?: Partial<BookingFacilityDto<T>>) {
    Object.assign(this, partial);
  }
}
