import { Injectable } from '@angular/core';
import { PageResponseDto } from '@turgenev/common';
import { LocationAggregationDto, LocationDto, LocationFilterDto, TourDto } from '@turgenev/dto';
import { extractId, ObjectOrId } from '@turgenev/utils';

import { Api } from '../api';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private api: Api) {}

  searchLocationsByFilter(filters: LocationFilterDto) {
    return this.api.post<PageResponseDto<TourDto>>('location/search-paging', filters);
  }

  aggregateLocations(filters: LocationFilterDto) {
    return this.api.post<LocationAggregationDto>('location/aggregations', filters);
  }

  getOne(objectOrId: ObjectOrId<LocationDto>, context?: 'tour' | 'booking') {
    const id = extractId(objectOrId);
    return this.api.get<LocationDto>(`location/user/${id}`, {
      options: context && {
        params: {
          context,
        },
      },
    });
  }
}
