import { Exclude, Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { Merchant } from './merchant.type';

@Exclude()
export class MerchantInfoDto {
  @ApiProperty()
  @Expose()
  activeMerchant: Merchant;

  constructor(partial?: Partial<MerchantInfoDto>) {
    Object.assign(this, partial);
  }
}
