import { ApiProperty } from '../../utils';
import { SupportRequestContext } from './support-request-context.dto';

export class SupportDto {
  @ApiProperty()
  name: string;

  @ApiProperty()
  feedbackEmail?: string;

  @ApiProperty()
  feedbackPhone?: string;

  @ApiProperty()
  text: string;

  @ApiProperty()
  context?: SupportRequestContext;
}
