import { Injectable } from '@angular/core';
import { TOUR_TYPE_FISH, TOUR_TYPE_HUNT, TOUR_TYPE_TOURISM, TourType } from '@turgenev/dto';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs/operators';

type Theme = typeof HUNT_THEME;

const HUNT_THEME = {
  '--trg-color-primary': '#3da811',
  '--trg-color-primary-rgb': '61,168,17',
  '--trg-color-primary-contrast': '#ffffff',
  '--trg-color-base-02': '#9ed488',
};

const FISH_THEME: Theme = {
  '--trg-color-primary': '#2a6fdd',
  '--trg-color-primary-rgb': '42,111,221',
  '--trg-color-primary-contrast': '#ffffff',
  '--trg-color-base-02': '#88afec',
};

const TOURISM_THEME: Theme = {
  '--trg-color-primary': '#ed9118',
  '--trg-color-primary-rgb': '237,145,24',
  '--trg-color-primary-contrast': '#ffffff',
  '--trg-color-base-02': '#f5c27e',
};

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcher {
  private themeHistory: TourType[] = [];

  private themes: Record<TourType, Theme> = {
    [TOUR_TYPE_HUNT]: HUNT_THEME,
    [TOUR_TYPE_FISH]: FISH_THEME,
    [TOUR_TYPE_TOURISM]: TOURISM_THEME,
  };

  private theme$$ = new Subject<TourType>();
  theme$ = this.theme$$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  themeCss$ = this.theme$.pipe(
    map(t => this.themes[t]),
    shareReplay(),
  );

  constructor() {
    this.theme$
      .pipe(
        takeUntilDestroyed(this),
        tap(t => {
          this.themeHistory.push(t);
          if (this.themeHistory.length > 10) {
            this.themeHistory.splice(0, this.themeHistory.length - 10);
          }
        }),
      )
      .subscribe();

    this.themeCss$
      .pipe(
        takeUntilDestroyed(this),
        filter(t => !!t),
        tap(t => {
          for (const [k, v] of Object.entries(t)) {
            document.documentElement.style.setProperty(k, v);
          }
        }),
      )
      .subscribe();
  }

  switch(tourType: TourType) {
    this.theme$$.next(tourType);
  }

  switchBack() {
    const prev = this.themeHistory[this.themeHistory.length - 2];

    if (prev) {
      this.switch(prev);
    }
  }

  async getCurrentTheme() {
    return await this.theme$.pipe(take(1)).toPromise();
  }

  getIonicColorName(tourType: TourType): string {
    switch (tourType) {
      case TOUR_TYPE_HUNT:
        return 'hunt';
      case TOUR_TYPE_FISH:
        return 'fish';
      case TOUR_TYPE_TOURISM:
        return 'tourism';

      default:
        return 'primary';
    }
  }
}
