/* eslint-disable @typescript-eslint/ban-types */
import { ApiProperty } from '../../utils';
import { FacilityType } from '../facility';
import { LocationFacilityItem } from '../location-facility';

export class LocationFacilityItemWithPrice<T extends FacilityType = FacilityType> {
  @ApiProperty()
  locationItem: LocationFacilityItem<T>;

  @ApiProperty()
  price: number;
}
