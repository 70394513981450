export const LOCATION_STATUS_RAW_DATA = [
  {
    id: 'NEW',
    nameMl: {
      ru: 'Новое',
      en: 'New',
    },
  },
  {
    id: 'FOR_APPROVAL',
    nameMl: {
      ru: 'На проверке',
      en: 'For approval',
    },
  },
  {
    id: 'APPROVED',
    nameMl: {
      ru: 'Одобрено',
      en: 'Approved',
    },
  },
  {
    id: 'FIXING',
    nameMl: {
      ru: 'На доработке',
      en: 'Fixing',
    },
  },
] as const;

export const [
  LOCATION_STATUS_NEW,
  LOCATION_STATUS_FOR_APPROVAL,
  LOCATION_STATUS_APPROVED,
  LOCATION_STATUS_FIXING,
] = LOCATION_STATUS_RAW_DATA;
