import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { ActiveTourFeatureModule } from './features/active-tour/active-tour-feature.module';
import { PartnerFeatureModule } from './features/partner/partner-feature.module';
import { SearchFilterFeatureModule } from './features/search-filters/search-filters-feature.module';
import { ThemeFeatureModule } from './features/theme/theme-feature.module';

@NgModule({
  imports: [
    StoreModule.forRoot({
      router: routerReducer,
    }),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
      traceLimit: 75,
    }),
    StoreRouterConnectingModule.forRoot(),
    SearchFilterFeatureModule,
    ThemeFeatureModule,
    ActiveTourFeatureModule,
    PartnerFeatureModule,
  ],
})
export class StateModule {}
