import { Expose } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { ParticipantDto } from '../participant';
import { PromocodeDto } from './promocode.dto';

export class PromocodeParticipantDto {
  @ApiProperty()
  @Expose()
  id: number;

  @ApiProperty()
  @Expose()
  promocode: PromocodeDto;

  @ApiProperty()
  @Expose()
  participant: ParticipantDto;

  constructor(partial?: Partial<PromocodeParticipantDto>) {
    Object.assign(this, partial);
  }
}
