/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

export function GenericClass<P>(): new () => P {
  return class {} as any;
}

@Injectable({
  providedIn: 'root',
})
export class Config extends GenericClass<typeof environment>() {
  constructor() {
    super();
    Object.assign(this, environment);
  }
}
