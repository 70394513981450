import { Exclude, Expose, Type } from 'class-transformer';

import { MultiLangString } from '../../common';
import { PartnerDto } from '../partner';
import { LocationManageDto } from './location-manage.dto';

@Exclude()
export class TourManagerDto {
  @Expose()
  nameMl: MultiLangString;

  @Expose()
  @Type(() => PartnerDto)
  partner: PartnerDto;

  @Expose()
  minPrice?: number;

  @Expose()
  @Type(() => LocationManageDto)
  location: LocationManageDto;

  constructor(partial?: Partial<TourManagerDto>) {
    Object.assign(this, partial);
  }
}
