import { Exclude, Expose, Type } from 'class-transformer';

import { MultiLangString } from '../../common';
import { PartnerPublicDto } from '../partner';
import { LocationDto } from './location.dto';

@Exclude()
export class TourDto {
  @Expose()
  nameMl: MultiLangString;

  @Expose()
  @Type(() => PartnerPublicDto)
  partner: PartnerPublicDto;

  @Expose()
  minPrice?: number;

  @Expose()
  originalPrice?: number;

  @Expose()
  @Type(() => LocationDto)
  location: LocationDto;

  constructor(partial?: Partial<TourDto>) {
    Object.assign(this, partial);
  }
}
