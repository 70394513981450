import { Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { ApiProperty, Id } from '../../utils';
import { LocationDto } from '../location';

export class FavoriteLocationsDto {
  @ApiProperty({ description: 'Participat favorite location Ids' })
  @IsNotEmpty()
  @Expose()
  locationIds: Id<LocationDto>[];

  constructor(partial?: Partial<FavoriteLocationsDto>) {
    Object.assign(this, partial);
  }
}
