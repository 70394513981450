import { inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getRouterSelectors, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';

import { ThemeSwitcher } from '../../../providers/services/theme-switcher';
import { activeTourFeature } from '../active-tour/active-tour.feature';
import { searchFiltersFeature } from '../search-filters/search-filters.feature';

export const appThemeUpdatedByRouter = createEffect(
  (actions = inject(Actions), store = inject(Store), themeSwitcher = inject(ThemeSwitcher)) => {
    const { selectUrl } = getRouterSelectors();

    return actions.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => store.select(selectUrl)),
      switchMap(([_, currentUrl]) => {
        const isTourPage =
          currentUrl?.startsWith('/tour/') || currentUrl?.startsWith('/tour-booking/');

        return store.select(
          isTourPage
            ? activeTourFeature.selectLocationType
            : searchFiltersFeature.selectSearchFiltersType,
        );
      }),
      tap(type => {
        themeSwitcher.switch(type || 'TOUR');
      }),
      map(() => ''),
    );
  },
  {
    functional: true,
    dispatch: false,
  },
);
