import { rawElementByIdFunc } from '../raw-element-by-id';

export const BUSINESS_FORM_RAW_DATA = [
  {
    id: 'INDIVIDUAL_ENTREPRENEUR',
    nameMl: {
      en: 'Individual entrepreneur',
      ru: 'Индивидуальный предприниматель',
    },
  },
  {
    id: 'ORGANIZATION',
    nameMl: {
      en: 'Organization',
      ru: 'Юридическое лицо',
    },
  },
  {
    id: 'SELF_EMPLOYED',
    nameMl: {
      en: 'Self employed',
      ru: 'Самозанятый',
    },
  },
] as const;

export const [INDIVIDUAL_ENTREPRENEUR, ORGANIZATION, SELF_EMPLOYED] = BUSINESS_FORM_RAW_DATA;

export const businessFormById = rawElementByIdFunc(BUSINESS_FORM_RAW_DATA);
