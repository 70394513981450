import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { DiscountType } from './discount-type.type';

@Exclude()
export class DiscountTypeDto {
  @ApiProperty({ type: () => String })
  @IsNotEmpty()
  @Expose()
  id: DiscountType;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  descriptionMl: MultiLangString;

  constructor(partial: Partial<DiscountType>) {
    Object.assign(this, partial);
  }
}
