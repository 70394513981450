import { tackleById } from '../tackle';
import { TOUR_TYPE_FISH, TOUR_TYPE_HUNT } from '../tour-type/tour-type.data';

export const ACTIVITY_TYPE_RAW_DATA = [
  {
    id: 15,
    nameMl: {
      ru: 'нахлыст',
    },
    tourType: TOUR_TYPE_FISH,
  },
  {
    id: 16,
    nameMl: {
      ru: 'с лодки',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [
      tackleById('spinning'),
      tackleById('bolognese-tackle'),
      tackleById('harpoon'),
      tackleById('jig'),
      tackleById('flywheel'),
      tackleById('micro-jig'),
      tackleById('noodling'),
      tackleById('speargun'),
      tackleById('bobber'),
      tackleById('trolling'),
      tackleById('rod'),
      tackleById('feeder'),
    ],
  },
  {
    id: 17,
    nameMl: {
      ru: 'подводная',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [tackleById('harpoon'), tackleById('speargun')],
  },
  {
    id: 18,
    nameMl: {
      ru: 'зимняя',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [tackleById('zherlitsa'), tackleById('winter-rod')],
  },
  {
    id: 19,
    nameMl: {
      ru: 'морская',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [
      tackleById('bolognese-tackle'),
      tackleById('harpoon'),
      tackleById('jig'),
      tackleById('micro-jig'),
      tackleById('noodling'),
      tackleById('speargun'),
      tackleById('bobber'),
      tackleById('spinning'),
      tackleById('trolling'),
    ],
  },
  {
    id: 21,
    nameMl: {
      ru: 'с берега',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [
      tackleById('bolognese-tackle'),
      tackleById('harpoon'),
      tackleById('jig'),
      tackleById('leger-rig'),
      tackleById('flywheel'),
      tackleById('micro-jig'),
      tackleById('fly-fishing'),
      tackleById('noodling'),
      tackleById('speargun'),
      tackleById('bobber'),
      tackleById('spinning'),
      tackleById('rod'),
      tackleById('feeder'),
    ],
  },
  {
    id: 22,
    nameMl: {
      ru: 'смешанная',
    },
    tourType: TOUR_TYPE_FISH,
    tackles: [
      tackleById('bolognese-tackle'),
      tackleById('harpoon'),
      tackleById('jig'),
      tackleById('leger-rig'),
      tackleById('flywheel'),
      tackleById('micro-jig'),
      tackleById('fly-fishing'),
      tackleById('noodling'),
      tackleById('speargun'),
      tackleById('bobber'),
      tackleById('spinning'),
      tackleById('trolling'),
      tackleById('rod'),
      tackleById('feeder'),
    ],
  },
  {
    id: 1,
    nameMl: {
      ru: 'загонная',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 2,
    nameMl: {
      ru: 'с подхода',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 3,
    nameMl: {
      ru: 'с вышки',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 4,
    nameMl: {
      ru: 'из засидки',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 5,
    nameMl: {
      ru: 'на приваде',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 6,
    nameMl: {
      ru: 'на солонцах / на овсах',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 7,
    nameMl: {
      ru: 'с подсадной уткой',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 8,
    nameMl: {
      ru: 'с собаками',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 9,
    nameMl: {
      ru: 'с чучелами',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 10,
    nameMl: {
      ru: 'с капканом',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 11,
    nameMl: {
      ru: 'соколиная',
    },
    tourType: TOUR_TYPE_HUNT,
  },
  {
    id: 20,
    nameMl: {
      ru: 'на реву / на гону',
    },
    tourType: TOUR_TYPE_HUNT,
  },
] as const;
