export function toRecord<T, K extends string | number = string>(
  array: T[],
  keyFunc: (v: T) => K,
): Record<K, T>;
// eslint-disable-next-line no-redeclare
export function toRecord<T, K extends string | number = string, V = T>(
  array: T[],
  keyFunc: (v: T) => K,
  valueFunc: (v: T) => V,
): Record<K, V>;
// eslint-disable-next-line no-redeclare
export function toRecord<T, K extends string | number = string, V = T>(
  array: T[],
  keyFunc: (v: T) => K,
  valueFunc?: (v: T) => V,
): Record<K, V> {
  if (!array?.length) {
    return {} as Record<K, V>;
  }

  return array.reduce((prev, cur) => {
    const key = keyFunc(cur);
    const value = valueFunc ? valueFunc(cur) : (cur as unknown as V);
    prev[key] = value;
    return prev;
  }, {} as Record<K, V>);
}
