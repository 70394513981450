import { Exclude, Expose } from 'class-transformer';

import { MultiLangString } from '../../common';
import { ApiProperty } from '../../utils';
import { TourType } from '../tour-type';
import { GeoLocationDto } from './geo-location.dto';

@Exclude()
export class TourMapDto {
  @Expose()
  @ApiProperty({ description: 'id' })
  id: number;

  @Expose()
  @ApiProperty({ description: 'Тип тура' })
  type: TourType;

  @Expose()
  @ApiProperty({ description: 'Название' })
  titleMl: MultiLangString;

  @Expose()
  @ApiProperty({ description: 'Описание' })
  descriptionMl: MultiLangString;

  @Expose()
  @ApiProperty({ description: 'Геолокация' })
  geoLocation: GeoLocationDto;

  @Expose()
  @ApiProperty({ description: 'Id картинки места' })
  imageId: number;

  @Expose()
  @ApiProperty({ description: 'Минимальная цена тура' })
  minPrice?: number;

  @Expose()
  @ApiProperty({ description: 'Рейтинг' })
  rating?: number;

  @Expose()
  @ApiProperty({ description: 'Кол-во отзывов' })
  feedbackCount?: number;

  constructor(partial: Partial<TourMapDto>) {
    Object.assign(this, partial);
  }
}
