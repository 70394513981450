import { Exclude, Expose, Type } from 'class-transformer';

import { ApiProperty } from '../../utils';
import { LoyaltyStatusDto } from '../loyalty-status';
import { PersonDto } from '../person/person.dto';
import { UserDto } from '../user/user.dto';

@Exclude()
export class ParticipantDto {
  @Expose()
  id: number;

  @Expose()
  @Type(() => UserDto)
  user: UserDto;

  @Expose()
  @Type(() => PersonDto)
  person: PersonDto;

  @Expose()
  @ApiProperty()
  bonusBalance: number;

  @Expose()
  @ApiProperty()
  @Type(() => LoyaltyStatusDto)
  loyaltyStatus?: LoyaltyStatusDto;
}
