import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { ActivityTargetTypeDto } from '../activity-target-type/activity-target-type.dto';
import { WeaponDto } from '../weapon/weapon.dto';

@Exclude()
export class ActivityTargetDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  nameMl: MultiLangString;

  @ApiProperty()
  @Expose()
  @Type(() => ActivityTargetTypeDto)
  types: ActivityTargetTypeDto[];

  @ApiProperty()
  @Expose()
  @Type(() => WeaponDto)
  weapons: WeaponDto[];
}
