import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';
import { PriceTypeDto } from '../price-type/price-type.dto';

@Exclude()
export class FacilityPriceTypeDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  @Type(() => PriceTypeDto)
  price: PriceTypeDto;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  descriptionMl: MultiLangString;

  constructor(partial: Partial<FacilityPriceTypeDto>) {
    Object.assign(this, partial);
  }
}
