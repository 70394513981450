import { Exclude, Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { MultiLangString } from '../../common/multi-lang-string';
import { ApiProperty } from '../../utils';

@Exclude()
export class LocationGeoObjectDto {
  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  id: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  lat: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  lng: number;

  @ApiProperty()
  @IsNotEmpty()
  @Expose()
  titleMl: MultiLangString;

  constructor(partial?: Partial<LocationGeoObjectDto>) {
    Object.assign(this, partial);
  }
}
