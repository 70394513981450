import { IsNotEmpty, MinLength } from 'class-validator';

import { ApiProperty } from '../../utils';

export class PasswordDto {
  @ApiProperty()
  @IsNotEmpty()
  password: string;

  @ApiProperty()
  @IsNotEmpty()
  @MinLength(8)
  newPassword: string;
}
