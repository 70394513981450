import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

import { MultiLangString } from '../../common';
import { IAbstractControl } from './i-abstract-control';

export type ValidatorError = {
  message: MultiLangString;
};

type Func<T, R> = (value: T) => R;
type Validator<T> = Func<IAbstractControl<T>, ValidatorError | null>;
type MessageFunc<R> = Func<R, ValidatorError | null>;

/**
 * Returns first result from other prepared validators
 * @param validators
 */
export function composeValidators<T>(...validators: Validator<T>[]): Validator<T> {
  return control => {
    if (!validators?.length) {
      return null;
    }

    for (const func of validators) {
      const result = func(control);

      if (result) {
        return result;
      }
    }
  };
}

/**
 * This type of validator depends only on controls value
 * @param errorFunc value validation function
 */
export function valueValidator<T, R>(
  errorFunc: Func<T, R>,
  messageFunc: MessageFunc<R>,
): Validator<T> {
  return controlOrValue => {
    const value = controlOrValue instanceof AbstractControl ? controlOrValue.value : controlOrValue;
    const error = errorFunc(value);
    const message = error ? messageFunc(error) : null;
    return message;
  };
}

export function controlValidator<T, R>(
  errorFunc: Func<IAbstractControl<T>, R>,
  messageFunc?: Func<R, ValidatorError | null>,
): Validator<T> {
  return control => {
    const error = errorFunc(control);
    const message = error ? messageFunc(error) : null;
    return message;
  };
}

type MinLengthInput = string | MultiLangString | unknown[];
type MinLengthError = { value: MinLengthInput; expected: number; actual: number };
export function minLength(
  config: number | { value: number },
  messageFunc?: MessageFunc<MinLengthError>,
) {
  if (typeof config === 'number') {
    config = {
      value: config,
    };
  }
  return length({ min: config.value, max: undefined }, error => {
    if (messageFunc) {
      return messageFunc({ actual: error.actual, value: error.value, expected: error.min });
    }

    return {
      message: {
        ru: !_.isArray(error.value)
          ? `Должно быть не меньше ${error.min} символов, введено ${error.actual || 0}`
          : `Должно быть не меньше ${error.min} элементов, введено ${error.actual || 0}`,
      },
    };
  });
}

type MaxLengthInput = string | MultiLangString | unknown[];
type MaxLengthError = { value: MaxLengthInput; expected: number; actual: number };
export function maxLength(
  config: number | { value: number },
  messageFunc?: MessageFunc<MaxLengthError>,
) {
  if (typeof config === 'number') {
    config = {
      value: config,
    };
  }
  return length({ min: undefined, max: config.value }, error => {
    if (messageFunc) {
      return messageFunc({ actual: error.actual, value: error.value, expected: error.max });
    }

    return {
      message: {
        ru: !_.isArray(error.value)
          ? `Должно быть не больше ${error.max} символов, введено ${error.actual || 0}`
          : `Должно быть не больше ${error.max} элементов, введено ${error.actual || 0}`,
      },
    };
  });
}

export function max(config: number, messageFunc?: MessageFunc<{ max: number }>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return valueValidator<string | number, { max: number }>(
    value => {
      if (!value) {
        return null;
      }

      if (typeof value === 'string' && value.match(/\d+/)) {
        return +value > config ? { max: config } : null;
      }

      if (typeof value === 'number') {
        return value > config ? { max: config } : null;
      }

      return { max: config };
    },
    messageFunc ||
      (({ max }) => {
        return {
          message: {
            ru: `Не может быть больше ${max}`,
          },
        };
      }),
  );
}

export function min(config: number, messageFunc?: MessageFunc<{ max: number }>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return valueValidator<string | number, { max: number }>(
    value => {
      if (!value) {
        return null;
      }

      if (typeof value === 'string' && value.match(/\d+/)) {
        return +value < config ? { max: config } : null;
      }

      if (typeof value === 'number') {
        return value < config ? { max: config } : null;
      }

      return { max: config };
    },
    messageFunc ||
      (({ max }) => {
        return {
          message: {
            ru: `Не может быть меньше ${max}`,
          },
        };
      }),
  );
}

type LengthInput = string | MultiLangString | unknown[];
type LengthError = { value: LengthInput; min: number; max: number; actual: number };
export function length(
  config: number | { exact: number } | { min: number; max: number },
  messageFunc?: MessageFunc<LengthError>,
) {
  return valueValidator<LengthInput, LengthError>(
    value => {
      if (!config || !value) {
        return null;
      }

      if (typeof config === 'number') {
        config = {
          min: config,
          max: config,
        };
      } else if ('exact' in config) {
        config = {
          min: config.exact,
          max: config.exact,
        };
      }

      const values: Exclude<MinLengthInput, MultiLangString>[] =
        typeof value === 'string' || _.isArray(value) ? [value] : [value.ru]; //_.values(value);

      for (const v of values) {
        const lth = v ? v.length : 0;

        if (lth < config.min || lth > config.max) {
          return {
            min: config.min,
            max: config.max,
            actual: lth,
            value,
          };
        }
      }

      return null;
    },
    messageFunc ||
      (error => {
        const str = error.max !== error.min ? `от ${error.min} до ${error.max}` : `${error.min}`;
        return {
          message: {
            ru: !_.isArray(error.value)
              ? `Должно быть ${str} символов (введено ${error.actual || 0})`
              : `Должно быть ${str} элементов (введено ${error.actual || 0})`,
          },
        };
      }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function required(messageFunc?: MessageFunc<any>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return valueValidator<any, { required: true }>(
    value => {
      const error = value ? null : ({ required: true } as const);
      return error;
    },
    messageFunc ||
      (() => {
        return {
          message: {
            ru: 'Поле обязательно для заполнения',
          },
        };
      }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numeric(messageFunc?: MessageFunc<any>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return valueValidator<any, { numeric: true }>(
    value => {
      if (!value || typeof value === 'number') {
        return null;
      }

      if (typeof value === 'string' && value.match(/\d+/)) {
        return null;
      }

      return { numeric: true } as const;
    },
    messageFunc ||
      (() => {
        return {
          message: {
            ru: 'Только цифры',
          },
        };
      }),
  );
}
