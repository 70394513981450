import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getRouterSelectors, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import {
  BookingDto,
  buildBookingFromLocation,
  getBookingActivityTargetFacility,
  getBookingActivityTypeFacility,
  getBookingFacilities,
  isFacilityOfType,
  updateBookingFacility,
} from '@turgenev/dto';
import { getDaysCount } from '@turgenev/utils';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { LocationService } from '../../../providers/services/location.service';
import { PriceService } from '../../../providers/services/price.service';
import { partnerFeature } from '../partner/partner.feature';
import { searchFiltersFeature } from '../search-filters/search-filters.feature';
import { TourBookingPageActions, TourPageActions } from './active-tour.actions';
import { activeTourFeature } from './active-tour.feature';
import { ADDITIONAL_SERVICES_PAGES } from './additional-services-page.type';

export const tourPageOpenedEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourPageActions.opened),
      concatLatestFrom(() => store.select(activeTourFeature.selectLocationId)),
      filter(([{ locationId }, prevId]) => locationId !== prevId),
      map(([{ locationId }]) => TourPageActions.locationLoadStart({ locationId })),
    );
  },
  { functional: true },
);

export const locationIsLoadingEffect = createEffect(
  (actions$ = inject(Actions), locationService = inject(LocationService)) => {
    return actions$.pipe(
      ofType(TourPageActions.locationLoadStart),
      switchMap(({ locationId }) => locationService.getOne(locationId)),
      map(location => TourPageActions.locationLoadSuccess({ location })),
      catchError(() => of(TourPageActions.locationLoadError())),
    );
  },
  {
    functional: true,
  },
);

export const locationLoadingErrorEffect = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourPageActions.locationLoadError),
      concatLatestFrom(() => store.select(partnerFeature.selectPartner)),
      tap(([_, partner]) => {
        if (partner === 'alfabank') {
          return router.navigate(['/alfabank']);
        }

        if (partner === 's7') {
          return router.navigate(['/experience']);
        }

        return router.navigate(['/']);
      }),
      map(() => ''),
    );
  },
  {
    functional: true,
    dispatch: false,
  },
);

export const locationLoadedEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourPageActions.locationLoadSuccess),
      concatLatestFrom(() => [
        store.select(activeTourFeature.selectLocation),
        store.select(searchFiltersFeature.selectFilters),
      ]),
      map(([_, location, filters]) =>
        buildBookingFromLocation(
          location,
          filters,
          item => {
            const isActivity = isFacilityOfType(item, [
              'beast',
              'fish',
              'fishing-type',
              'hunt-type',
              'tourism-type',
            ]);

            const isFree = item.isFree;

            return isActivity || isFree ? 1 : 0;
          },
          true,
          true,
        ),
      ),
      map(booking => TourPageActions.bookingUpdateSuccess({ booking })),
    );
  },
  { functional: true },
);

export const bookingPriceUpdatedEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), priceService = inject(PriceService)) => {
    return actions$.pipe(
      ofType(
        TourPageActions.bookingUpdateSuccess,
        TourBookingPageActions.bookingDatesUpdated,
        TourBookingPageActions.bookingFacilitiesUpdated,
        TourBookingPageActions.bookingPeopleCountUpdated,
        TourPageActions.bookingFacilityPreselected,
      ),
      concatLatestFrom(() => store.select(activeTourFeature.selectBooking)),
      switchMap(([_, booking]) => priceService.calc(booking)),
      map(booking => TourPageActions.bookingPriceUpdateSuccess({ booking })),
    );
  },
  { functional: true },
);

export const bookingPartialResetEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourBookingPageActions.bookingFacilitiesUpdated),
      concatLatestFrom(() => [
        store.select(activeTourFeature.selectBooking),
        store.select(activeTourFeature.selectActivities),
      ]),
      map(([_, booking, { selectedType }]) => {
        const peopleCount =
          (selectedType && 'peopleCountMin' in selectedType && selectedType.peopleCountMin) || 1;

        return TourPageActions.bookingUpdateSuccess({
          booking: new BookingDto({
            ...booking,
            dateFrom: null,
            dateTo: null,
            peopleCount,
          }),
        });
      }),
    );
  },
  {
    functional: true,
  },
);

export const bookingSelectedActivitiesUpdated = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourPageActions.bookingUpdateSuccess, TourBookingPageActions.bookingFacilitiesUpdated),
      concatLatestFrom(() => [
        store.select(activeTourFeature.selectBooking),
        store.select(activeTourFeature.selectLocation),
      ]),
      map(([_, booking, location]) => {
        const isHuntType = location.tourType.id === 'HUNT';

        const activityTypes = booking ? getBookingActivityTypeFacility(booking)?.options || [] : [];
        const activityTargets = booking
          ? getBookingActivityTargetFacility(booking)?.options || []
          : [];

        const selectedActivityType = (
          !isHuntType
            ? activityTypes.find(type => type.selected) || activityTypes[0]
            : activityTypes.find(type => type.selected)
        )?.locationItem;

        const selectedActivityTarget = (
          isHuntType
            ? activityTargets.find(targets => targets.selected) || activityTargets[0]
            : activityTargets.find(targets => targets.selected)
        )?.locationItem;

        return TourBookingPageActions.bookingActivitiesUpdated({
          activityTypes,
          activityTargets,
          selectedActivityType,
          selectedActivityTarget,
        });
      }),
    );
  },
  {
    functional: true,
  },
);

export const locationResetEffect = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    const { selectUrl } = getRouterSelectors();

    return action$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => store.select(selectUrl)),
      map(
        ([_, currentUrl]) =>
          currentUrl?.startsWith('/tour/') || currentUrl?.startsWith('/tour-booking/'),
      ),
      filter(isTourPage => !isTourPage),
      map(() => TourPageActions.locationReset()),
    );
  },
  {
    functional: true,
  },
);

export const additionalServicesPagesUpdatedEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourBookingPageActions.bookingDatesUpdated),
      concatLatestFrom(() => store.select(activeTourFeature.selectBooking)),
      map(([_, booking]) => {
        const bookingDuration = getDaysCount(booking.dateFrom, booking.dateTo);
        const showHouseRentSlide = bookingDuration >= 2;
        const pages = ADDITIONAL_SERVICES_PAGES.filter(page => {
          if (page.facilityTypes.includes('house-rent') && !showHouseRentSlide) {
            return false;
          }

          const facilities = getBookingFacilities(booking, {
            includeIds: page.facilityTypes,
          });

          return facilities.length;
        });

        return {
          pages,
          index: showHouseRentSlide ? 0 : 1,
        };
      }),
      map(payload => TourBookingPageActions.additionalServicesPagesUpdated(payload)),
    );
  },
  {
    functional: true,
  },
);

export const additionalServiceQuantityChangesEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(TourBookingPageActions.additionalServicesFacilityQuantityChanged),
      concatLatestFrom(() => store.select(activeTourFeature.selectBooking)),
      map(([{ facility }, booking]) => updateBookingFacility(booking, facility)),
      map(booking => TourPageActions.bookingUpdateSuccess({ booking })),
    );
  },
  {
    functional: true,
  },
);
